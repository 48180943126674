import React, { Component } from "react";

class Despre extends Component {
  render() {
    return (
      <div className="despre-brikston" id="despre-brikston">
        Pagina despre Brikston
      </div>
    );
  }
}

export default Despre;
