import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Subheader from "./Subheader";

// Import asset-uri
import imgBlocuri from "./../assets/img/calculator_blocuri.png";
import imgCaramida from "./../assets/img/calculator_caramida_klinker.png";
import imgPlacaj from "./../assets/img/calculator_placaj_klinker.png";
import imgSageataRosuSus from "./../assets/img/sageata_rosu_sus.png";

export default class ListaCalculatoare extends Component {
  render() {
    return (
      <div>
        <Header />
        <Subheader />

        <div className="lista-calculatoare" id="lista-calculatoare">
          <div
            className="lista-calculatoare-content"
            id="lista-calculatoare-content"
          >
            <Link to="/caramida-blocuri-ceramice">
              <div class="lista-item">
                <div className="lista-item-content">
                  <div className="item-red-arrow">
                    <img src={imgSageataRosuSus} alt="" />
                  </div>
                  <div className="item-imagine-container">
                    <div className="item-imagine">
                      <img src={imgBlocuri} alt="" />
                    </div>
                  </div>
                  <div className="item-titles-container">
                    <div className="item-title-grey">Calculator</div>
                    <div className="item-main-title">
                      <span>
                        Cărămidă și
                        <br /> blocuri ceramice
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/caramida-klinker">
              <div class="lista-item">
                <div className="lista-item-content">
                  <div className="item-red-arrow">
                    <img src={imgSageataRosuSus} alt="" />
                  </div>
                  <div className="item-imagine-container">
                    <div className="item-imagine">
                      <img src={imgCaramida} alt="" />
                    </div>
                  </div>
                  <div className="item-titles-container">
                    <div className="item-title-grey">Calculator</div>
                    <div className="item-main-title">
                      <span>Cărămidă Klinker</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/placaj-klinker">
              <div class="lista-item">
                <div className="lista-item-content">
                  <div className="item-red-arrow">
                    <img src={imgSageataRosuSus} alt="" />
                  </div>
                  <div className="item-imagine-container">
                    <div className="item-imagine">
                      <img src={imgPlacaj} alt="" />
                    </div>
                  </div>
                  <div className="item-titles-container">
                    <div className="item-title-grey">Calculator</div>
                    <div className="item-main-title">
                      <span>Placaj Klinker</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
