import React, { Component } from "react";
import { Link } from "react-router-dom";
import Subheader from "./Subheader";
import Menu from "./Menu";
import Headerq from "./Headerq";

import background from "./../assets/img/background.jpg";

export default class ConfirmareTrimitereOferta extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div
        className="background h100 calculator_caramida"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Headerq />
        <div className="main-confirmare-oferta" id="main-confirmare-oferta">
          <div
            className="confirmare-oferta-content"
            id="confirmare-oferta-content"
          >
            <div className="subheader" id="subheader">
              Mulțumim că ai folosit calculatorul de preț.
              <br />
              În scurt timp vei primi oferta pe mail.
            </div>
            <Link to="/">
              <div className="content-btn text_center_confirma">
                Fă un calcul nou
              </div>
            </Link>
          </div>
        </div>{" "}
      </div>
    );
  }
}
