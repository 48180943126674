import React, { Component } from "react";

import axios from "axios";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import CaramidaKlinker from "./CaramidaKlinker";
import Subheader from "./Subheader";
import Menu from "./Menu";
import Headerq from "./Headerq";

import red_arrow from "../assets/img/red-arrow.png";
import red_arrow_down from "../assets/img/down-arrow.png";
import product from "../assets/img/product.png";

import cart_btn from "../assets/img/cart_btn_white.png";
import background from "./../assets/img/background.jpg";
import edit from "./../assets/img/edit1.png";
import delete1 from "./../assets/img/delete1.png";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCos: 0,
      sidebarOpen: false,
      produse_caramida_klinker: "",
      produse_placaj_klinker: "",
      produse_caramida_blocuri: "",
      id_stergere: 0,
      show_popup_stergere: 0,
      show_popup_editare: 0,
      id_editare: 0,
      redirect: false,
      sursaStergere: "",
      color: "#666",
      color1: "#666",
      redirectPath: "",
      dataToEdit: "",
      adresaEmail: "",
      cumparatorFirma: "",
      cumparatorCUI: "",
      cumparatorRegCom: "",
      cumparatorSediu: "",
      cumparatorJudet: 0,
      cumparatorContact: "",
      cumparatorTelefon: "",
      furnizorFirma: "",
      furnizorCUI: "",
      furnizorRegCom: "",
      furnizorSediu: "",
      furnizorJudet: 0,
      furnizorContact: "",
      furnizorTelefon: "",
      bifaAccept: false,
      listaJudete: [
        "Alba",
        "Arad",
        "Arges",
        "Bacau",
        "Bihor",
        "Bistrita-Nasaud",
        "Botosani",
        "Brasov",
        "Braila",
        "Buzau",
        "Caras-Severin",
        "Calarasi",
        "Cluj",
        "Constanta",
        "Covasna",
        "Dambovita",
        "Dolj",
        "Galati",
        "Giurgiu",
        "Gorj",
        "Harghita",
        "Hunedoara",
        "Ialomita",
        "Iasi",
        "Ilfov",
        "Maramures",
        "Mehedinti",
        "Mures",
        "Neamt",
        "Olt",
        "Prahova",
        "Satu Mare",
        "Salaj",
        "Sibiu",
        "Suceava",
        "Teleorman",
        "Timis",
        "Tulcea",
        "Vaslui",
        "Valcea",
        "Vrancea"
      ],
      optionsJudet: "",
      content_caramida_blocuri: "",
      content_caramida_klinker: "",
      content_placaj_klinker: "",
      cartUpdated: 0
    };
  }

  componentWillUnmount() {
    var d = document.getElementById("root");
    d.classList.remove("pagina_cart");

    this.setState({
      cartUpdated: 0
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var d = document.getElementById("root");
    d.classList.add("pagina_cart");

    $("select").on("change", function() {
      if (this.value != "" || this.value != 0) {
        $(this).css("cssText", "color:#000;");

        if ($(this).hasClass("selectprodus")) {
          $(".selectmodel").css("cssText", "color:#000;");
        }
        if ($(this).hasClass("selectchit")) {
          $(".selectculoarechit").css("cssText", "color:#000;");
        }
      } else {
        $(this).css("cssText", "color:#666;");
      }
    });
  }

  updateCart() {
    // Produse caramida Klinker

    const content_caramida_klinker = Array.from(
      this.state.produse_caramida_klinker,
      child => {
        return (
          <div>
            <div
              className="cap_tabel cap_tabel_cart item_cos"
              key={child.idLinieComanda}
            >
              <div className="black_item flex_item_produs item55">
                {" "}
                <img
                  className="img_produs"
                  src={require("../assets/img/klinker/" + child.poza_caramida)}
                  alt=""
                />
                <div className="denumire_produs_cos">
                  {child.denumireKlinker} - {child.denumireModel}
                </div>
              </div>
              <div className="red_item item15">
                {child.cantitate_buc_paleti
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                buc
              </div>
              <div className="red_item item15">
                {" "}
                {parseFloat(child.klinker_caramida / child.cursIntrodus)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {"  "}
                {this.state.defaultCurrency.toUpperCase()}
              </div>
              <div className="red_item item15">
                <div className="actiuni flx sb around_edit">
                  <div
                    onClick={this.editareProdusCaramidaKlinker(
                      child.idLinieComanda
                    )}
                    data-id-produs={child.idLinieComanda}
                    className="btn_general editare editare_cos bd"
                  >
                    <img className="actions_img" src={edit} alt="" />
                  </div>
                  <div
                    onClick={this.stergeProdusCaramidaKlinker(
                      child.idLinieComanda
                    )}
                    data-id-produs={child.idLinieComanda}
                    className="btn_general stergere stergere_produs_cos bd"
                  >
                    <img className="actions_img" src={delete1} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="cap_tabel cap_tabel_cart item_cos">
              <div className="black_item item55">
                <div className="blank100" />
                {child.denumireMortar}
              </div>
              <div className="red_item item15">--</div>
              <div className="red_item item15">
                {parseFloat(child.klinker_mortar / child.cursIntrodus)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {"  "}
                {this.state.defaultCurrency.toUpperCase()}
              </div>
              <div className="red_item item15" />
            </div>
            <div className="cap_tabel cap_tabel_cart item_cos">
              <div className="black_item item55">
                <div className="blank100" />
                Paleți
              </div>
              <div className="red_item item15">{child.paleti} buc</div>
              <div className="red_item item15">
                {parseFloat((child.paleti * 25) / child.cursIntrodus)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {"  "}
                {this.state.defaultCurrency.toUpperCase()}
              </div>
              <div className="red_item item15" />
            </div>
          </div>
        );
      }
    );

    // Produse caramida blocuri ceramice
    const content_caramida_blocuri = Array.from(
      this.state.produse_caramida_blocuri,
      child => {
        console.log(child);
        return (
          <div>
            <div
              className="cap_tabel cap_tabel_cart item_cos"
              key={child.idLinieComanda}
            >
              <div className="black_item flex_item_produs item55">
                {" "}
                <img className="img_produs" src={child.imagine} alt="" />
                <div className="denumire_produs_cos">{child.titluProdus}</div>
              </div>
              <div className="red_item item15">
                {child.bucati_livrabil
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                buc
              </div>
              <div className="red_item item15">
                {parseFloat(child.pret_livrabil / child.cursIntrodus)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {"  "}
                {this.state.defaultCurrency.toUpperCase()}
              </div>
              <div className="red_item item15">
                <div className="actiuni flx sb around_edit">
                  <div
                    onClick={this.editareProdusCaramidaBlocuri(
                      child.idLinieComanda
                    )}
                    data-id-produs={child.idLinieComanda}
                    className="btn_general editare editare_cos bd"
                  >
                    <img className="actions_img" src={edit} alt="" />
                  </div>
                  <div
                    onClick={this.stergeProdusCaramidaBlocuri(
                      child.idLinieComanda
                    )}
                    data-id-produs={child.idLinieComanda}
                    className="btn_general stergere stergere_produs_cos bd"
                  >
                    <img className="actions_img" src={delete1} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="cap_tabel cap_tabel_cart item_cos">
              <div className="black_item item55">
                <div className="blank100" />
                Paleți
              </div>
              <div className="red_item item15">
                {child.paleti_livrabil
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                buc
              </div>
              <div className="red_item item15">
                {parseFloat(child.pret_paleti / child.cursIntrodus)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {"  "}
                {this.state.defaultCurrency.toUpperCase()}
              </div>
              <div className="red_item item15" />
            </div>
          </div>
        );
      }
    );

    // Produse PLACAJ KLINKER
    const content_placaj_klinker = Array.from(
      this.state.produse_placaj_klinker,
      child => {
        var original = child.denumirePlacaj;
        var result = original.substr(original.indexOf(" ") + 1);
        return (
          <div>
            <div
              className="cap_tabel cap_tabel_cart item_cos"
              key={child.idLinieComanda}
            >
              <div className="black_item flex_item_produs item55">
                {" "}
                <img
                  className="img_produs"
                  src={require("../assets/img/klinker/" + child.poza_placaj)}
                  alt=""
                />
                <div className="denumire_produs_cos">
                  {child.denumirePlacaj}
                </div>
              </div>
              <div className="red_item item15">
                {child.cantitate_placaj_bucati
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                buc
              </div>
              <div className="red_item item15">
                {parseFloat(child.placaj / child.cursIntrodus)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {"  "}
                {this.state.defaultCurrency.toUpperCase()}
              </div>
              <div className="red_item item15">
                <div className="actiuni flx sb around_edit">
                  <div
                    onClick={this.editareProdusPlacajKlinker(
                      child.idLinieComanda
                    )}
                    data-id-produs={child.idLinieComanda}
                    className="btn_general editare editare_cos bd"
                  >
                    <img className="actions_img" src={edit} alt="" />
                  </div>
                  <div
                    onClick={this.stergeProdusPlacajKlinker(
                      child.idLinieComanda
                    )}
                    data-id-produs={child.idLinieComanda}
                    className="btn_general stergere stergere_produs_cos bd"
                  >
                    <img className="actions_img" src={delete1} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="cap_tabel cap_tabel_cart item_cos">
              <div className="black_item item55">
                <div className="blank100" />
                Coltar {result} {child.denumireModel}
              </div>
              <div className="red_item item15">
                {child.cantitate_coltare_bucati
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                buc
              </div>
              <div className="red_item item15">
                {parseFloat(child.coltar / child.cursIntrodus)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {"  "}
                {this.state.defaultCurrency.toUpperCase()}
              </div>
              <div className="red_item item15" />
            </div>
            <div className="cap_tabel cap_tabel_cart item_cos">
              <div className="black_item item55">
                <div className="blank100" />
                Adeziv {child.denumireAdeziv}
              </div>
              <div className="red_item item15">--</div>
              <div className="red_item item15">
                {parseFloat(child.adeziv / child.cursIntrodus)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {"  "}
                {this.state.defaultCurrency.toUpperCase()}
              </div>
              <div className="red_item item15" />
            </div>
            <div className="cap_tabel cap_tabel_cart item_cos">
              <div className="black_item item55">
                <div className="blank100" />
                {child.denumireChit} {child.culoareChit}
              </div>
              <div className="red_item item15">--</div>
              <div className="red_item item15">
                {parseFloat(child.chit / child.cursIntrodus)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                {"  "}
                {this.state.defaultCurrency.toUpperCase()}
              </div>
              <div className="red_item item15" />
            </div>
          </div>
        );
      }
    );

    this.setState({
      content_caramida_klinker: content_caramida_klinker,
      content_caramida_blocuri: content_caramida_blocuri,
      content_placaj_klinker: content_placaj_klinker,

      cartUpdated: 1
    });
  }

  componentWillMount() {
    console.log(this.state);
    console.log(localStorage);
    // Preluare produse din BD
    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels2.php`, {
        ultima_comanda: localStorage.getItem("ultima_comanda"),
        get_data: "populare_cos"
      })
      .then(res => {
        console.log(res.data);

        this.setState({
          defaultCurrency: localStorage.defaultCurrency
        });

        this.setState(
          {
            produse_caramida_klinker: res.data.produse.caramida_klinker,
            produse_caramida_blocuri: res.data.produse.caramida_blocuri,
            produse_placaj_klinker: res.data.produse.placaj_klinker,
            totalCos: res.data.produse.totaluri
          },
          () => {
            this.updateCart();
          }
        );
      });

    // Prelucrare judete
    var loop = 0;
    var listItems = this.state.listaJudete.map(judet => (
      <option key={judet} value={judet}>
        {judet}
      </option>
    ));
    this.setState({
      optionsJudet: listItems
    });
  }

  toggleDiv = event => {
    $(this.refs["date_cumparator_form"]).slideToggle();
  };

  // Editare
  editareProdusCaramidaKlinker = id => event => {
    this.setState({
      id_editare: id,
      show_popup_editare: 1,
      redirect: true,
      redirectPath: "/caramida-klinker"
    });
  };

  editareProdusPlacajKlinker = id => event => {
    this.setState({
      id_editare: id,
      show_popup_editare: 1,
      redirect: true,
      redirectPath: "/placaj-klinker"
    });
  };

  editareProdusCaramidaBlocuri = id => event => {
    this.setState({
      id_editare: id,
      show_popup_editare: 1,
      redirect: true,
      redirectPath: "/caramida-blocuri-ceramice"
    });
  };

  editeazaProdusOk = event => {
    if (this.state.redirect) {
      // Ascunde popup
      this.setState({
        show_popup_editare: 0
      });

      // Redirectare catre pagina specifica in functie de tipul editarii
      if (this.state.redirectPath === "/caramida-blocuri-ceramice") {
        this.state.produse_caramida_blocuri.map(linie => {
          if (linie.idLinieComanda == this.state.id_editare) {
            this.props.history.push({
              pathname: this.state.redirectPath,
              state: { dataToEdit: linie }
            });
          }
        });
      } else if (this.state.redirectPath === "/caramida-klinker") {
        this.state.produse_caramida_klinker.map(linie => {
          if (linie.idLinieComanda == this.state.id_editare) {
            this.props.history.push({
              pathname: this.state.redirectPath,
              state: { dataToEdit: linie }
            });
          }
        });
      } else if (this.state.redirectPath === "/placaj-klinker") {
        this.state.produse_placaj_klinker.map(linie => {
          if (linie.idLinieComanda == this.state.id_editare) {
            this.props.history.push({
              pathname: this.state.redirectPath,
              state: { dataToEdit: linie }
            });
          }
        });
      }
    }
  };

  // Stergere
  stergeProdusCaramidaKlinker = id => event => {
    this.setState({
      id_stergere: id,
      show_popup_stergere: 1,
      sursaStergere: "caramida_klinker"
    });
  };

  stergeProdusCaramidaBlocuri = id => event => {
    this.setState({
      id_stergere: id,
      show_popup_stergere: 1,
      sursaStergere: "caramida_blocuri"
    });
  };

  stergeProdusPlacajKlinker = id => event => {
    this.setState({
      id_stergere: id,
      show_popup_stergere: 1,
      sursaStergere: "placaj_klinker"
    });
  };

  stergeProdusOk = event => {
    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        id_produs: this.state.id_stergere,
        sursa: this.state.sursaStergere,
        get_data: "stergere_produs_cos"
      })
      .then(res => {
        this.setState({
          show_popup_stergere: 0
        });

        axios
          .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
            ultima_comanda: localStorage.getItem("ultima_comanda"),
            get_data: "numar_produse_comanda"
          })
          .then(res => {
            this.setState({
              nr_produse: res.data
            });
          });

        axios
          .post(`https://www.brikston.ro/ajax_calculator/getModels2.php`, {
            ultima_comanda: localStorage.getItem("ultima_comanda"),
            get_data: "populare_cos"
          })
          .then(res => {
            this.setState(
              {
                produse_caramida_klinker: res.data.produse.caramida_klinker,
                produse_caramida_blocuri: res.data.produse.caramida_blocuri,
                produse_placaj_klinker: res.data.produse.placaj_klinker,
                totalCos: res.data.produse.totaluri
              },
              () => {
                // var produse_total =
                //   this.state.produse_caramida_blocuri.length +
                //   this.state.produse_caramida_klinker.length +
                //   this.state.produse_placaj_klinker.length;
                // this.setState({
                //   nr_produse: produse_total
                // });

                // Sterg elemente din localStorage daca nu mai exista nici un produs in cos
                if (
                  this.state.produse_caramida_blocuri.length == 0 &&
                  this.state.produse_caramida_klinker == 0 &&
                  this.state.produse_placaj_klinker == 0
                ) {
                  localStorage.clear();
                }

                this.updateCart();
              }
            );
          });
      });
  };

  componentWillReceiveProps(newProps) {
    console.log(newProps);
  }

  // Inchidere popup
  closePopup = event => {
    this.setState({
      show_popup_stergere: 0,
      show_popup_editare: 0
    });
  };

  // Afisare/ascundere div-uri
  toggleDiv1 = event => {
    $(this.refs["date_furnizor_form"]).slideToggle();
  };

  // Trimite oferta pret
  trimiteOfertaPret = e => {
    if (this.state.adresaEmail !== "") {
      // Validare email
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.state.adresaEmail
        )
      ) {
        // Acceptare termeni si conditii
        if (this.state.bifaAccept) {
          // Trimit email-ul cu PDF-ul atasat
          $(".loader_full").fadeIn();
          axios
            .post(
              `https://www.brikston.ro/tcpdf/examples/calculator_general.php`,
              {
                idComanda: localStorage.getItem("ultima_comanda"),
                adresaEmail: this.state.adresaEmail,
                cumparatorFirma: this.state.cumparatorFirma,
                cumparatorCUI: this.state.cumparatorCUI,
                cumparatorRegCom: this.state.cumparatorRegCom,
                cumparatorSediu: this.state.cumparatorSediu,
                cumparatorJudet: this.state.cumparatorJudet,
                cumparatorContact: this.state.cumparatorContact,
                cumparatorTelefon: this.state.cumparatorTelefon,
                furnizorFirma: this.state.furnizorFirma,
                furnizorCUI: this.state.furnizorCUI,
                furnizorRegCom: this.state.furnizorRegCom,
                furnizorSediu: this.state.furnizorSediu,
                furnizorJudet: this.state.furnizorJudet,
                furnizorContact: this.state.furnizorContact,
                furnizorTelefon: this.state.furnizorTelefon
              }
            )
            .then(res => {
              if (
                res.data.emailTrimis === 1 &&
                res.data.emailValid === 1 &&
                res.data.comandaValid === 1
              ) {
                // Succes
                // Clear localStorage
                localStorage.clear();

                // Update state
                this.setState({
                  bifaAccept: false,
                  adresaEmail: "",
                  produse_caramida_blocuri: "",
                  produse_caramida_klinker: "",
                  produse_placaj_klinker: "",
                  cumparatorFirma: "",
                  cumparatorContact: "",
                  cumparatorCUI: "",
                  cumparatorJudet: "",
                  cumparatorRegCom: "",
                  cumparatorSediu: "",
                  cumparatorTelefon: "",
                  furnizorContact: "",
                  furnizorCUI: "",
                  furnizorFirma: "",
                  furnizorJudet: "",
                  furnizorRegCom: "",
                  furnizorSediu: "",
                  furnizorTelefon: "",
                  content_caramida_blocuri: "",
                  content_caramida_klinker: "",
                  content_placaj_klinker: ""
                });

                // Redirect
                this.props.history.push({
                  pathname: "/confirmare-oferta"
                });
              } else {
                // Eroare
                if (res.data.comandaValid === 0) {
                  alert("Comanda nu există! Vă rugăm încercați din nou.");
                  this.setState({
                    bifaAccept: false
                  });
                }
                if (res.data.emailValid === 0) {
                  alert(
                    "Adresa de email nu este corectă. Vă rugăm încercați din nou."
                  );
                  this.setState({
                    bifaAccept: false
                  });
                }
                if (res.data.emailTrimis === 0) {
                  alert(
                    "Email-ul nu a fost trimis. Vă rugăm încercați din nou."
                  );
                  this.setState({
                    bifaAccept: false
                  });
                }
              }
              $(".loader_full").hide();
            });
        } else {
          e.preventDefault();
          alert("Acceptarea termenilor si conditiilor este obligatorie.");
        }
      } else {
        alert(
          "Adresă de email invalidă! Vă rugăm să adăugați o adresă de email validă."
        );
      }
    } else {
      e.preventDefault();
      alert("Adresa de email este obligatorie.");
    }
  };

  // Modificare adresa de email
  changeAdresaEmail = e => {
    this.setState({
      adresaEmail: e.target.value
    });
  };

  // Acord date personale
  acordDatePersonale = e => {
    if (e.target.checked) {
      this.setState({
        bifaAccept: true
      });
    } else {
      this.setState({
        bifaAccept: false
      });
    }
  };

  // Date cumparator
  updateCumparator = e => {
    if (e.target.name == "firma") {
      this.setState({
        cumparatorFirma: e.target.value
      });
    } else if (e.target.name == "cui") {
      this.setState({
        cumparatorCUI: e.target.value
      });
    } else if (e.target.name == "reg_com") {
      this.setState({
        cumparatorRegCom: e.target.value
      });
    } else if (e.target.name == "sediu") {
      this.setState({
        cumparatorSediu: e.target.value
      });
    } else if (e.target.name == "contact") {
      this.setState({
        cumparatorContact: e.target.value
      });
    } else if (e.target.name == "telefon") {
      this.setState({
        cumparatorTelefon: e.target.value
      });
    } else if (e.target.name == "judet") {
      this.setState({
        cumparatorJudet: e.target.value
      });
      if (e.target.value != 0) {
        this.setState({
          color1: "#000"
        });
      } else {
        this.setState({
          color1: "#666"
        });
      }
    }
  };

  // Date furnizor
  updateFurnizor = e => {
    if (e.target.name === "firma") {
      this.setState({
        furnizorFirma: e.target.value
      });
    } else if (e.target.name === "cui") {
      this.setState({
        furnizorCUI: e.target.value
      });
    } else if (e.target.name === "reg_com") {
      this.setState({
        furnizorRegCom: e.target.value
      });
    } else if (e.target.name === "sediu") {
      this.setState({
        furnizorSediu: e.target.value
      });
    } else if (e.target.name === "contact") {
      this.setState({
        furnizorContact: e.target.value
      });
    } else if (e.target.name === "telefon") {
      this.setState({
        furnizorTelefon: e.target.value
      });
    } else if (e.target.name === "judet") {
      this.setState({
        furnizorJudet: e.target.value
      });

      if (e.target.value != 0) {
        this.setState({
          color: "#000"
        });
      } else {
        this.setState({
          color: "#666"
        });
      }
    }
  };

  render() {
    const styles = {
      containerStyle: {
        color: this.state.color
      }
    };
    const { containerStyle } = styles;

    const styles1 = {
      containerStyle1: {
        color: this.state.color1
      }
    };
    const { containerStyle1 } = styles1;
    if (
      this.state.produse_caramida_blocuri.length == 0 &&
      this.state.produse_caramida_klinker.length == 0 &&
      this.state.produse_placaj_klinker.length == 0 &&
      this.state.cartUpdated == 1
    ) {
      return (
        <div
          className="background calculator_caramida "
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className="loader_cos" />
          <Headerq nr_produse={this.state.nr_produse} />
          <div className="subheader2">
            <div className="subheader" id="subheader">
              Coșul de cumpărături
            </div>
          </div>

          <div className="componenta-cart no-product" id="componenta-cart">
            <div className="header_page">
              <div className="rb bd no_product_cart">
                Momentan nu aveți niciun produs în coș.
              </div>
              <div className="title_gri gri rb bd title_calculeaza_nou">
                <Link to="/">
                  Fă un calcul nou
                  <img className="arrow_cos" src={red_arrow} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (this.state.cartUpdated == 1)
        return (
          <div
            className="background calculator_caramida"
            style={{ backgroundImage: `url(${background})` }}
          >
            <Headerq nr_produse={this.state.nr_produse} />
            <div className="subheader2">
              <div className="subheader" id="subheader">
                Coșul de cumpărături
              </div>
            </div>
            <div className="componenta-cart" id="componenta-cart">
              <div className="rezumat_cart">
                <div className="section1">
                  <div className="calculator-field-title medium tl_mare">
                    1. Rezumatul coșului
                  </div>
                  <div className="continut_cos_cumparaturi">
                    <div className="tabel_consum">
                      <div className="cap_tabel">
                        <div className="item-title item55">Produse</div>
                        <div className="item-title item15">Cantitate</div>
                        <div className="item-title item15">Preț (fără TVA)</div>
                      </div>
                      {this.state.content_caramida_klinker}
                      {this.state.content_caramida_blocuri}
                      {this.state.content_placaj_klinker}
                      <div className="cap_tabel cap_tabel_total">
                        <div className="black_item item55">
                          <div className="blank100" />
                          TOTAL
                        </div>
                        <div className="red_item item15" />
                        <div className="red_item item15">
                          {(this.state.totalCos / localStorage.cursIntrodus)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          {"  "}
                          {this.state.defaultCurrency.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header_page inca_un_produs">
                  <div className="title_gri gri rb bd title_calculeaza_nou">
                    <Link to="/">
                      Calculează necesarul pentru încă un produs
                      <img className="arrow_cos" src={red_arrow} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="section2 section_general">
                  <div onClick={this.toggleDiv}>
                    <div className="calculator-field-title medium tl_mare">
                      2. Date cumpărător
                    </div>
                  </div>
                  <div
                    className="date_cumparator_form date_generale"
                    ref="date_cumparator_form"
                  >
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  firma"
                        name="firma"
                        placeholder="Firma"
                        onChange={this.updateCumparator}
                        value={this.state.cumparatorFirma}
                      />
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  cui"
                        name="cui"
                        placeholder="CUI"
                        onChange={this.updateCumparator}
                        value={this.state.cumparatorCUI}
                      />
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  reg_com"
                        name="reg_com"
                        placeholder="Reg. Com."
                        onChange={this.updateCumparator}
                        value={this.state.cumparatorRegCom}
                      />
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  sediu"
                        name="sediu"
                        placeholder="Sediu"
                        onChange={this.updateCumparator}
                        value={this.state.cumparatorSediu}
                      />
                    </div>
                    <div className="div_input">
                      <select
                        name="judet"
                        className="select_general judet"
                        name="judet"
                        onChange={this.updateCumparator}
                        value={this.state.cumparatorJudet}
                        style={containerStyle1}
                      >
                        <option value="0">Judet</option>
                        {this.state.optionsJudet}
                      </select>
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  contact"
                        name="contact"
                        placeholder="Nume persoană contact"
                        onChange={this.updateCumparator}
                        value={this.state.cumparatorContact}
                      />
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  telefon"
                        name="telefon"
                        placeholder="Telefon"
                        onChange={this.updateCumparator}
                        value={this.state.cumparatorTelefon}
                      />
                    </div>
                  </div>
                </div>
                <div className="section3 section_general">
                  <div onClick={this.toggleDiv1}>
                    <div className="calculator-field-title medium tl_mare">
                      3. Date furnizor
                    </div>
                  </div>
                  <div
                    className="date_furnizor_form date_generale"
                    ref="date_furnizor_form"
                  >
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  firma"
                        name="firma"
                        placeholder="Firma"
                        onChange={this.updateFurnizor}
                        value={this.state.furnizorFirma}
                      />
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  cui"
                        name="cui"
                        placeholder="CUI"
                        onChange={this.updateFurnizor}
                        value={this.state.furnizorCUI}
                      />
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  reg_com"
                        name="reg_com"
                        placeholder="Reg. Com."
                        onChange={this.updateFurnizor}
                        value={this.state.furnizorRegCom}
                      />
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  sediu"
                        name="sediu"
                        placeholder="Sediu"
                        onChange={this.updateFurnizor}
                        value={this.state.furnizorSediu}
                      />
                    </div>
                    <div className="div_input">
                      <select
                        name="judet"
                        className="judet select_general"
                        name="judet"
                        style={containerStyle}
                        onChange={this.updateFurnizor}
                        value={this.state.furnizorJudet}
                      >
                        <option value="0">Judet</option>
                        {this.state.optionsJudet}
                      </select>
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  contact"
                        name="contact"
                        placeholder="Nume persoană contact"
                        onChange={this.updateFurnizor}
                        value={this.state.furnizorContact}
                      />
                    </div>
                    <div className="div_input">
                      <input
                        type="text"
                        className="input_dreapta input_edit  telefon"
                        name="telefon"
                        placeholder="Telefon"
                        onChange={this.updateFurnizor}
                        value={this.state.furnizorTelefon}
                      />
                    </div>
                  </div>
                </div>
                <div className="section4 section_general">
                  <div className="calculator-field-title medium tl_mare">
                    4. Trimite necesarul de materiale și oferta de preț pe
                    e-mail
                  </div>

                  <div className="text-center">
                    <input
                      type="text"
                      className="input_email"
                      name="input_email"
                      onChange={this.changeAdresaEmail}
                      value={this.state.adresaEmail}
                      placeholder="Introdu adresa de e-mail"
                    />
                  </div>
                  <div className="gdpr flx">
                    <div className="gdpr_left">
                      <input
                        id="checkbox-2"
                        className="checkbox-custom chx"
                        name="checkbox-2"
                        type="checkbox"
                        onChange={this.acordDatePersonale}
                        checked={this.state.bifaAccept ? "checked" : ""}
                      />
                      <label
                        htmlFor="checkbox-2"
                        className="checkbox-custom-label"
                      >
                        Bifând căsuța, vă exprimați acordul pentru a primi
                        comunicări și oferte în scop de marketing din partea
                        Brikston. Datele personale oferite vor fi procesate
                        conform politicii de prelucrare a datelor personale
                        Brikston.
                      </label>
                    </div>
                  </div>
                  <div
                    className="btn_general trimite_oferta_pret bd"
                    onClick={this.trimiteOfertaPret}
                  >
                    Trimite oferta de preț
                  </div>
                </div>
              </div>
              <div
                className="popup_general popup_stergere"
                style={{
                  display:
                    this.state.show_popup_stergere == 1 ? "block" : "none"
                }}
              >
                <div className="popup_general_inner">
                  <div className="content_popup">
                    Doriti sa stergeti produsul din cos?
                  </div>
                  <div className="btns_block">
                    <div
                      className="sterg_produsul btn_prod_gen"
                      onClick={this.stergeProdusOk}
                    >
                      DA
                    </div>
                    <div
                      onClick={this.closePopup}
                      className="nu_sterg_produsul btn_prod_gen"
                    >
                      NU
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="popup_general popup_editare"
                style={{
                  display: this.state.show_popup_editare == 1 ? "block" : "none"
                }}
              >
                <div className="popup_general_inner">
                  <div className="content_popup">
                    Doriti sa editati produsul?
                  </div>
                  <div className="btns_block">
                    <div
                      className="sterg_produsul btn_prod_gen"
                      onClick={this.editeazaProdusOk}
                    >
                      DA
                    </div>
                    <div
                      onClick={this.closePopup}
                      className="nu_sterg_produsul btn_prod_gen"
                    >
                      NU
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="loader_full">
              <div className="bloc_centru">
                <div class="lds-roller">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="se_trimite">Se trimite oferta...</div>
              </div>
            </div>
          </div>
        );
      else return <div />;
    }
  }
}

export default Cart;
