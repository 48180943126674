import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

import Subheader from "./Subheader";
import Menu from "./Menu";
import Headerq from "./Headerq";

import product from "../assets/img/product.png";
import cart_btn from "../assets/img/cart_btn_white.png";
import background from "./../assets/img/background.jpg";

export default class NecesarCaramidaBucati extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  // Adaug in cosul de cumparaturi
  handleAddToCart = e => {
    
    // Preiau datele
    var lungime = this.props.data[0].lungime;
    var inaltime = this.props.data[0].inaltime;
    var grosime = this.props.data[0].grosime;
    var volum = this.props.data[0].volum;
    var suprafata = this.props.data[0].suprafata;
    var idProdus = this.props.data[0].idProdus;
    var numeProdus = this.props.data[0].product_name;
    var pretLista = this.props.data[0].pret;
    var discount = this.props.data[0].discount;
    var consumM3 = this.props.data[0].cantitate_m3;
    var consumM2 = this.props.data[0].cantitate_m2;
    var masaBuc = this.props.data[0].masa;
    var consumM3_mortar = this.props.data[0].cantitate_mortar_m3;
    var masaRef_mortar = this.props.data[0].masaMortarM3;
    var pretM3_nisip = this.props.data[0].pretNisipM3;
    var pretT_ciment = this.props.data[0].pretCimentT;
    var consumM2_mortar = this.props.data[0].cantitate_mortar_m2;
    var reportM3_M2 = this.props.data[0].raportM3M2;
    var volum_buc =
      (this.props.data[0].lungimeDim *
        this.props.data[0].inaltimeDim *
        this.props.data[0].latimeDim) /
      1000000;
    var necesar =
      this.props.data[0].suprafata * this.props.data[0].cantitate_m2;
    var pretAchizitie =
      (this.props.data[0].pret * (100 - this.props.data[0].discount)) / 100;
    var bucati = this.props.data[0].bucati;
    var bucPalet = this.props.data[0].bucatiPerPalet;
    var consum_mortar =
      this.props.data[0].cantitate_mortar_m3 *
      (((this.props.data[0].lungimeDim *
        this.props.data[0].inaltimeDim *
        this.props.data[0].latimeDim) /
        1000000) *
        this.props.data[0].bucati);
    var pretRef_palet = this.props.data[0].pretPalet;
    var masaRef_palet = this.props.data[0].masaPalet;
    var editareProdusCos = this.props.data[0].editareProdusCos;
    var idLinieComanda = this.props.data[0].idLinieComanda;
    var moneda = this.props.data[0].moneda.toUpperCase();
    var cursIntrodus = this.props.data[0].cursIntrodus;

    // Execut call-ul catre fisierul care se ocupa de adaugarea in cos
    axios
      .post("https://www.brikston.ro/ajax_calculator/getModels2.php", {
        get_data: "addToCartCalc1Bucati",
        moneda: moneda,
        cursIntrodus: cursIntrodus,
        editareProdusCos: editareProdusCos,
        idLinieComanda: idLinieComanda,
        idComandaNou: localStorage.getItem("ultima_comanda"),
        lungime: parseFloat(lungime).toFixed(0),
        inaltime: parseFloat(inaltime).toFixed(0),
        grosime: grosime,
        volum: parseFloat(volum).toFixed(2),
        suprafata: parseFloat(suprafata).toFixed(2),
        idProdus: idProdus,
        titluProdus: numeProdus,
        pret_lista_referinta: parseFloat(pretLista).toFixed(2),
        discount_referinta: discount,
        pret_achizitie_referinta: parseFloat(pretAchizitie).toFixed(2),
        pret_m3_referinta: parseFloat(pretAchizitie * consumM3).toFixed(2),
        pret_m2_referinta: parseFloat(pretAchizitie * consumM2).toFixed(2),
        consum_m3: consumM3,
        masa_m3: parseFloat((masaBuc * consumM3) / 1000).toFixed(2),
        pret_m3: parseFloat(pretAchizitie * consumM3).toFixed(2),
        pret_m3_tva: parseFloat(pretAchizitie * consumM3 * 1.19).toFixed(2),
        consum_m3_mortar: parseFloat(consumM3_mortar).toFixed(2),
        masa_m3_mortar: parseFloat(consumM3_mortar * masaRef_mortar).toFixed(2),
        pret_m3_mortar: parseFloat(
          consumM3_mortar * pretM3_nisip +
            (consumM3_mortar / 6.25) * pretT_ciment
        ).toFixed(2),
        pret_m3_mortar_tva: parseFloat(
          (consumM3_mortar * pretM3_nisip +
            (consumM3_mortar / 6.25) * pretT_ciment) *
            1.19
        ).toFixed(2),
        masa_m3_total: parseFloat(
          (masaBuc * consumM3) / 1000 + consumM3_mortar * masaRef_mortar
        ).toFixed(2),
        pret_m3_total: parseFloat(
          pretAchizitie * consumM3 +
            (consumM3_mortar * pretM3_nisip +
              (consumM3_mortar / 6.25) * pretT_ciment)
        ).toFixed(2),
        pret_m3_total_tva: parseFloat(
          (pretAchizitie * consumM3 +
            (consumM3_mortar * pretM3_nisip +
              (consumM3_mortar / 6.25) * pretT_ciment)) *
            1.19
        ).toFixed(2),
        consum_m2: consumM2,
        masa_m2: parseFloat((masaBuc * consumM2) / 1000).toFixed(2),
        pret_m2: parseFloat(pretAchizitie * consumM2).toFixed(2),
        pret_m2_tva: parseFloat(pretAchizitie * consumM2 * 1.19).toFixed(2),
        consum_m2_mortar: parseFloat(consumM2_mortar).toFixed(2),
        masa_m2_mortar: parseFloat(masaRef_mortar * consumM2_mortar).toFixed(2),
        pret_m2_mortar: parseFloat(
          (consumM3_mortar * pretM3_nisip +
            (consumM3_mortar / 6.25) * pretT_ciment) /
            reportM3_M2
        ).toFixed(2),
        pret_m2_mortar_tva: parseFloat(
          ((consumM3_mortar * pretM3_nisip +
            (consumM3_mortar / 6.25) * pretT_ciment) /
            reportM3_M2) *
            1.19
        ).toFixed(2),
        masa_m2_total: parseFloat(
          (masaBuc * consumM2) / 1000 + consumM2_mortar * masaRef_mortar
        ).toFixed(2),
        pret_m2_total: parseFloat(
          pretAchizitie * consumM2 +
            (consumM3_mortar * pretM3_nisip +
              (consumM3_mortar / 6.25) * pretT_ciment) /
              reportM3_M2
        ).toFixed(2),
        pret_m2_total_tva: parseFloat(
          (pretAchizitie * consumM2 +
            (consumM3_mortar * pretM3_nisip +
              (consumM3_mortar / 6.25) * pretT_ciment) /
              reportM3_M2) *
            1.19
        ).toFixed(2),
        bucati_final: bucati,
        volum_final: parseFloat(volum_buc * bucati).toFixed(2),
        paleti_final: parseFloat(bucati / bucPalet).toFixed(0),
        masa_final: parseFloat((masaBuc * bucati) / 1000).toFixed(2),
        pret_final: parseFloat(pretAchizitie * bucati).toFixed(2),
        pret_tva_final: parseFloat(pretAchizitie * bucati * 1.19).toFixed(2),
        volum_mortar: parseFloat(consum_mortar).toFixed(2),
        masa_mortar: parseFloat(masaRef_mortar * consum_mortar).toFixed(2),
        pret_mortar: parseFloat(
          consum_mortar * pretM3_nisip + (consum_mortar / 6.25) * pretT_ciment
        ).toFixed(2),
        pret_mortar_tva: parseFloat(
          (consum_mortar * pretM3_nisip +
            (consum_mortar / 6.25) * pretT_ciment) *
            1.19
        ).toFixed(2),
        volum_total: parseFloat(volum_buc * bucati + consum_mortar).toFixed(2),
        masa_total: parseFloat(
          (masaBuc * bucati) / 1000 + masaRef_mortar * consum_mortar
        ).toFixed(2),
        pret_total: parseFloat(
          pretAchizitie * bucati +
            (consum_mortar * pretM3_nisip +
              (consum_mortar / 6.25) * pretT_ciment)
        ).toFixed(2),
        pret_total_tva: parseFloat(
          (pretAchizitie * bucati +
            (consum_mortar * pretM3_nisip +
              (consum_mortar / 6.25) * pretT_ciment)) *
            1.19
        ).toFixed(2),
        bucati_livrabil: parseFloat(bucati / bucPalet).toFixed(0) * bucPalet,
        volum_livrabil: parseFloat(
          volum_buc *
            parseFloat(
              parseFloat(bucati / bucPalet).toFixed(0) * bucPalet
            ).toFixed(2)
        ).toFixed(2),
        paleti_livrabil: parseFloat(bucati / bucPalet).toFixed(0),
        masa_livrabil: parseFloat(
          (masaBuc * (parseFloat(bucati / bucPalet).toFixed(0) * bucPalet)) /
            1000
        ).toFixed(2),
        pret_livrabil:
          pretAchizitie * (parseFloat(bucati / bucPalet).toFixed(0) * bucPalet),
        pret_livrabil_tva:
          pretAchizitie *
          (parseFloat(bucati / bucPalet).toFixed(0) * bucPalet) *
          1.19,
        masa_paleti:
          (masaRef_palet * parseFloat(bucati / bucPalet).toFixed(0)) / 1000,
        pret_paleti: parseFloat(
          pretRef_palet * parseFloat(bucati / bucPalet).toFixed(0)
        ).toFixed(2),
        pret_paleti_tva: parseFloat(
          pretRef_palet * parseFloat(bucati / bucPalet).toFixed(0) * 1.19
        ).toFixed(2),
        masa_livrabil_total: parseFloat(
          (masaBuc * (parseFloat(bucati / bucPalet).toFixed(0) * bucPalet)) /
            1000 +
            (masaRef_palet * parseFloat(bucati / bucPalet).toFixed(0)) / 1000
        ).toFixed(2),
        pret_livrabil_total: parseFloat(
          pretAchizitie *
            (parseFloat(bucati / bucPalet).toFixed(0) * bucPalet) +
            pretRef_palet * parseFloat(bucati / bucPalet).toFixed(0)
        ).toFixed(2),
        pret_livrabil_total_tva: parseFloat(
          (pretAchizitie *
            (parseFloat(bucati / bucPalet).toFixed(0) * bucPalet) +
            pretRef_palet * parseFloat(bucati / bucPalet).toFixed(0)) *
            1.19
        ).toFixed(2),
        tipCalcul: "bucati",
        intrare_lungime: 0,
        intrare_inaltime: 0,
        intrare_volum: 0,
        intrare_suprafata: 0,
        intrare_bucati: parseFloat(bucati).toFixed(0)
      })
      .then(result => {
        if (
          localStorage.getItem("defaultCurrency") === null ||
          localStorage.getItem("defaultCurrency") === "" ||
          localStorage.getItem("defaultCurrency") === "null"
        ) {
          localStorage.setItem("defaultCurrency", this.props.data[0].moneda);
          localStorage.setItem("cursIntrodus", this.props.data[0].cursIntrodus);
        }

        // Redirect
        this.setState({
          redirect: true
        });
      });
  };

  render() {
    if (this.state.redirect) {
      //return <Redirect push to="/cart" />;
      window.location.href = 'https://leier.viscero.ro/reprezentanti-zonali/'; 
      return null;
    }

    return (
      <div
        className="background calculator_caramida"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Headerq />
        <div className="subheader2">
          <div className="subheader" id="subheader">
            Necesar de cărămidă {this.props.data[0].product_name}
          </div>
        </div>
        <div className="container_caramida">
          <div className="componenta-necesar-caramida flxx necesar_flex">
            <div className="imagine_necesar">
              <img
                className="img_produs1"
                src={this.props.data[0].product_image}
                alt=""
              />
            </div>
            <div className="necesar_right necesar-caramida-inner">
              <div className="mt">
                <div className="calculator-field-title medium tl_mare">
                  Date intrare
                </div>
                <div className="box_cantitate">
                  <div className="info-constructie-items flxx nowrap">
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Bucăți</span>
                      <div class="input_red">
                        {this.props.data[0].bucati
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                    </div>

                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Grosime (cm)</span>
                      <div class="input_red">{this.props.data[0].grosime}</div>
                    </div>
                    
                    {/*
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Moneda</span>
                      <div class="input_red">
                        {this.props.data[0].moneda.toUpperCase()}
                      </div>
                    </div>

                    {this.props.data[0].discount != 0 ? (
                      <div className="info-constructie-item flex_12  flx sb">
                        <span className="item-title">Discount (%)</span>
                        <div class="input_red">
                          {this.props.data[0].discount}
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                    */}
                  </div>
                </div>
              </div>
              <div className="mt">
                <div className="calculator-field-title medium tl_mare mtt">
                  Consum specific de materiale
                </div>
                <div className="tabel_consum">
                  <div className="cap_tabel cap_tabel1">
                    <div className="item-title">Tip material</div>
                    <div className="item-title">
                      Cantitate <br />
                      (buc/m<sup>2</sup> sau l/m<sup>2</sup> zidarie)
                    </div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (buc/m<sup>3</sup> sau l/m<sup>3</sup> zidarie)
                    </div>
                  </div>
                  <div className="cap_tabel cap_tabel1">
                    <div className="black_item">
                      {this.props.data[0].product_name}
                    </div>
                    <div className="red_item">
                      {this.props.data[0].cantitate_m2
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {this.props.data[0].cantitate_m3
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
{/*
                  <div className="cap_tabel nob cap_tabel1">
                    <div className="black_item">Mortar</div>
                    <div className="red_item">
                      {" "}
                      {parseFloat(this.props.data[0].cantitate_mortar_m2)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(this.props.data[0].cantitate_mortar_m3)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                      */}

                </div>
              </div>

              <div className="mt">
                <div className="calculator-field-title medium tl_mare mtt">
                  Cantități livrabile (în funcție de unități de împachetare)
                </div>

                <div className="tabel_consum">
                  <div className="cap_tabel">
                    <div className="item-title">Tip material</div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (buc)
                    </div>
                    <div className="item-title">
                      Volum
                      <br />
                      (m<sup>3</sup>)
                    </div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (paleți)
                    </div>
                    <div className="item-title">
                      Masa
                      <br />
                      (tone)
                    </div>
                    {/*<div className="item-title">
                      Valoare
                      <br /> (fără TVA)
                    </div>
                    <div className="item-title">
                      Valoare <br />
                      (cu TVA)
                    </div>
                    */}
                  </div>
                  <div className="cap_tabel">
                    <div className="black_item">
                      {this.props.data[0].product_name}
                    </div>
                    <div className="red_item">
                      {this.props.data[0].livrabil_caramida_cantitate
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {this.props.data[0].livrabil_caramida_volum
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {this.props.data[0].livrabil_caramida_paleti
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {this.props.data[0].livrabil_caramida_masa
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    {/*
                    <div className="red_item">
                      {this.props.data[0].livrabil_caramida_valoare_fara_tva
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        this.props.data[0].livrabil_caramida_valoare_fara_tva *
                          1.19
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                      */}
                  </div>

                  <div className="cap_tabel">
                    <div className="black_item">Paleți</div>
                    <div className="red_item">
                      {this.props.data[0].livrabil_paleti_cantitate
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {this.props.data[0].livrabil_paleti_masa
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    {/*
                    <div className="red_item">
                      {this.props.data[0].livrabil_paleti_valoare_fara_tva
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        this.props.data[0].livrabil_paleti_valoare_fara_tva *
                          1.19
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                      */}
                  </div>

                  <div className="cap_tabel cap_tabel_total">
                    <div className="black_item">TOTAL</div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {" "}
                      {this.props.data[0].livrabil_caramida_volum
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {parseFloat(
                        parseFloat(this.props.data[0].livrabil_caramida_masa) +
                          parseFloat(this.props.data[0].livrabil_paleti_masa)
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    {/*
                    <div className="red_item">
                      {" "}
                      {parseFloat(
                        parseFloat(
                          this.props.data[0].livrabil_caramida_valoare_fara_tva
                        ) +
                          parseFloat(
                            this.props.data[0].livrabil_paleti_valoare_fara_tva
                          )
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        parseFloat(
                          this.props.data[0]
                            .livrabil_caramida_valoare_fara_tva * 1.19
                        ) +
                          parseFloat(
                            this.props.data[0]
                              .livrabil_paleti_valoare_fara_tva * 1.19
                          )
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                          */}
                  </div>
                </div>
              </div>
              <div className="butoane_cart">
                <Link
                  to={{
                    pathname: "/caramida-blocuri-ceramice",
                    data: {
                      refaCalculData: this.props.data[0],
                      sourceRefaCalcul: 1
                    }
                  }}
                >
                  <div className="btn_general refa_calculul bd">
                    Refă calculul
                  </div>
                </Link>
                <div
                  className="btn_general adauga_oferta_in_cos bd"
                  onClick={this.handleAddToCart}
                >
                  Contactează un agent leier pentru ofertă
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
