import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Subheader from "./Subheader";
import Menu from "./Menu";
import Headerq from "./Headerq";

import background from "./../assets/img/background.jpg";

// Import assets
import product_image from "../assets/img/klinker/dummy-img.png";
import edit_icon from "../assets/img/edit.png";

class CaramidaBlocuriCeramice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Editare produs cos
      editareProdusCos: 0,
      idLinieComanda: 0,

      // Lungime totala paleti
      lungimePereti: 0,
      inputLungimePereti: true,
      lungimePeretiED: "input_edit",

      // Inaltime perete
      inaltimePerete: 0,
      inputInaltimePerete: true,
      inaltimePereteED: "input_edit",
      active_inputs: false,

      // Pret
      pret: 0,
      inputPret: true,
      pretED: "input_edit",

      // Discount
      discount: 0,
      inputDiscount: true,
      discountED: "input_edit",

      // Volum
      volum: "",
      inputVolum: false,
      volumED: "input_no_edit",

      // Suprafata zidarie
      suprafataZidarie: "",
      inputSuprafataZidarie: false,
      suprafataZidarieED: "input_no_edit",

      // Numar bucati
      numarBucati: 0,
      inputNumarBucati: false,
      numarBucatiED: "input_no_edit",

      //Produse
      produse: [],
      productImage: product_image,
      productName: "",

      // Currency
      defaultCurrency: "lei",
      cursIntrodus: 1,
      cursIntrodusAfisat: 0,

      // Valoare default pentru modalitatea de calcul
      optionModalitate: "lungime",

      // Valoare default pentru select-ul de produse
      optionProduse: 0,

      // Valoare default pentru grosime
      optionGrosime: 0,
      optionGrosimeText: 0,

      // Lungime, latime, inaltime, cantitateM2
      lungime: 0,
      latime: 0,
      inaltime: 0,
      cantitateM2: 0
    };
  }

  // Actualizare valoare `lungimePaleti`
  changeDetaliiConstructie = e => {
    var targetValueModalitate = e.target.attributes.id.value;
    var targetValue = e.target.value;

    // Se preia imaginea si pretul
    if (targetValueModalitate == "inputLungimePereti") {
      // Calculez suprafata
      var suprafata = targetValue * this.state.inaltimePerete;

      // Calculez volumn
      var volum =
        (targetValue *
          this.state.inaltimePerete *
          this.state.optionGrosimeText) /
        100;

      // Setez state-ul
      this.setState({
        lungimePereti: targetValue,
        suprafataZidarie: suprafata,
        inputSuprafataZidarie: true,
        volum: volum,
        inputVolum: true,
        volumED: "input_no_edit",
        inputNumarBucati: true,
        numarBucatiED: "input_no_edit",
        numarBucati:
          this.state.inaltimePerete * targetValue * this.state.cantitateM2
      });
    } else if (targetValueModalitate == "inputInaltimePerete") {
      // Calculez suprafata
      var suprafata = targetValue * this.state.lungimePereti;

      // Calculez volumn
      var volum =
        (targetValue *
          this.state.lungimePereti *
          this.state.optionGrosimeText) /
        100;

      this.setState({
        inaltimePerete: targetValue,
        suprafataZidarie: Number.isInteger(suprafata)
          ? suprafata
          : parseFloat(suprafata).toFixed(2),
        inputSuprafataZidarie: true,
        volum: Number.isInteger(volum) ? volum : parseFloat(volum).toFixed(2),
        inputVolum: true,
        volumED: "input_no_edit",
        inputNumarBucati: true,
        numarBucatiED: "input_no_edit",
        numarBucati: Number.isInteger(
          this.state.lungimePereti * targetValue * this.state.cantitateM2
        )
          ? this.state.lungimePereti * targetValue * this.state.cantitateM2
          : parseFloat(
              this.state.lungimePereti * targetValue * this.state.cantitateM2
            ).toFixed(2)
      });
    } else if (targetValueModalitate == "inputPret") {
      this.setState({
        pret: targetValue
      });
    } else if (targetValueModalitate == "inputDiscount") {
      this.setState({
        discount: targetValue
      });
    } else if (targetValueModalitate == "inputVolum") {
      // Calculez suprafata zidarie
      var suprafataZidarie = (targetValue / this.state.optionGrosimeText) * 100;

      this.setState({
        volum: targetValue,
        suprafataZidarie: Number.isInteger(suprafataZidarie)
          ? suprafataZidarie
          : parseFloat(suprafataZidarie).toFixed(2),
        inputNumarBucati: true,
        inputSuprafataZidarie: true,
        numarBucatiED: "input_no_edit",
        numarBucati: parseFloat(
          suprafataZidarie * this.state.cantitateM2
        ).toFixed(0)
      });
    } else if (targetValueModalitate == "inputSuprafataZidarie") {
      // Calculez volumul
      var volum = (targetValue * this.state.optionGrosimeText) / 100;

      this.setState({
        suprafataZidarie: targetValue,
        volum: Number.isInteger(volum) ? volum : parseFloat(volum).toFixed(2),
        inputNumarBucati: true,
        numarBucatiED: "input_no_edit",
        numarBucati: parseFloat(targetValue * this.state.cantitateM2).toFixed(0)
      });
    } else if (targetValueModalitate == "inputNumarBucati") {
      // Calculez volum
      var volumBuc =
        (this.state.lungime * this.state.latime * this.state.inaltime) /
        1000000;
      var volum = volumBuc * targetValue;

      // Calculez suprafata zidarie
      var suprafataZidarie = (volum / this.state.optionGrosimeText) * 100;

      this.setState({
        numarBucati: targetValue,
        volum: Number.isInteger(volum) ? volum : parseFloat(volum).toFixed(2),
        suprafataZidarie: Number.isInteger(suprafataZidarie)
          ? suprafataZidarie
          : parseFloat(suprafataZidarie).toFixed(2)
      });
    }
  };

  produsSelectat = e => {
    if (this.refs.selectBCProduse) {
      // Setez state-ul pentru `optionProduse`
      this.setState({
        optionProduse: this.refs.selectBCProduse.value,
        productName: this.refs.selectBCProduse[
          this.refs.selectBCProduse.selectedIndex
        ].text
      });

      this.setState({
        active_inputs: true
      });

      // Se preia imaginea si pretul
      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "imagine_pret_produs",
          idProdus: this.refs.selectBCProduse.value,
          grosimeValue: this.state.optionGrosimeText
        })
        .then(res => {
          let output = res.data;
          Object.keys(output).map(key =>
            this.setState(
              {
                productImage:
                  "https://www.brikston.ro/uploads/" + output[key].imagine,
                pret: output[key].pret,
                lungime: output[key].lungime,
                latime: output[key].latime,
                inaltime: output[key].inaltime,
                cantitateM2: output[key].cantitateM2
              },
              () => {
                // Numar bucati
                if (this.state.optionModalitate === "bucati") {
                  var volumBuc =
                    (this.state.lungime *
                      this.state.latime *
                      this.state.inaltime) /
                    1000000;
                  var volum = volumBuc * this.state.numarBucati;

                  // Calculez suprafata zidarie
                  var suprafataZidarie =
                    (volum / this.state.optionGrosimeText) * 100;

                  // Update state
                  this.setState(prevState => ({
                    numarBucati: this.state.numarBucati,
                    suprafataZidarie: suprafataZidarie,
                    volum: volum
                  }));
                } else if (this.state.optionModalitate === "lungime") {
                  var numarBucati =
                    this.state.inaltimePerete *
                    this.state.lungimePereti *
                    this.state.cantitateM2;
                  this.setState({
                    numarBucati: numarBucati
                  });
                }
              }
            )
          );
        });
    }
  };

  // Functie ce schimba valoare selectata pentru select-ul de grosime
  grosimeSelectata = e => {
    if (this.refs.selectBCGrosime) {
      this.setState({
        active_inputs: false
      });
      if (this.state.optionModalitate === "lungime") {
        this.setState({
          inputVolum: false,
          inputInaltimePerete: true,
          inputLungimePereti: true,
          inputSuprafataZidarie: false,
          inputNumarBucati: false,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_no_edit",
          inaltimePereteED: "input_edit",
          lungimePeretiED: "input_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_no_edit",
          optionGrosime: this.refs.selectBCGrosime.value,
          optionGrosimeText: this.refs.selectBCGrosime[
            this.refs.selectBCGrosime.selectedIndex
          ].text,
          optionProduse: 0,
          productImage: product_image,
          latime: 0,
          lungime: 0,
          inaltime: 0
        });
      }

      if (this.state.optionModalitate === "volum") {
        this.setState({
          inputVolum: true,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          inputSuprafataZidarie: false,
          inputNumarBucati: false,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_no_edit",
          optionGrosime: this.refs.selectBCGrosime.value,
          optionGrosimeText: this.refs.selectBCGrosime[
            this.refs.selectBCGrosime.selectedIndex
          ].text,
          optionProduse: 0,
          productImage: product_image,
          latime: 0,
          lungime: 0,
          inaltime: 0
        });
      }

      if (this.state.optionModalitate === "suprafata") {
        this.setState({
          inputVolum: false,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          inputSuprafataZidarie: true,
          inputNumarBucati: false,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_no_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_edit",
          numarBucatiED: "input_no_edit",
          optionGrosime: this.refs.selectBCGrosime.value,
          optionGrosimeText: this.refs.selectBCGrosime[
            this.refs.selectBCGrosime.selectedIndex
          ].text,
          optionProduse: 0,
          productImage: product_image,
          latime: 0,
          lungime: 0,
          inaltime: 0
        });
      }

      if (this.state.optionModalitate === "bucati") {
        this.setState({
          inputVolum: false,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          inputSuprafataZidarie: false,
          inputNumarBucati: true,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_no_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_edit",
          optionGrosime: this.refs.selectBCGrosime.value,
          optionGrosimeText: this.refs.selectBCGrosime[
            this.refs.selectBCGrosime.selectedIndex
          ].text,
          optionProduse: 0,
          productImage: product_image,
          latime: 0,
          lungime: 0,
          inaltime: 0
        });
      }

      // Preiau produsele
      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "produse_by_grosime",
          categorie: this.refs.selectBCGrosime.value
        })
        .then(res => {
          this.setState({ produse: res.data });
        });
    }
  };

  // Functie ce face anumite campuri editabile in functie de modalitatea de calcul selectata
  changeSelectOption = e => {
    if (this.refs.modalitate_calcul_select) {
      this.setState({
        optionModalitate: this.refs.modalitate_calcul_select.value,
        optionGrosime: 0,
        optionProduse: 0,
        productImage: product_image
      });

      this.setState({
        active_inputs: false
      });

      if (this.refs.modalitate_calcul_select.value == "volum") {
        this.setState({
          inputVolum: true,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          inputSuprafataZidarie: false,
          inputNumarBucati: false,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_no_edit"
        });
      } else if (this.refs.modalitate_calcul_select.value == "suprafata") {
        this.setState({
          inputVolum: false,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          inputSuprafataZidarie: true,
          inputNumarBucati: false,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_no_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_edit",
          numarBucatiED: "input_no_edit"
        });
      } else if (this.refs.modalitate_calcul_select.value == "lungime") {
        this.setState({
          inputVolum: false,
          inputInaltimePerete: true,
          inputLungimePereti: true,
          inputSuprafataZidarie: false,
          inputNumarBucati: false,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_no_edit",
          inaltimePereteED: "input_edit",
          lungimePeretiED: "input_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_no_edit"
        });
      } else if (this.refs.modalitate_calcul_select.value == "bucati") {
        this.setState({
          inputVolum: false,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          inputSuprafataZidarie: false,
          inputNumarBucati: true,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_no_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_edit"
        });
      }
    }
  };

  // Functie pentru validare inainte de submit
  calculeazaBtn = e => {
    if (this.state.optionProduse === 0) {
      e.preventDefault();
      alert("Selectarea produsului este obligatorie!");
    }

    if (this.state.cursIntrodus == "" && this.state.defaultCurrency != "lei") {
      e.preventDefault();
      alert("Vă rugăm să introduceți cursul pentru moneda aleasă.");
    }
  };

  componentWillMount() {
    console.log(this.props.location.data);
    // Refacere calcul
    if (
      this.props.location.data != undefined &&
      this.props.location.data != "undefined" &&
      this.props.location.data != "" &&
      this.props.location.data.sourceRefaCalcul == 1 &&
      this.props.location.data.refaCalculData != ""
    ) {
      // Afisez input-uri in functie de modalitatea de calcul
      if (
        this.props.location.data.refaCalculData.modalitateCalcul === "lungime"
      ) {
        this.setState({
          inputVolum: true,
          inputSuprafataZidarie: true,
          inputNumarBucati: true,
          inputInaltimePerete: true,
          inputLungimePereti: true,
          volumED: "input_no_edit",
          inaltimePereteED: "input_edit",
          lungimePeretiED: "input_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_no_edit",
          active_inputs: true
        });
      } else if (
        this.props.location.data.refaCalculData.modalitateCalcul === "volum"
      ) {
        this.setState({
          inputVolum: true,
          inputSuprafataZidarie: true,
          inputNumarBucati: true,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          volumED: "input_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_no_edit",
          active_inputs: true
        });
      } else if (
        this.props.location.data.refaCalculData.modalitateCalcul === "suprafata"
      ) {
        this.setState({
          inputVolum: true,
          inputSuprafataZidarie: true,
          inputNumarBucati: true,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          volumED: "input_no_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_edit",
          numarBucatiED: "input_no_edit",
          active_inputs: true
        });
      } else if (
        this.props.location.data.refaCalculData.modalitateCalcul === "bucati"
      ) {
        this.setState({
          inputVolum: true,
          inputSuprafataZidarie: true,
          inputNumarBucati: true,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          volumED: "input_no_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_edit",
          active_inputs: true
        });
      }

      // Afisez inputul pentru curs
      if (this.props.location.data.refaCalculData.moneda != "lei") {
        this.setState({
          cursIntrodusAfisat: 1
        });
      }

      this.setState(
        {
          optionModalitate: this.props.location.data.refaCalculData
            .modalitateCalcul,
          optionGrosime:
            "g_" +
            this.props.location.data.refaCalculData.grosime.replace(".", "_"),
          optionGrosimeText: this.props.location.data.refaCalculData.grosime,
          productImage: this.props.location.data.refaCalculData.product_image,
          productName: this.props.location.data.refaCalculData.product_name,
          latime: this.props.location.data.refaCalculData.latimeDim,
          inaltime: this.props.location.data.refaCalculData.inaltimeDim,
          lungime: this.props.location.data.refaCalculData.lungimeDim,
          lungimePereti: this.props.location.data.refaCalculData.lungime,
          inaltimePerete: this.props.location.data.refaCalculData.inaltime,
          volum: this.props.location.data.refaCalculData.volum,
          suprafataZidarie: this.props.location.data.refaCalculData.suprafata,
          numarBucati: this.props.location.data.refaCalculData.bucati,
          pret: this.props.location.data.refaCalculData.pret,
          discount: this.props.location.data.refaCalculData.discount,
          defaultCurrency: this.props.location.data.refaCalculData.moneda,
          cantitateM2: this.props.location.data.refaCalculData.cantitate_m2,
          cursIntrodus: this.props.location.data.refaCalculData.cursIntrodus,
          inputVolum: true,
          inputSuprafataZidarie: true,
          inputNumarBucati: true,
          editareProdusCos: this.props.location.data.refaCalculData
            .editareProdusCos,
          idLinieComanda: this.props.location.data.refaCalculData.idLinieComanda
        },
        () => {
          axios
            .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
              get_data: "produse_by_grosime",
              categorie:
                "g_" +
                this.props.location.data.refaCalculData.grosime.replace(
                  ".",
                  "_"
                )
            })
            .then(res => {
              this.setState({
                produse: res.data,
                optionProduse: this.props.location.data.refaCalculData.idProdus
              });
            });
        }
      );
    }

    // Update state in functie de tipul calculului
    if (this.props.location.state !== undefined) {
      // Updatare UI in functie de tipul calculului
      if (this.props.location.state.dataToEdit.tipCalcul == "volum") {
        this.setState({
          inputVolum: true,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          inputSuprafataZidarie: true,
          inputNumarBucati: true,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_no_edit",
          editareProdusCos: 1,
          active_inputs: true
        });
      } else if (
        this.props.location.state.dataToEdit.tipCalcul == "suprafata"
      ) {
        this.setState({
          inputVolum: true,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          inputSuprafataZidarie: true,
          inputNumarBucati: true,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_no_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_edit",
          numarBucatiED: "input_no_edit",
          editareProdusCos: 1,
          active_inputs: true
        });
      } else if (this.props.location.state.dataToEdit.tipCalcul == "lungime") {
        this.setState({
          inputVolum: true,
          inputInaltimePerete: true,
          inputLungimePereti: true,
          inputSuprafataZidarie: true,
          inputNumarBucati: true,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_no_edit",
          inaltimePereteED: "input_edit",
          lungimePeretiED: "input_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_no_edit",
          editareProdusCos: 1,
          active_inputs: true
        });
      } else if (this.props.location.state.dataToEdit.tipCalcul == "bucati") {
        this.setState({
          inputVolum: true,
          inputInaltimePerete: false,
          inputLungimePereti: false,
          inputSuprafataZidarie: true,
          inputNumarBucati: true,
          volum: 0,
          inaltimePerete: 0,
          lungimePereti: 0,
          suprafataZidarie: 0,
          numarBucati: 0,
          pret: 0,
          discount: 0,
          volumED: "input_no_edit",
          inaltimePereteED: "input_no_edit",
          lungimePeretiED: "input_no_edit",
          suprafataZidarieED: "input_no_edit",
          numarBucatiED: "input_edit",
          editareProdusCos: 1,
          active_inputs: true
        });
      }

      // Preiau produsele
      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "produse_by_grosime",
          categorie:
            "g_" +
            this.props.location.state.dataToEdit.grosime.replace(".", "_")
        })
        .then(res => {
          this.setState({
            produse: res.data,
            optionModalitate: this.props.location.state.dataToEdit.tipCalcul,
            optionGrosimeText: this.props.location.state.dataToEdit.grosime,
            optionGrosime:
              "g_" +
              this.props.location.state.dataToEdit.grosime.replace(".", "_"),
            optionProduse: this.props.location.state.dataToEdit.idProdus,
            productName: this.props.location.state.dataToEdit.titluProdus,
            productImage: this.props.location.state.dataToEdit.imagine,
            volum: this.props.location.state.dataToEdit.volum,
            suprafataZidarie: this.props.location.state.dataToEdit.suprafata,
            numarBucati: this.props.location.state.dataToEdit.bucati_final,
            pret: this.props.location.state.dataToEdit.pret_achizitie_referinta,
            discount: this.props.location.state.dataToEdit.discount_referinta,
            defaultCurrency:
              this.props.location.state.dataToEdit.moneda != ""
                ? this.props.location.state.dataToEdit.moneda
                : "lei",
            cantitateM2: this.props.location.state.dataToEdit.consum_m2,
            lungime: this.props.location.state.dataToEdit.lungimeDim,
            latime: this.props.location.state.dataToEdit.latimeDim,
            inaltime: this.props.location.state.dataToEdit.inaltimeDim,
            idLinieComanda: this.props.location.state.dataToEdit.idLinieComanda,
            lungimePereti: this.props.location.state.dataToEdit.lungime,
            inaltimePerete: this.props.location.state.dataToEdit.inaltime
          });
        });
    }

    // Preiau moneda si cursul din localStorage daca exista
    if (
      localStorage.getItem("defaultCurrency") != null &&
      localStorage.getItem("defaultCurrency") != "" &&
      localStorage.getItem("defaultCurrency") != "null"
    ) {
      this.setState({
        defaultCurrency: localStorage.getItem("defaultCurrency").toLowerCase(),
        cursIntrodus: localStorage.getItem("cursIntrodus")
      });
    }
  }

  handleFocus = e => {
    if (e.target.value == 0) e.target.select();
  };

  // Schimb moneda
  changeMoneda = e => {
    if (e.target.value != "lei") {
      this.setState({
        cursIntrodusAfisat: 1,
        defaultCurrency: e.target.value,
        cursIntrodus: ""
      });
    } else {
      this.setState({
        cursIntrodusAfisat: 0,
        defaultCurrency: e.target.value,
        cursIntrodus: ""
      });
    }
  };

  // Introducere curs
  introducereCurs = e => {
    this.setState({
      cursIntrodus: e.target.value
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    $("select").on("change", function() {
      if (this.value != "" || this.value != 0) {
        $(this).css("cssText", "color:#000;");

        if ($(this).hasClass("selectprodus")) {
          $(".selectmodel").css("cssText", "color:#000;");
        }
        if ($(this).hasClass("selectchit")) {
          $(".selectculoarechit").css("cssText", "color:#000;");
        }
      } else {
        $(this).css("cssText", "color:#666;");
      }
    });
  }

  render() {
    return (
      <div className="h100">
        <div
          className="background h100 calculator_caramida"
          style={{ backgroundImage: `url(${background})` }}
        >
          <Headerq />
          <div className="subheader2">
            <Subheader />
          </div>
          <div className="copy_subheader">
            Acest calculator pentru cărămidă și blocuri ceramice te ajută să
            estimezi de câte cărămizi vei avea nevoie pentru a acoperi o anumită
            suprafață. Pe lângă necesarul de materiale vei afla și costurile.
          </div>
          <div
            className="caramida-blocuri-ceramice select_produse_general"
            id="caramida-blocuri-ceramice"
          >
            <div className="select_produse_general">
              <div className="select_inner">
                <div className="sl_general_placaj sl1">
                  <div className="tl_general">
                    Selectează
                    <br /> modalitatea de calcul pe care o preferi
                  </div>
                  <select
                    ref="modalitate_calcul_select"
                    className="calculator-field-select select_general select_black"
                    onChange={this.changeSelectOption}
                    value={this.state.optionModalitate}
                  >
                    <option value="lungime">Lungime, înălțime, grosime</option>
                    <option value="volum">Volum, grosime</option>
                    <option value="suprafata">Suprafață, grosime</option>
                    <option value="bucati">Număr bucăți</option>
                  </select>
                </div>
                <div className="sl_general_placaj sl1">
                  <div className="tl_general">
                    Selectează
                    <br /> grosimea peretelui
                  </div>
                  <select
                    ref="selectBCGrosime"
                    value={this.state.optionGrosime}
                    onChange={this.grosimeSelectata}
                    className={
                      " calculator-field-select select_general " +
                      (this.state.active_inputs ? "select_black" : "")
                    }
                  >
                    <option value="0" disabled>
                      Selectează Grosime
                    </option>
                    <option value="g_38">38</option>
                    <option value="g_37_5">37.5</option>
                    <option value="g_30">30</option>
                    <option value="g_25">25</option>
                    <option value="g_20">20</option>
                    <option value="g_19">19</option>
                    <option value="g_15">15</option>
                    <option value="g_12_5">12.5</option>
                  </select>
                </div>
                <div className="sl_general_placaj sl1">
                  <div className="tl_general">
                    Selectează
                    <br /> modelul de cărămidă dorit
                  </div>
                  <select
                    ref="selectBCProduse"
                    value={this.state.optionProduse}
                    onChange={this.produsSelectat}
                    className={
                      "calculator-field-select select_general sl_j1 " +
                      (this.state.active_inputs ? "select_black" : "")
                    }
                  >
                    <option value="0" disabled>
                      Selectează Produsul
                    </option>
                    {this.state.produse.map(produse => (
                     
                      <option key={produse.idProdus} value={produse.idProdus}>
                        {produse.name}
                      </option>
                    
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div
              className={`inputs_hidden ${
                this.state.active_inputs ? "show_inputs" : ""
              }`}
            >
              <div className="sectiune_inputuri">
                <div className="poza_stanga">
                  {this.state.productImage ? (
                    <img src={this.state.productImage} alt="" />
                  ) : (
                    ""
                  )}
                </div>

                {/* Informatii constructie */}
                <div className="calculator-field informatii-constructie info_right">
                 

                  <div className="calculator-field-title medium tl_mare">
                    Cărămidă
                  </div>

                  <div className="info-constructie-items">
                    {this.state.inputLungimePereti ? (
                      <div className="info-constructie-item  flx sb w100">
                        <div className="item-title">
                          Lungime totală pereți (m):
                        </div>
                        <input
                          id="inputLungimePereti"
                          type="number"
                          value={
                            isNaN(this.state.lungimePereti)
                              ? 0
                              : this.state.lungimePereti
                          }
                          className={
                            "inputLungimePereti input_dreapta c1 fz18 bd " +
                            this.state.lungimePeretiED
                          }
                          onChange={this.changeDetaliiConstructie}
                          onFocus={this.handleFocus}
                          min="0"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.inputInaltimePerete ? (
                      <div className="info-constructie-item  flx sb w100">
                        <div className="item-title">Înălțime perete:</div>
                        <input
                          id="inputInaltimePerete"
                          type="number"
                          value={
                            isNaN(this.state.inaltimePerete)
                              ? 0
                              : this.state.inaltimePerete
                          }
                          className={
                            "inputInaltimePerete input_dreapta c1 fz18 bd " +
                            this.state.inaltimePereteED
                          }
                          onChange={this.changeDetaliiConstructie}
                          onFocus={this.handleFocus}
                          min="0"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.inputVolum ? (
                      <div className="info-constructie-item  flx sb w100">
                        <span className="item-title">
                          Volum zidărie (m
                          <sup>3</sup>
                          ):
                        </span>
                        <input
                          id="inputVolum"
                          type="number"
                          value={isNaN(this.state.volum) ? 0 : this.state.volum}
                          className={
                            "inputVolum input_dreapta c1 fz18 bd " +
                            this.state.volumED
                          }
                          onChange={this.changeDetaliiConstructie}
                          onFocus={this.handleFocus}
                          min="0"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.inputSuprafataZidarie ? (
                      <div className="info-constructie-item flx sb w100">
                        <span className="item-title">
                          Suprafață zidărie (m
                          <sup>2</sup>
                          ):
                        </span>
                        <input
                          id="inputSuprafataZidarie"
                          type="number"
                          value={
                            isNaN(this.state.suprafataZidarie)
                              ? 0
                              : this.state.suprafataZidarie
                          }
                          className={
                            "inputSuprafataZidarie input_dreapta c1 fz18 bd " +
                            this.state.suprafataZidarieED
                          }
                          onChange={this.changeDetaliiConstructie}
                          onFocus={this.handleFocus}
                          min="0"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.inputNumarBucati ? (
                      <div className="info-constructie-item  flx sb w100">
                        <span className="item-title">Număr bucăți:</span>
                        <input
                          id="inputNumarBucati"
                          type="number"
                          value={
                            isNaN(this.state.numarBucati)
                              ? 0
                              : this.state.numarBucati
                          }
                          className={
                            "inputNumarBucati input_dreapta c1 fz18 bd " +
                            this.state.numarBucatiED
                          }
                          onChange={this.changeDetaliiConstructie}
                          onFocus={this.handleFocus}
                          min="0"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                   
                  </div>

                  
                  <Link
                    to={{
                      pathname: "/sumar-comanda",
                      data: { date_calcul: this.state }
                    }}
                    onClick={this.calculeazaBtn}
                  >
                    <div className="calculeaza-button" id="calculeaza-button">
                      <div className="calculeaza-label">
                        Calculează
                        <span>Necesar materiale și costuri</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CaramidaBlocuriCeramice;
