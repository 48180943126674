import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import product from "../assets/img/product.png";
import PlacajKlinker from "./PlacajKlinker";
import Subheader from "./Subheader";
import Menu from "./Menu";
import Headerq from "./Headerq";

import cart_btn from "../assets/img/cart_btn_white.png";
import background from "./../assets/img/background.jpg";

class NecesarPlacajKlinker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suprafata_zidarie: 0,
      defaultSelectGrosime: 0,
      lungime: "",
      ultima_comanda: "",
      latime: "",
      inaltime: "",
      defaultCurrency: "lei",
      product_img: "dummy-img.png",
      nameProdusKlinker: "",
      bucati_palet: "",
      nume_mortar: "",
      discount: "",
      discount_mortar: "",
      pret_bucata_mortar: "",
      nume_coltar_prelucrat: "",
      redirect: false

      // defaultSelectModelKlinker: 0,
      // defaultSelectMortar: 0,
      // defaultSelectProdusKlinker: 0,
      // produse: [],
      // modele: (
      //   <option value="0" disabled="">
      //     Selectează Modelul
      //   </option>
      // ),
      // pret_bucata: "",

      // discount: "",

      // pret_bucata_mortar: "",

      // discount_mortar: ""
    };
  }

  componentWillMount() {
    //if (this.props.location.state_curent) {
    var state_curent = this.props.location.state_curent;

    console.log(sessionStorage);

    var nume_coltar = sessionStorage.getItem("nameProdusPlacajKlinker");
    var nume_coltar_prelucrat = nume_coltar.replace("Placaj", "");

    this.setState({
      paritate: 1, // nu modifica pentru ca paritatea reala este stocata in variabila 'cursIntrodus'
      defaultSelectProdusPlacajKlinker: sessionStorage.getItem(
        "defaultSelectProdusPlacajKlinker"
      ),
      nume_coltar_prelucrat: nume_coltar_prelucrat,
      nameProdusPlacajKlinker: sessionStorage.getItem(
        "nameProdusPlacajKlinker"
      ),
      product_img_placaj: sessionStorage.getItem("product_img_placaj"),
      defaultSelectModelPlacajKlinker: sessionStorage.getItem(
        "defaultSelectModelPlacajKlinker"
      ),
      denumirePlacajModel: sessionStorage.getItem("denumirePlacajModel"),
      discount_adeziv: sessionStorage.getItem("discount_adeziv"),
      discount_chit: sessionStorage.getItem("discount_chit"),
      discount_placaj: sessionStorage.getItem("discount_placaj"),
      ultima_comanda: sessionStorage.getItem("ultima_comanda"),
      bucati_palet: sessionStorage.getItem("bucati_palet"),
      pret_placaj: sessionStorage.getItem("pret_placaj"),
      suprafata_placare: sessionStorage.getItem("suprafata_placare"),
      lungime_colturi: sessionStorage.getItem("lungime_colturi"),
      pret_adeziv: sessionStorage.getItem("pret_adeziv"),
      modelAdeziv: sessionStorage.getItem("modelAdeziv"),
      modelChit: sessionStorage.getItem("modelChit"),
      nameModelAdeziv: sessionStorage.getItem("nameModelAdeziv"),
      nameModelChit: sessionStorage.getItem("nameModelChit"),
      imagine_placaj: sessionStorage.getItem("imagine_placaj"),
      latime_placaj: sessionStorage.getItem("latime_placaj"),
      lungime_placaj: sessionStorage.getItem("lungime_placaj"),
      inaltime_placaj: sessionStorage.getItem("inaltime_placaj"),
      grosime_placaj: sessionStorage.getItem("grosime_placaj"),
      denumireModel: sessionStorage.getItem("denumireModel"),
      nameChitRosturi: sessionStorage.getItem("nameChitRosturi"),
      idChitRosturi: sessionStorage.getItem("idChitRosturi"),
      nameCuloareChit: sessionStorage.getItem("nameCuloareChit"),
      idCuloareChit: sessionStorage.getItem("idCuloareChit"),
      pret_chit: sessionStorage.getItem("pret_chit"),
      general_inaltime_coltar_placaj: sessionStorage.getItem(
        "general_inaltime_coltar_placaj"
      ),
      general_buc_cutie_placaj: sessionStorage.getItem(
        "general_buc_cutie_placaj"
      ),
      general_buc_cutie_coltar: sessionStorage.getItem(
        "general_buc_cutie_coltar"
      ),

      general_latime_coltar_placaj: sessionStorage.getItem(
        "general_latime_coltar_placaj"
      ),
      general_lungime_coltar: sessionStorage.getItem("general_lungime_coltar"),

      general_lungime_coltar_placaj: sessionStorage.getItem(
        "general_lungime_coltar_placaj"
      ),
      general_pret_coltar_placaj: sessionStorage.getItem(
        "general_pret_coltar_placaj"
      ),
      general_latura_scurta_placaj: sessionStorage.getItem(
        "general_latura_scurta_placaj"
      ),
      pret_chit_culoare: sessionStorage.getItem("pret_chit_culoare"),
      kgSac: sessionStorage.getItem("kgSac"),
      livrabile_placaj_cutii: "",
      livrabile_placaj_fara_tva: "",
      livrabile_coltar_buc: "",
      livrabile_coltar_cutii: "",
      livrabile_coltar_fara_tva: "",
      livrabile_adeziv_buc: "",
      livrabile_adeziv_fara_tva: "",
      livrabile_chit_buc: "",
      livrabile_chit_fara_tva: "",
      specific_placaj_buc: "",
      specific_placaj_fara_tva: "",
      specific_coltar_buc: "",
      specific_coltar_fara_tva: "",
      specific_adeziv_kg: "",
      specific_adeziv_fara_tva: "",
      specific_chit_kg: "",
      specific_chit_fara_tva: "",
      defaultCurrency: sessionStorage.getItem("defaultCurrency"),
      cursIntrodus: sessionStorage.getItem("cursIntrodus"),
      editareProdusPlacaj: sessionStorage.getItem("editareProdusPlacaj"),
      id_linie_comanda: sessionStorage.getItem("id_linie_comanda")
    });
    //}
  }

  componentWillUnmount() {}

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  adaugaInCos = event => {
    if (sessionStorage.getItem("editareProdusPlacaj") == 1) {
      var tip_actiune = "editare_produs_placaj";
    } else {
      var tip_actiune = "adauga_in_cos_placaj";
    }

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        get_data: tip_actiune,
        ultima_comanda: sessionStorage.getItem("ultima_comanda"),
        id_linie_comanda: sessionStorage.getItem("id_linie_comanda"),
        idPlacaj: sessionStorage.getItem("defaultSelectProdusPlacajKlinker"),
        idModel: sessionStorage.getItem("defaultSelectModelPlacajKlinker"),
        idAdeziv: sessionStorage.getItem("modelAdeziv"),
        denumireAdeziv: sessionStorage.getItem("nameModelAdeziv"),
        pretAdeziv: sessionStorage.getItem("pret_adeziv"),
        discount_adeziv: sessionStorage.getItem("discount_adeziv"),
        idChit: sessionStorage.getItem("idChitRosturi"),
        denumireChit: sessionStorage.getItem("nameChitRosturi"),
        culoareChit: sessionStorage.getItem("nameCuloareChit"),
        pretChit: sessionStorage.getItem("pret_chit"),
        kgSac: sessionStorage.getItem("kgSac"),
        discount_chit: sessionStorage.getItem("discount_chit"),
        denumire_placaj: sessionStorage.getItem("nameProdusPlacajKlinker"),
        prescurtare: sessionStorage.getItem("nameProdusPlacajKlinker"),
        denumireModel: sessionStorage.getItem("denumireModel"),
        suprafata: sessionStorage.getItem("suprafata_placare"),
        lungime: sessionStorage.getItem("general_lungime_coltar_placaj"),
        latime: sessionStorage.getItem("general_latime_coltar_placaj"),
        inaltime: sessionStorage.getItem("general_inaltime_coltar_placaj"),
        grosime_rost: sessionStorage.getItem("grosime_placaj"),
        lungime_colturi: sessionStorage.getItem("lungime_colturi"),
        suprafata_placaj_rost: sessionStorage.getItem("suprafata_placaj_rost"),
        suprafata_coltar_rost: sessionStorage.getItem("suprafata_coltar_rost"),
        cantitate_coltare: sessionStorage.getItem("cantitate_coltare"),
        suprafata_totala_coltare: sessionStorage.getItem(
          "suprafata_totala_coltare"
        ),
        cantitate_placaj: sessionStorage.getItem("cantitate_placaj"),
        cantitate_placaj_cutii: sessionStorage.getItem(
          "cantitate_placaj_cutii"
        ),
        cantitate_placaj_bucati: sessionStorage.getItem(
          "cantitate_placaj_bucati"
        ),
        cantitate_coltare_cutii: sessionStorage.getItem(
          "cantitate_coltare_cutii"
        ),
        cantitate_coltare_bucati: sessionStorage.getItem(
          "cantitate_coltare_bucati"
        ),
        consum_specific_chit: sessionStorage.getItem("consum_specific_chit"),
        buc_cutie: sessionStorage.getItem("general_buc_cutie_placaj"),
        buc_cutie_coltar: sessionStorage.getItem("general_buc_cutie_coltar"),
        pret_buc: sessionStorage.getItem("pret_placaj"),
        pret_buc_coltar: sessionStorage.getItem("general_pret_coltar_placaj"),
        discount: sessionStorage.getItem("discount_placaj"),
        livrabil_placaj: sessionStorage.getItem("livrabil_placaj"),
        livrabil_coltar: sessionStorage.getItem("livrabil_coltar"),
        livrabil_adeziv: sessionStorage.getItem("livrabil_adeziv"),
        livrabil_chit: sessionStorage.getItem("livrabil_chit"),
        valoare_totala_calculata_placaj: sessionStorage.getItem(
          "valoare_totala_calculata_placaj"
        ),
        imagine_placaj: sessionStorage.getItem("imagine_placaj"),
        defaultCurrency: sessionStorage.getItem("defaultCurrency"),
        cursIntrodus: sessionStorage.getItem("cursIntrodus")
      })
      .then(res => {
        // Fac clear la sessionStorage, insa fara moneda selectat si cursul introdus
        var monedaSelect = sessionStorage.getItem("defaultCurrency");
        var cursSelect = sessionStorage.getItem("cursIntrodus");
        var ultimaComanda = sessionStorage.getItem("ultima_comanda");
        sessionStorage.clear();
        sessionStorage.setItem("defaultCurrency", monedaSelect);
        sessionStorage.setItem("cursIntrodus", cursSelect);
        sessionStorage.setItem("ultima_comanda", ultimaComanda);

        // Redirect
        this.setState({
          redirect: true
        });
      });
  };

  render() {
    // Redirect pe pagina cos
    if (this.state.redirect) {
      //return <Redirect push to="/cart" />;
      window.location.href = 'https://leier.viscero.ro/reprezentanti-zonali/'; 
      return null;
    }

    var suprafata_placaj_rost =
      ((parseFloat(this.state.general_lungime_coltar_placaj) +
        parseFloat(this.state.grosime_placaj)) *
        (parseFloat(this.state.general_latime_coltar_placaj) +
          parseFloat(this.state.grosime_placaj))) /
      1000000;
    //suprafata placaj rost OK

    var pret_buc =
      (parseFloat(this.state.pret_placaj) *
        (100 - parseFloat(this.state.discount_placaj))) /
      100;

    var livrabil_valoare_placaj_fara_tva =
      ((1 / suprafata_placaj_rost) * pret_buc) / this.state.paritate;

    var livrabil_valoare_placaj_cu_tva =
      livrabil_valoare_placaj_fara_tva * 1.19;

    //valori placaj cu si fara tva

    var valoare_adeziv_fara_tva =
      ((2.8 / 25) *
        (parseFloat(this.state.pret_adeziv) *
          ((100 - parseFloat(this.state.discount_adeziv)) / 100))) /
      this.state.paritate;

    var valoare_adeziv_cu_tva = valoare_adeziv_fara_tva * 1.19;

    //valori adeziv cu si fara tva

    var consum_specific_chit =
      ((parseFloat(this.state.general_lungime_coltar_placaj) +
        parseFloat(this.state.general_latime_coltar_placaj)) *
        parseFloat(this.state.general_inaltime_coltar_placaj) *
        parseFloat(this.state.grosime_placaj) *
        1.5) /
      parseFloat(this.state.general_lungime_coltar_placaj) /
      parseFloat(this.state.general_latime_coltar_placaj);

    //consum specific chit
    var valoare_chit_fara_tva =
      (consum_specific_chit / parseFloat(this.state.kgSac)) *
      ((parseFloat(this.state.pret_chit) *
        ((100 - parseFloat(this.state.discount_chit)) / 100)) /
        this.state.paritate);

    var valoare_chit_cu_tva = valoare_chit_fara_tva * 1.19;

    //valoare chit fara tva si cu tva

    var cantitate_coltar_klinker =
      (1 /
        (parseFloat(this.state.general_latime_coltar_placaj) +
          parseFloat(this.state.grosime_placaj))) *
      1000;

    //cantitate coltar klinker
    var valoare_coltar_fara_tva =
      ((1 /
        (parseFloat(this.state.general_latime_coltar_placaj) +
          parseFloat(this.state.grosime_placaj))) *
        1000 *
        parseFloat(this.state.general_pret_coltar_placaj) *
        (100 - parseFloat(this.state.discount_placaj))) /
      100 /
      this.state.paritate;

    var valoare_coltar_cu_tva = valoare_coltar_fara_tva * 1.19;

    //valoare coltar fara tva si cu tva

    var suprafata_coltar_rost =
      ((parseFloat(this.state.general_lungime_coltar) +
        parseFloat(this.state.grosime_placaj) -
        20) *
        (parseFloat(this.state.general_latime_coltar_placaj) +
          parseFloat(this.state.grosime_placaj)) +
        (parseFloat(this.state.general_latura_scurta_placaj) +
          parseFloat(this.state.grosime_placaj) -
          20) *
          (parseFloat(this.state.general_latime_coltar_placaj) +
            parseFloat(this.state.grosime_placaj))) /
      1000000;

    //suprafata coltar rost

    var cantitate_coltare =
      parseFloat(this.state.lungime_colturi) *
      ((1 /
        (parseFloat(this.state.general_latime_coltar_placaj) +
          parseFloat(this.state.grosime_placaj))) *
        1000);

    //cantitate_coltare

    var suprafata_totala_coltare = cantitate_coltare * suprafata_coltar_rost;

    //suprafata totala coltare

    var cantitate_placaj =
      (parseFloat(this.state.suprafata_placare) -
        suprafata_totala_coltare / 2) /
      suprafata_placaj_rost;

    // cantitate placaj

    var cantitate_placaj_cutii = Math.ceil(
      cantitate_placaj / parseFloat(this.state.general_buc_cutie_placaj)
    );

    //cantitate placaj cutii

    var cantitate_placaj_bucati =
      cantitate_placaj_cutii * parseFloat(this.state.general_buc_cutie_placaj);

    //cantitate placaj bucati
    var cantitate_coltare_cutii = Math.ceil(
      cantitate_coltare / parseFloat(this.state.general_buc_cutie_coltar)
    );
    // cantitate_coltare_cutii

    var cantitate_coltare_bucati =
      cantitate_coltare_cutii * parseFloat(this.state.general_buc_cutie_coltar);

    //cantitate_coltare_bucati

    var valoare_placaj_fara_tva =
      (cantitate_placaj_bucati *
        ((parseFloat(this.state.pret_placaj) *
          (100 - this.state.discount_placaj)) /
          100)) /
      parseFloat(this.state.paritate);

    var valoare_placaj_cu_tva = valoare_placaj_fara_tva * 1.19;

    // cantitate placaj total fara tva

    var livrabil_valoare_coltar_fara_tva =
      (cantitate_coltare_bucati *
        ((parseFloat(this.state.general_pret_coltar_placaj) *
          (100 - this.state.discount_placaj)) /
          100)) /
      parseFloat(this.state.paritate);

    var livrabil_valoare_coltar_cu_tva =
      livrabil_valoare_coltar_fara_tva * 1.19;

    //cantitate coltar total fara tva
    var livrabil_cantitate_adeziv = Math.ceil(
      (2.8 * parseFloat(this.state.suprafata_placare)) / 25
    );

    //cantitate adeziv

    var livrabil_cantitate_adeziv_fara_tva =
      (Math.ceil((2.8 * parseFloat(this.state.suprafata_placare)) / 25) *
        (parseFloat(this.state.pret_adeziv) *
          ((100 - parseFloat(this.state.discount_adeziv)) / 100))) /
      parseFloat(this.state.paritate);

    var livrabil_cantitate_adeziv_cu_tva =
      livrabil_cantitate_adeziv_fara_tva * 1.19;

    //cantitate adeziv fara tva
    var livrabil_cantitate_chit_rosturi = Math.ceil(
      (consum_specific_chit * parseFloat(this.state.suprafata_placare)) /
        parseFloat(this.state.kgSac)
    );
    //cantitate chit de rosturi
    var livrabil_valoare_chit_de_rosturi_fara_tva =
      (Math.ceil(
        (consum_specific_chit * parseFloat(this.state.suprafata_placare)) /
          parseFloat(this.state.kgSac)
      ) *
        ((parseFloat(this.state.pret_chit) *
          (100 - parseFloat(this.state.discount_chit))) /
          100)) /
      parseFloat(this.state.paritate);

    var livrabil_valoare_chit_de_rosturi_cu_tva =
      livrabil_valoare_chit_de_rosturi_fara_tva * 1.19;

    //cantitate chit de rosturi fara tva

    sessionStorage.setItem(
      "livrabil_cantitate_chit_rosturi",
      livrabil_cantitate_chit_rosturi
    );
    sessionStorage.setItem(
      "livrabil_cantitate_adeziv",
      livrabil_cantitate_adeziv
    );
    sessionStorage.setItem(
      "cantitate_coltare_bucati",
      cantitate_coltare_bucati
    );
    sessionStorage.setItem("cantitate_coltare_cutii", cantitate_coltare_cutii);
    sessionStorage.setItem("cantitate_placaj_bucati", cantitate_placaj_bucati);
    sessionStorage.setItem("cantitate_placaj_cutii", cantitate_placaj_cutii);
    sessionStorage.setItem("cantitate_placaj", cantitate_placaj);
    sessionStorage.setItem(
      "suprafata_totala_coltare",
      suprafata_totala_coltare
    );
    sessionStorage.setItem("cantitate_coltare", cantitate_coltare);
    sessionStorage.setItem("suprafata_coltar_rost", suprafata_coltar_rost);
    sessionStorage.setItem("consum_specific_chit", consum_specific_chit);
    sessionStorage.setItem("pret_buc", pret_buc);
    sessionStorage.setItem("suprafata_placaj_rost", suprafata_placaj_rost);

    sessionStorage.setItem(
      "livrabil_placaj",
      valoare_placaj_fara_tva.toFixed(2)
    );

    sessionStorage.setItem(
      "livrabil_coltar",
      livrabil_valoare_coltar_fara_tva.toFixed(2)
    );

    sessionStorage.setItem(
      "livrabil_adeziv",
      livrabil_cantitate_adeziv_fara_tva.toFixed(2)
    );

    sessionStorage.setItem(
      "livrabil_chit",
      livrabil_valoare_chit_de_rosturi_fara_tva.toFixed(2)
    );

    sessionStorage.setItem(
      "specific_placaj",
      livrabil_valoare_placaj_fara_tva.toFixed(2)
    );

    sessionStorage.setItem(
      "specific_coltar",
      valoare_coltar_fara_tva.toFixed(2)
    );

    sessionStorage.setItem(
      "specific_adeziv",
      valoare_adeziv_fara_tva.toFixed(2)
    );

    sessionStorage.setItem("specific_chit", valoare_chit_fara_tva.toFixed(2));

    sessionStorage.setItem(
      "valoare_totala_calculata_placaj",
      (
        parseFloat(sessionStorage.getItem("livrabil_placaj")) +
        parseFloat(sessionStorage.getItem("livrabil_coltar")) +
        parseFloat(sessionStorage.getItem("livrabil_adeziv")) +
        parseFloat(sessionStorage.getItem("livrabil_chit"))
      ).toFixed(2)
    );

    var original = this.state.nameProdusPlacajKlinker;
    var result = original.substr(original.indexOf(" ") + 1);

    return (
      <div
        className="background calculator_caramida"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Headerq />
        <div className="subheader2">
          <div className="subheader" id="subheader">
            Necesar de placaj {this.state.nameProdusPlacajKlinker}{" "}
            {sessionStorage.getItem("nameSelectModelPlacajKlinker")}
          </div>
        </div>
        <div className="container_caramida">
          <div className="componenta-necesar-caramida flxx necesar_flex">
            <div className="imagine_necesar">
              <img
                className="img_produs1"
                src={require("../assets/img/klinker/" +
                  this.state.imagine_placaj)}
                alt=""
              />
            </div>
            <div className="necesar-caramida-inner necesar_right ">
              <div className="mt">
                <div className="title_medium fz33 medium tl_mare">
                  Date intrare
                </div>
                <div className="box_cantitate">
                  <div className="info-constructie-items flxx nowrap">
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">
                        Suprafață placaj (m<sup>2</sup>)
                      </span>
                      <input
                        disabled
                        value={this.state.suprafata_placare}
                        type="text"
                        className="input_red  pr0 c1 fz18 bd"
                        name="suprafata_placare"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Grosime rost (mm)</span>
                      <input
                        disabled
                        value={this.state.grosime_placaj}
                        type="text"
                        className="input_red  pr0  c1 fz18 bd"
                        name="grosime_placaj"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Lungime colțuri (mm)</span>
                      <input
                        disabled
                        value={this.state.lungime_colturi}
                        type="text"
                        className="input_red  pr0  c1 fz18 bd"
                        name="lungime"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Lungime (mm)</span>
                      <input
                        disabled
                        value={this.state.general_lungime_coltar_placaj}
                        type="text"
                        className="input_red  pr0  c1 fz18 bd"
                        name="lungime"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Inălțime (mm)</span>
                      <input
                        disabled
                        value={this.state.general_latime_coltar_placaj}
                        type="text"
                        className="input_red  pr0  c1 fz18 bd"
                        name="inaltime"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Grosime (mm)</span>
                      <input
                        disabled
                        value={this.state.general_inaltime_coltar_placaj}
                        type="text"
                        className="input_red  pr0  c1 fz18 bd"
                        name="grosime_rost"
                      />
                    </div>
                    asdas
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Monedă</span>
                      <input
                        disabled
                        value={this.state.defaultCurrency.toUpperCase()}
                        type="text"
                        className="input_red  pr0  c1 fz18 bd"
                        name="moneda"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">
                        Discount Placaj Klinker (%)
                      </span>
                      <input
                        disabled
                        value={this.state.discount_placaj}
                        type="text"
                        className="input_red  pr0  c1 fz18 bd"
                        name="moneda"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Discount Adeziv (%)</span>
                      <input
                        disabled
                        value={this.state.discount_adeziv}
                        type="text"
                        className="input_red  pr0  c1 fz18 bd"
                        name="moneda"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">
                        Discount Chit de rosturi (%)
                      </span>
                      <input
                        disabled
                        value={this.state.discount_chit}
                        type="text"
                        className="input_red  pr0  c1 fz18 bd"
                        name="moneda"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt">
                <div className="calculator-field-title medium tl_mare mtt">
                  Consum specific de materiale
                </div>
                <div className="tabel_consum">
                  <div className="cap_tabel">
                    <div className="item-title">Tip material</div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (buc/m<sup>2</sup>)
                    </div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (buc/ml)
                    </div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (kg/m<sup>2</sup>)
                    </div>
                    
                    <div className="item-title">
                      Valoare <br />
                      (fără TVA)
                    </div>
                    <div className="item-title">
                      Valoare
                      <br /> (cu TVA)
                    </div>
                  </div>
                  <div className="cap_tabel">
                    <div className="black_item">
                      {this.state.nameProdusPlacajKlinker}{" "}
                      {sessionStorage.getItem("nameSelectModelPlacajKlinker")}
                    </div>
                    <div className="red_item">
                      {" "}
                      {(1 / suprafata_placaj_rost)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {parseFloat(
                        livrabil_valoare_placaj_fara_tva /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        livrabil_valoare_placaj_cu_tva / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel nob">
                    <div className="black_item">
                      Coltar {result} (
                      {sessionStorage.getItem("general_lungime_coltar")}x
                      {sessionStorage.getItem("general_latime_coltar_placaj")}x
                      {sessionStorage.getItem("general_inaltime_coltar_placaj")}
                      ) {sessionStorage.getItem("nameSelectModelPlacajKlinker")}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {cantitate_coltar_klinker
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">--</div>

                    <div className="red_item">
                      {parseFloat(
                        valoare_coltar_fara_tva / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        valoare_coltar_cu_tva / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel nob">
                    <div className="black_item">
                      Adeziv {this.state.nameModelAdeziv}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">--</div>
                    <div className="red_item">2.80</div>

                    <div className="red_item">
                      {parseFloat(
                        valoare_adeziv_fara_tva / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        valoare_adeziv_cu_tva / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel nob">
                    <div className="black_item">
                      {this.state.nameChitRosturi} {this.state.nameCuloareChit}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {consum_specific_chit
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>

                    <div className="red_item">
                      {parseFloat(
                        valoare_chit_fara_tva / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(valoare_chit_cu_tva / this.state.cursIntrodus)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel cap_tabel_total">
                    <div className="black_item">TOTAL</div>
                    <div className="red_item">--</div>
                    <div className="red_item">--</div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {parseFloat(
                        (parseFloat(livrabil_valoare_placaj_fara_tva) +
                          parseFloat(valoare_coltar_fara_tva) +
                          parseFloat(valoare_adeziv_fara_tva) +
                          parseFloat(valoare_chit_fara_tva)) /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {this.state.defaultCurrency.toUpperCase()}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        (parseFloat(livrabil_valoare_placaj_cu_tva) +
                          parseFloat(valoare_coltar_cu_tva) +
                          parseFloat(valoare_adeziv_cu_tva) +
                          parseFloat(valoare_chit_cu_tva)) /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {this.state.defaultCurrency.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt">
                <div className="calculator-field-title medium tl_mare mtt">
                  Cantități livrabile (în funcție de unități de împachetare)
                </div>

                <div className="tabel_consum">
                  <div className="cap_tabel">
                    <div className="item-title">Tip material</div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (buc)
                    </div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (cutii)
                    </div>
                    <div className="item-title">
                      Valoare
                      <br /> (fără TVA)
                    </div>
                    <div className="item-title">
                      Valoare
                      <br /> (cu TVA)
                    </div>
                  </div>
                  <div className="cap_tabel">
                    <div className="black_item">
                      {this.state.nameProdusPlacajKlinker}{" "}
                      {sessionStorage.getItem("nameSelectModelPlacajKlinker")}
                    </div>
                    <div className="red_item">
                      {cantitate_placaj_bucati

                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {cantitate_placaj_cutii

                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        valoare_placaj_fara_tva / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        valoare_placaj_cu_tva / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel">
                    <div className="black_item">
                      Coltar {result} (
                      {sessionStorage.getItem("general_lungime_coltar")}x
                      {sessionStorage.getItem("general_latime_coltar_placaj")}x
                      {sessionStorage.getItem("general_inaltime_coltar_placaj")}
                      ) {sessionStorage.getItem("nameSelectModelPlacajKlinker")}
                    </div>
                    <div className="red_item">
                      {cantitate_coltare_bucati

                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {cantitate_coltare_cutii

                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        livrabil_valoare_coltar_fara_tva /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        livrabil_valoare_coltar_cu_tva / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel nob">
                    <div className="black_item">
                      Adeziv {this.state.nameModelAdeziv}
                    </div>
                    <div className="red_item">
                      {" "}
                      {livrabil_cantitate_adeziv

                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {parseFloat(
                        livrabil_cantitate_adeziv_fara_tva /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        livrabil_cantitate_adeziv_cu_tva /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel nob">
                    <div className="black_item">
                      {this.state.nameChitRosturi}
                    </div>
                    <div className="red_item">
                      {" "}
                      {livrabil_cantitate_chit_rosturi

                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item AICI">
                      {parseFloat(
                        livrabil_valoare_chit_de_rosturi_fara_tva /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        livrabil_valoare_chit_de_rosturi_cu_tva /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel cap_tabel_total">
                    <div className="black_item">TOTAL</div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {" "}
                      {(
                        parseFloat(cantitate_placaj_cutii) +
                        parseFloat(cantitate_coltare_cutii)
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        (parseFloat(valoare_placaj_fara_tva) +
                          parseFloat(livrabil_valoare_coltar_fara_tva) +
                          parseFloat(livrabil_cantitate_adeziv_fara_tva) +
                          parseFloat(
                            livrabil_valoare_chit_de_rosturi_fara_tva
                          )) /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {this.state.defaultCurrency.toUpperCase()}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        (parseFloat(valoare_placaj_cu_tva) +
                          parseFloat(livrabil_valoare_coltar_cu_tva) +
                          parseFloat(livrabil_cantitate_adeziv_cu_tva) +
                          parseFloat(livrabil_valoare_chit_de_rosturi_cu_tva)) /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {this.state.defaultCurrency.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="butoane_cart">
                <Link
                  to={{
                    pathname: "/placaj-klinker",
                    data: { refaCalculData: this.state, sourceRefaCalcul: 1 }
                  }}
                >
                  <div className="btn_general refa_calculul bd">
                    Refă calculul
                  </div>
                </Link>
                <div
                  onClick={this.adaugaInCos}
                  className="btn_general  adauga_oferta_in_cos bd"
                >
                  Contactează un agent leier pentru ofertă
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NecesarPlacajKlinker;
