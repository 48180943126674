import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import logo from "./../assets/img/leier_logo_oficial.jpg";
import x from "./../assets/img/x.png";
import shape from "./../assets/img/shape.png";
import bkf from "./../assets/img/bkf.png";
import sao from "./../assets/img/sao.png";
import oude from "./../assets/img/oude.png";

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ visible: newProps.visible });
  }

  handleChangeMenu = event => {
    this.setState({ visible: false });
  };

  render() {
    return (
      <div
        className={
          this.state.visible ? "meniu_site fadeIn" : "meniu_site fadeOut"
        }
      >
        <div className="header2">
          <div className="container_header2">
            <div className="logo_header2">
              {" "}
              <img src={logo} alt="Logo Brikston" />
            </div>
            <div className="meniu_header2">
              <div className="container_right">
                <img src={x} alt="Exit" onClick={this.props.onClick} />
              </div>
            </div>
          </div>
        </div>
        <div className="meniu_container">
          <Link to="/caramida-blocuri-ceramice">
            <div className="item_meniu">
              <div className="imagine_meniu">
                <img src={bkf} alt="Imagine Meniu" />
              </div>
              <div className="text_meniu">
                <div className="title_meniu">Calculator</div>
                <div className="subtitle_meniu">
                  Cărămidă și blocuri ceramice
                </div>
              </div>
            </div>
          </Link>
          <Link to="/caramida-klinker">
            <div className="item_meniu">
              <div className="imagine_meniu">
                <img src={sao} alt="Imagine Meniu" />
              </div>
              <div className="text_meniu">
                <div className="title_meniu">Calculator</div>
                <div className="subtitle_meniu">Cărămidă Klinker</div>
              </div>
            </div>
          </Link>
          <Link to="/placaj-klinker">
            <div className="item_meniu">
              <div className="imagine_meniu">
                <img src={oude} alt="Imagine Meniu" />
              </div>
              <div className="text_meniu">
                <div className="title_meniu">Calculator</div>
                <div className="subtitle_meniu">Placaj Klinker</div>
              </div>
            </div>
          </Link>
        </div>
        <div className="termeni">
          <div className="termeni_general">Termeni şi condiții</div>
          <div className="termeni_general">Despre Brikston</div>
        </div>
      </div>
    );
  }
}
