import React, { Component } from "react";

// Import componenta structura
import Structura from "./components/Structura";

class App extends Component {
  render() {
    return <Structura />;
  }
}

export default App;
