import React, { Component } from "react";

// Import componente React
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Import componente custom
import Header from "./Header";
import Subheader from "./Subheader";
import ListaCalculatoare from "./ListaCalculatoare";
import CalculatorCaramida from "./CalculatorCaramida";
import CaramidaKlinker from "./CaramidaKlinker";
import NecesarCaramidaKlinker from "./NecesarCaramidaKlinker";
import PlacajKlinker from "./PlacajKlinker";
import Cart from "./Cart";
import NecesarPlacajKlinker from "./NecesarPlacajKlinker";
import NecesarCaramida from "./NecesarCaramida";
import TermeniConditii from "./TermeniConditii";
import Despre from "./Despre";
import ConfirmareTrimitereOferta from "./ConfirmareTrimitereOferta";
// Import asset-uri
import "./../assets/css/style.css";
import "./../assets/css/style2.css";
import "./../assets/css/responsive.css";

export default class Structura extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="h100">
          <Switch>
            <Route exact path="/" component={CalculatorCaramida} />
            <Route path="/caramida-klinker" component={CalculatorCaramida} />
             {/*<Route path="/placaj-klinker" component={PlacajKlinker} />*/}
            <Route
              path="/caramida-blocuri-ceramice"
              component={CalculatorCaramida}/>
            <Route path="/sumar-comanda" component={NecesarCaramida} />
           {/* <Route
              path="/sumar-comanda-klinker"
              component={NecesarCaramidaKlinker}
            />*/}
            <Route path="/cart" component={Cart} />
             {/*<Route
              path="/sumar-comanda-placaj-klinker"
              component={NecesarPlacajKlinker}
            />*/}
            <Route path="/termeni-si-conditii" component={TermeniConditii} />
            {/*<Route path="/despre-brikston" component={Despre} />*/}
            <Route
              path="/confirmare-oferta"
              component={ConfirmareTrimitereOferta}
            />
          </Switch>
        </div>
      </BrowserRouter>
    );
   
  }
}
