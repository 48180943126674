import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

import CaramidaKlinker from "./CaramidaKlinker";
import Subheader from "./Subheader";
import Menu from "./Menu";
import Headerq from "./Headerq";

import product from "../assets/img/product.png";
import cart_btn from "../assets/img/cart_btn_white.png";
import background from "./../assets/img/background.jpg";

class NecesarCaramidaKlinker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suprafata_zidarie: 0,
      defaultSelectGrosime: 0,
      lungime: "",
      ultima_comanda: "",
      latime: "",
      inaltime: "",
      defaultCurrency: "lei",
      product_img: "dummy-img.png",
      nameProdusKlinker: "",
      bucati_palet: "",
      nume_mortar: "",
      discount: "",
      discount_mortar: "",
      pret_bucata_mortar: "",
      cursIntrodus: 1,
      redirect: false

      // defaultSelectModelKlinker: 0,
      // defaultSelectMortar: 0,
      // defaultSelectProdusKlinker: 0,
      // produse: [],
      // modele: (
      //   <option value="0" disabled="">
      //     Selectează Modelul
      //   </option>
      // ),
      // pret_bucata: "",

      // discount: "",

      // pret_bucata_mortar: "",

      // discount_mortar: ""
    };
  }

  componentWillMount() {
    console.log(localStorage);
    //if (this.props.location.state_curent) {
    var state_curent = this.props.location.state_curent;
    this.setState({
      // suprafata_zidarie: state_curent.state_curent.suprafata_zidarie,
      // defaultSelectGrosime: state_curent.state_curent.defaultSelectGrosime,
      // lungime: state_curent.state_curent.lungime,
      // latime: state_curent.state_curent.latime,
      // inaltime: state_curent.state_curent.inaltime,
      // defaultCurrency: state_curent.state_curent.defaultCurrency,
      // product_img: state_curent.state_curent.product_img,
      // nameProdusKlinker: state_curent.state_curent.nameProdusKlinker,
      // nume_mortar: state_curent.state_curent.nume_mortar,
      // bucati_palet: state_curent.state_curent.bucati_palet,
      // pret_bucata: state_curent.state_curent.pret_bucata,
      // pret_bucata_mortar: state_curent.state_curent.pret_bucata_mortar,
      // discount: state_curent.state_curent.discount,
      // discount_mortar: state_curent.state_curent.discount_mortar,
      // defaultSelectProdusKlinker:
      // state_curent.state_curent.defaultSelectProdusKlinker,
      defaultSelectModelKlinker: localStorage.getItem(
        "defaultSelectModelKlinker"
      ),
      suprafata_zidarie: localStorage.getItem("suprafata_zidarie"),
      defaultSelectGrosime: localStorage.getItem("defaultSelectGrosime"),
      lungime: localStorage.getItem("lungime"),
      latime: localStorage.getItem("latime"),
      inaltime: localStorage.getItem("inaltime"),
      defaultCurrency: localStorage.getItem("defaultCurrency"),
      product_img: localStorage.getItem("product_img"),
      nameProdusKlinker: localStorage.getItem("nameProdusKlinker"),
      nume_mortar: localStorage.getItem("nume_mortar"),
      bucati_palet: localStorage.getItem("bucati_palet"),
      pret_bucata: localStorage.getItem("pret_bucata"),
      pret_bucata_mortar: localStorage.getItem("pret_bucata_mortar"),
      discount: localStorage.getItem("discount"),
      discount_mortar: localStorage.getItem("discount_mortar"),
      ultima_comanda: localStorage.getItem("ultima_comanda"),
      defaultSelectProdusKlinker: localStorage.getItem(
        "defaultSelectProdusKlinker"
      ),
      denumireModel: localStorage.getItem("denumireModel"),
      cursIntrodus: localStorage.getItem("cursIntrodus"),
      suprafata_caramida_rost_update: "",
      necesar_mp_buc_update: "",
      cantitate_buc_update: "",
      cantitate_buc_update_paleti: "",
      paleti_update: "",
      consum_mortar_update: "",
      pret_buc_update: "",
      buc_palet_update: "",
      id_linie_comanda: localStorage.getItem("id_linie_comanda"),
      editareProdus: localStorage.getItem("editareProdus")
    });
    //}
  }

  componentWillUnmount() {}

  componentDidMount() {
    window.scrollTo(0, 0);

    console.log(this.props);
  }

  adaugaInCos = event => {
    if (localStorage.getItem("editareProdus") == 1) {
      var tip_actiune = "editare_produs";
    } else {
      var tip_actiune = "adauga_in_cos";
    }

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        get_data: tip_actiune,
        idKlinker: localStorage.getItem("defaultSelectProdusKlinker"),
        idModel: localStorage.getItem("defaultSelectModelKlinker"),
        id_linie_comanda: localStorage.getItem("id_linie_comanda"),
        idMortar: localStorage.getItem("defaultSelectMortar"),
        denumireMortar: localStorage.getItem("nume_mortar"),
        pretMortar: localStorage.getItem("pret_bucata_mortar"),
        discount_mortar: localStorage.getItem("discount_mortar"),
        denumireKlinker: localStorage.getItem("nameProdusKlinker"),
        prescurtare: localStorage.getItem("suprafata_zidarie"),
        denumireModel: localStorage.getItem("denumireModel"),
        suprafata: localStorage.getItem("suprafata_zidarie"),
        lungime: localStorage.getItem("lungime"),
        latime: localStorage.getItem("latime"),
        inaltime: localStorage.getItem("inaltime"),
        grosime_rost: localStorage.getItem("defaultSelectGrosime"),
        poza_caramida: localStorage.getItem("product_img"),
        suprafata_caramida_rost: localStorage.getItem(
          "suprafata_caramida_rost_update"
        ),
        necesar_mp_buc: localStorage.getItem("necesar_mp_buc_update"),
        cantitate_buc: localStorage.getItem("cantitate_buc_update"),
        cantitate_buc_paleti:
          parseFloat(localStorage.getItem("bucati_palet")) *
          parseFloat(localStorage.getItem("paleti_update")),
        paleti: localStorage.getItem("paleti_update"),
        pret_palet: 25,
        defaultCurrency: localStorage.getItem("defaultCurrency"),
        cursIntrodus: localStorage.getItem("cursIntrodus"),
        consum_mortar: localStorage.getItem("consum_mortar_update"),
        pret_buc: localStorage.getItem("pret_bucata"),
        buc_palet: localStorage.getItem("bucati_palet"),
        discount: localStorage.getItem("discount"),
        ultima_comanda: localStorage.getItem("ultima_comanda"),
        valoare_totala_fara_tva_caramida: localStorage.getItem(
          "valoare_totala_fara_tva_caramida"
        ),
        valoare_fara_tva_mortar: localStorage.getItem(
          "valoare_fara_tva_mortar"
        ),
        valoare_paleti: localStorage.getItem("valoare_paleti"),
        valoare_totala_produs_fara_tva: localStorage.getItem(
          "valoare_totala_produs_fara_tva"
        )
      })
      .then(res => {
        // Fac clear la localStorage, insa fara moneda selectat si cursul introdus
        var monedaSelect = localStorage.getItem("defaultCurrency");
        var cursSelect = localStorage.getItem("cursIntrodus");
        var ultimaComanda = localStorage.getItem("ultima_comanda");
        localStorage.clear();
        localStorage.setItem("defaultCurrency", monedaSelect);
        localStorage.setItem("cursIntrodus", cursSelect);
        localStorage.setItem("ultima_comanda", ultimaComanda);

        // Redirect
        this.setState({
          redirect: true
        });
      });
  };

  render() {
    // Redirect pe pagina cos
    if (this.state.redirect) {
      //return <Redirect push to="/cart" />;
      window.location.href = 'https://leier.viscero.ro/reprezentanti-zonali/'; 
      return null;
    }

    var suprafata_caramida_rost =
      ((parseInt(this.state.lungime) +
        parseInt(this.state.defaultSelectGrosime)) *
        (parseInt(this.state.inaltime) +
          parseInt(this.state.defaultSelectGrosime))) /
      1000000;
    var necesar_mp_buc = Math.round(1 / suprafata_caramida_rost);

    var cantitate_buc = Math.round(
      parseFloat(this.state.suprafata_zidarie) /
        parseFloat(suprafata_caramida_rost)
    );
    var paleti = Math.ceil(cantitate_buc / this.state.bucati_palet);
    var cantitate_paleti = paleti * this.state.bucati_palet;

    var consum_mortar =
      ((1 -
        (necesar_mp_buc *
          parseInt(this.state.lungime) *
          parseInt(this.state.inaltime)) /
          1000000) /
        10) *
      1.1;

    var pret_buc =
      (parseFloat(this.state.pret_bucata) *
        (100 - parseFloat(this.state.discount))) /
      100;

    var pret_buc_mortar =
      (parseFloat(this.state.pret_bucata_mortar) *
        (100 - parseFloat(this.state.discount_mortar))) /
      100;

    var paritate = 1;
    var valoare_fara_tva = (cantitate_paleti * pret_buc) / paritate;
    var valoare_cu_tva = (cantitate_paleti * pret_buc * 1.19) / paritate;

    var valoare_fara_tva_mortar_specific =
      (consum_mortar * 50 * pret_buc_mortar) / paritate;
    var valoare_cu_tva_mortar_specific =
      (consum_mortar * 50 * pret_buc_mortar * 1.19) / paritate;

    var valoare_totala_fara_tva_caramida_livrabil =
      (necesar_mp_buc * pret_buc) / paritate;
    var valoare_totala_cu_tva_caramida_livrabil =
      (necesar_mp_buc * pret_buc * 1.19) / paritate;

    var valoare_fara_tva_mortar =
      (Math.ceil(
        consum_mortar * 50 * parseFloat(this.state.suprafata_zidarie)
      ) *
        pret_buc_mortar) /
      paritate;
    var valoare_cu_tva_mortar =
      (Math.ceil(
        consum_mortar * 50 * parseFloat(this.state.suprafata_zidarie)
      ) *
        pret_buc_mortar *
        1.19) /
      paritate;

    var cantitate_paleti_livrabili = Math.ceil(
      consum_mortar * 50 * parseFloat(this.state.suprafata_zidarie)
    );

    localStorage.setItem(
      "valoare_fara_tva_mortar",
      valoare_fara_tva_mortar.toFixed(2)
    );
    localStorage.setItem(
      "valoare_totala_fara_tva_caramida",
      valoare_fara_tva.toFixed(2)
    );
    localStorage.setItem("valoare_paleti", (paleti * 25).toFixed(2));
    localStorage.setItem(
      "valoare_totala_produs_fara_tva",
      (
        parseFloat(valoare_fara_tva_mortar) +
        parseFloat(paleti * 25) +
        parseFloat(valoare_fara_tva)
      ).toFixed(2)
    );

    localStorage.setItem(
      "suprafata_caramida_rost_update",
      suprafata_caramida_rost
    );
    localStorage.setItem("necesar_mp_buc_update", necesar_mp_buc);
    localStorage.setItem("cantitate_buc_update", cantitate_buc);
    localStorage.setItem("cantitate_buc_update_paleti", cantitate_buc);
    localStorage.setItem("paleti_update", paleti);
    localStorage.setItem("consum_mortar_update", consum_mortar);
    localStorage.setItem("pret_buc_update", pret_buc);
    localStorage.setItem("buc_palet_update", cantitate_buc);

    return (
      <div
        className="background calculator_caramida"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Headerq />
        <div className="subheader2">
          <div className="subheader" id="subheader">
            Necesar de cărămidă {this.state.nameProdusKlinker}
          </div>
        </div>
        <div className="container_caramida">
          <div className="componenta-necesar-caramida flxx necesar_flex">
            <div className="imagine_necesar">
              <img
                className="img_produs1"
                src={require("../assets/img/klinker/" + this.state.product_img)}
                alt=""
              />
            </div>
            <div className="necesar_right necesar-caramida-inner">
              <div className="mt">
                <div className="calculator-field-title medium tl_mare">
                  Date intrare
                </div>
                <div className="box_cantitate">
                  <div className="info-constructie-items flxx nowrap">
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">
                        Suprafață zidărie (m<sup>2</sup>)
                      </span>
                      <input
                        disabled
                        value={this.state.suprafata_zidarie}
                        type="text"
                        className="input_red pr0 c1 fz18 bd"
                        name="suprafata_zidarie"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Grosime rost (mm)</span>
                      <input
                        disabled
                        value={this.state.defaultSelectGrosime}
                        type="text"
                        className="input_red pr0  c1 fz18 bd"
                        name="grosime_rost"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Lungime (mm)</span>
                      <input
                        disabled
                        value={this.state.lungime}
                        type="text"
                        className="input_red pr0  c1 fz18 bd"
                        name="lungime"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Lățime (mm)</span>
                      <input
                        disabled
                        value={this.state.latime}
                        type="text"
                        className="input_red pr0  c1 fz18 bd"
                        name="latime"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Inălțime (mm)</span>
                      <input
                        disabled
                        value={this.state.inaltime}
                        type="text"
                        className="input_red pr0  c1 fz18 bd"
                        name="inaltime"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Monedă</span>
                      <input
                        disabled
                        value={this.state.defaultCurrency.toUpperCase()}
                        type="text"
                        className="input_red pr0  c1 fz18 bd"
                        name="moneda"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Discount Cărămidă (%)</span>
                      <input
                        disabled
                        value={this.state.discount}
                        type="text"
                        className="input_red pr0  c1 fz18 bd"
                        name="moneda"
                      />
                    </div>
                    <div className="info-constructie-item flex_12  flx sb">
                      <span className="item-title">Discount Mortar (%)</span>
                      <input
                        disabled
                        value={this.state.discount_mortar}
                        type="text"
                        className="input_red pr0  c1 fz18 bd"
                        name="moneda"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt">
                <div className="calculator-field-title medium tl_mare mtt">
                  Consum specific de materiale
                </div>
                <div className="tabel_consum">
                  <div className="cap_tabel">
                    <div className="item-title">
                      La m<sup>2</sup> de zidărie
                    </div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (buc)
                    </div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (kg/mp)
                    </div>
                     {/*
                    <div className="item-title">
                      Valoare
                      <br /> (fără TVA)
                    </div>
                    <div className="item-title">
                      Valoare
                      <br /> (cu TVA)
                    </div>
                     */}
                  </div>
                  <div className="cap_tabel">
                    <div className="black_item">
                      {this.state.nameProdusKlinker}
                      {"  "}
                      {this.state.denumireModel}
                    </div>
                    <div className="red_item">{necesar_mp_buc}</div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {parseFloat(
                        valoare_totala_fara_tva_caramida_livrabil /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        valoare_totala_cu_tva_caramida_livrabil /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel nob">
                    <div className="black_item">
                      Mortar {this.state.nume_mortar}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {(consum_mortar * 1500)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    {/*
                    <div className="red_item">
                      {parseFloat(
                        valoare_fara_tva_mortar_specific /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        valoare_cu_tva_mortar_specific / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                      */}
                  </div>
                  <div className="cap_tabel cap_tabel_total">
                    <div className="black_item">TOTAL</div>
                    <div className="red_item">--</div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {parseFloat(
                        (parseFloat(valoare_totala_fara_tva_caramida_livrabil) +
                          parseFloat(valoare_fara_tva_mortar_specific)) /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {this.state.defaultCurrency.toUpperCase()}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        (parseFloat(valoare_totala_cu_tva_caramida_livrabil) +
                          parseFloat(valoare_cu_tva_mortar_specific)) /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {this.state.defaultCurrency.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt">
                <div className="calculator-field-title medium tl_mare mtt">
                  Cantități livrabile (în funcție de unități de împachetare)
                </div>

                <div className="tabel_consum">
                  <div className="cap_tabel">
                    <div className="item-title">Tip material</div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (buc)
                    </div>
                    <div className="item-title">
                      Cantitate
                      <br />
                      (kg/mp)
                    </div>
                    <div className="item-title">
                      Valoare <br />
                      (fără TVA)
                    </div>
                    <div className="item-title">
                      Valoare <br />
                      (cu TVA)
                    </div>
                  </div>
                  <div className="cap_tabel">
                    <div className="black_item">
                      {this.state.nameProdusKlinker}
                      {"  "}
                      {this.state.denumireModel}
                    </div>
                    <div className="red_item">
                      {cantitate_paleti
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {paleti.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(valoare_fara_tva / this.state.cursIntrodus)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(valoare_cu_tva / this.state.cursIntrodus)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel">
                    <div className="black_item">Paleți</div>
                    <div className="red_item">
                      {paleti.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {parseFloat((paleti * 25) / this.state.cursIntrodus)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        (paleti * 1.19 * 25) / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel nob">
                    <div className="black_item">
                      Mortar {this.state.nume_mortar}
                    </div>
                    <div className="red_item">
                      {cantitate_paleti_livrabili
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {parseFloat(
                        valoare_fara_tva_mortar / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        valoare_cu_tva_mortar / this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </div>
                  <div className="cap_tabel cap_tabel_total">
                    <div className="black_item">TOTAL</div>
                    <div className="red_item">--</div>
                    <div className="red_item">--</div>
                    <div className="red_item">
                      {parseFloat(
                        (parseFloat(valoare_fara_tva_mortar) +
                          parseFloat(paleti * 25) +
                          parseFloat(valoare_fara_tva)) /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {this.state.defaultCurrency.toUpperCase()}
                    </div>
                    <div className="red_item">
                      {parseFloat(
                        (parseFloat(valoare_cu_tva_mortar) +
                          parseFloat(paleti * 1.19 * 25) +
                          parseFloat(valoare_cu_tva)) /
                          this.state.cursIntrodus
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      {this.state.defaultCurrency.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="butoane_cart">
                <Link
                  to={{
                    pathname: "/caramida-klinker",
                    data: { refaCalculData: this.state, sourceRefaCalcul: 1 }
                  }}
                >
                  <div className="btn_general refa_calculul bd">
                    Refă calculul
                  </div>
                </Link>
                <div
                  onClick={this.adaugaInCos}
                  className="btn_general  adauga_oferta_in_cos bd"
                >
                  Contactează un agent leier pentru ofertă
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NecesarCaramidaKlinker;
