import React, { Component } from "react";

export default class Subheader extends Component {
  render() {
    return (
      <div className="subheader" id="subheader">
        Calculează online necesarul de produse
      </div>
    );
  }
}
