import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";

import Subheader from "./Subheader";
import Menu from "./Menu";
import Headerq from "./Headerq";

import background from "./../assets/img/background.jpg";

// Import assets
import product_image from "../assets/img/product.png";
import edit_icon from "../assets/img/edit.png";

class PlacajKlinker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lungimePaleti: "",
      active_inputs: false,
      lungimePaletiShow: false,
      defaultCurrency: "lei",
      cursIntrodus: 1,
      cursIntrodusAfisat: 0,
      produse_placaj: [],
      chit_placaj: [],
      adeziv_placaj: [],
      modele_placaj: [],
      modele_culoare_chit: [],
      defaultSelectProdusPlacajKlinker: 0,
      nameProdusPlacajKlinker: "",
      product_img_placaj: "dummy-img.png",
      defaultSelectModelPlacajKlinker: "",
      denumirePlacajModel: "",
      discount_adeziv: 0.0,
      discount_chit: 0.0,
      discount: 0.0,
      ultima_comanda: "",
      pret_bucata_placaj: "",
      suprafata_placare: "",
      lungime_colturi: "",
      pret_adeziv: "",
      modelAdeziv: 0,
      modelChit: "",
      nameModelAdeziv: "",
      nameModelChit: "",
      pret_chit: "",
      nameCuloareChit: "",
      nameSelectModelPlacajKlinker: "",
      grosime_placaj: "",
      general_inaltime_coltar_placaj: "",
      general_latime_coltar_placaj: "",
      general_lungime_coltar: "",
      general_lungime_coltar_placaj: "",
      general_pret_coltar_placaj: "",
      general_buc_cutie_placaj: "",
      general_buc_cutie_coltar: "",
      general_latura_scurta_placaj: "",
      pret_chit_culoare: "",
      kgSac: "",
      editareProdusPlacaj: 0,
      idChitRosturi: 0,
      idCuloareChit: 0
    };
  }

  componentDidMount() {
    $("input").click(function() {
      var value = $(this).val();
      if (value == "0" || value == "0.00") {
        $(this).select();
      }
    });
    $("select").on("change", function() {
      if (this.value != "" && this.value != 0) {
        $(this).css("cssText", "color:#000;");

        if ($(this).hasClass("selectprodus")) {
          $(".selectmodel").css("cssText", "color:#000;");
        }
        if ($(this).hasClass("selectchit")) {
          $(".selectculoarechit").css("cssText", "color:#000;");
        }
      } else {
        $(this).css("cssText", "color:#666;");
      }
    });
  }

  changeLungimePaletiValue = event => {
    this.setState({
      lungimePaleti: event.target.value
    });
  };

  lungimePaletiShow = event => {
    this.setState({
      lungimePaletiShow: true
    });
  };

  /* currencyChange = event => {
    if (event.target.value == "euro") {
      this.setState({
        defaultCurrency: "euro"
      });
    } else if (event.target.value == "lei") {
      this.setState({
        defaultCurrency: "lei"
      });
    }
  }; */

  componentWillMount() {
    if (this.props.location.state != undefined) {
      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "produse_placaj"
        })
        .then(res => {
          var produse_array = [];
          for (var k = 0; k < res.data.length; k++) {
            produse_array.push(
              <option key={res.data[k].id_produs} value={res.data[k].id_produs}>
                {res.data[k].nume_produs}
              </option>
            );
          }
          console.log(this.props.location.state);

          this.setState({
            editareProdusPlacaj: 1,
            active_inputs: true
          });
          this.setState({
            id_linie_comanda: this.props.location.state.dataToEdit
              .idLinieComanda
          });

          this.setState({ produse_placaj: produse_array });

          this.setState({
            defaultSelectGrosime: this.props.location.state.dataToEdit
              .grosime_rost,
            defaultSelectProdusPlacajKlinker: this.props.location.state
              .dataToEdit.idPlacaj
          });

          var value = this.props.location.state.dataToEdit.idModel;
          var id_produs = this.state.idPlacaj;

          // Populare select placaj Klinker
          this.setState({
            nameProdusPlacajKlinker: this.props.location.state.dataToEdit
              .denumirePlacaj
          });

          axios
            .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
              get_data: "chit_placaj"
            })
            .then(res => {
              var chit_placaj_array = [];
              for (var k = 0; k < res.data.length; k++) {
                chit_placaj_array.push(
                  <option
                    key={res.data[k].id_produs}
                    value={res.data[k].id_produs}
                  >
                    {res.data[k].nume_produs}
                  </option>
                );
              }

              this.setState({ chit_placaj: chit_placaj_array });
            });

          axios
            .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
              get_data: "adeziv_placaj"
            })
            .then(res => {
              var produse_adeziv_array = [];
              for (var k = 0; k < res.data.length; k++) {
                produse_adeziv_array.push(
                  <option
                    key={res.data[k].id_produs}
                    value={res.data[k].id_produs}
                  >
                    {res.data[k].nume_produs}
                  </option>
                );
              }
              this.setState({ adeziv_placaj: produse_adeziv_array });
            });

          axios
            .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
              id: this.props.location.state.dataToEdit.idPlacaj,
              get_data: "modelPlacaj"
            })
            .then(res => {
              var modele_placaj_array = [];
              for (var k = 0; k < res.data.array_model.length; k++) {
                modele_placaj_array.push(
                  <option
                    key={res.data.array_model[k].idModel}
                    value={res.data.array_model[k].idModel}
                  >
                    {res.data.array_model[k].denumireModel}
                  </option>
                );
              }

              this.setState({
                product_img_placaj: res.data.pret_model.imagine,
                denumirePlacajModel: res.data.pret_model.denumireModel,
                general_inaltime_coltar_placaj: res.data.model_placaj.inaltime,
                general_latime_coltar_placaj: res.data.model_placaj.latime,
                general_lungime_coltar_placaj: res.data.model_placaj.lungime,
                general_buc_cutie_placaj: res.data.model_placaj.buc_cutie,
                general_buc_cutie_coltar:
                  res.data.model_placaj.buc_cutie_coltar,
                general_pret_coltar_placaj: res.data.pret_model.pret_coltar,
                general_latura_scurta_placaj: res.data.pret_model.latura_scurta,
                general_lungime_coltar: res.data.pret_model.lungime_coltar
              });

              // Culoare chit rosturi
              axios
                .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
                  id: this.props.location.state.dataToEdit.idChit,
                  get_data: "culoareChitRosturi"
                })
                .then(res => {
                  var culoare_chit_rosturi_array = [];
                  for (var k = 0; k < res.data.array_model.length; k++) {
                    culoare_chit_rosturi_array.push(
                      <option
                        key={res.data.array_model[k].idCuloareChit}
                        value={res.data.array_model[k].idCuloareChit}
                      >
                        {res.data.array_model[k].denumireCuloare}
                      </option>
                    );
                  }
                  this.setState({
                    modele_culoare_chit: culoare_chit_rosturi_array,
                    kgSac: res.data.pret_model.kgSac,
                    pret_chit_culoare: res.data.pret_model.pretChit
                  });
                });

              axios
                .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
                  id: this.props.location.state.dataToEdit.idModel,
                  get_data: "pret_model_placaj"
                })
                .then(res => {
                  if (
                    sessionStorage.getItem("ultima_comanda") === null ||
                    sessionStorage.getItem("ultima_comanda") === ""
                  ) {
                    sessionStorage.setItem("ultima_comanda", res.data);
                  }
                  this.setState({
                    product_img_placaj: res.data.pret_model.imagine
                  });
                });

              this.setState({
                modele_placaj: modele_placaj_array,
                product_img_placaj: res.data.pret_model.imagine,
                defaultCurrency: this.props.location.state.dataToEdit.moneda,
                cursIntrodus: this.props.location.state.dataToEdit.cursIntrodus,
                grosime_placaj: this.props.location.state.dataToEdit
                  .grosime_rost,
                nameProdusPlacajKlinker: this.props.location.state.dataToEdit
                  .denumirePlacaj,
                defaultSelectProdusPlacajKlinker: this.props.location.state
                  .dataToEdit.idPlacaj,

                defaultSelectModelPlacajKlinker: this.props.location.state
                  .dataToEdit.idModel,
                denumirePlacajModel: this.props.location.state.dataToEdit
                  .denumireModel,
                nameModelAdeziv: this.props.location.state.dataToEdit
                  .denumireAdeziv,
                modelAdeziv: this.props.location.state.dataToEdit.idAdeziv,
                nameChitRosturi: this.props.location.state.dataToEdit
                  .denumireChit,
                idChitRosturi: this.props.location.state.dataToEdit.idChit,
                nameCuloareChit: this.props.location.state.dataToEdit
                  .culoareChit,
                idCuloareChit: this.props.location.state.dataToEdit.idChit,
                imagine_placaj: this.props.location.state.dataToEdit
                  .poza_placaj,
                suprafata_placare: this.props.location.state.dataToEdit
                  .suprafata,
                lungime_colturi: this.props.location.state.dataToEdit
                  .lungime_colturi,
                pret_bucata_placaj: this.props.location.state.dataToEdit
                  .pret_buc,
                discount: this.props.location.state.dataToEdit.discount,
                pret_adeziv: this.props.location.state.dataToEdit.pretAdeziv,
                discount_adeziv: this.props.location.state.dataToEdit
                  .discount_adeziv,
                pret_chit: this.props.location.state.dataToEdit.pretChit,
                discount_chit: this.props.location.state.dataToEdit
                  .discount_chit
              });
            });
        });
    } else {
      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "produse_placaj"
        })
        .then(res => {
          var produse_placaj_array = [];
          for (var k = 0; k < res.data.length; k++) {
            produse_placaj_array.push(
              <option key={res.data[k].id_produs} value={res.data[k].id_produs}>
                {res.data[k].nume_produs}
              </option>
            );
          }

          this.setState({ produse_placaj: produse_placaj_array });
        });

      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "chit_placaj"
        })
        .then(res => {
          var chit_placaj_array = [];
          for (var k = 0; k < res.data.length; k++) {
            chit_placaj_array.push(
              <option key={res.data[k].id_produs} value={res.data[k].id_produs}>
                {res.data[k].nume_produs}
              </option>
            );
          }

          this.setState({ chit_placaj: chit_placaj_array });
        });

      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "adeziv_placaj"
        })
        .then(res => {
          var produse_adeziv_array = [];
          for (var k = 0; k < res.data.length; k++) {
            produse_adeziv_array.push(
              <option key={res.data[k].id_produs} value={res.data[k].id_produs}>
                {res.data[k].nume_produs}
              </option>
            );
          }
          this.setState({ adeziv_placaj: produse_adeziv_array });
        });
      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "ultima_comanda"
        })
        .then(res => {
          if (
            sessionStorage.getItem("ultima_comanda") === null ||
            sessionStorage.getItem("ultima_comanda") === ""
          ) {
            sessionStorage.setItem("ultima_comanda", res.data);
          }
        });

      // Refa calcul
      if (
        this.props.location.data != undefined &&
        this.props.location.data.refaCalculData != undefined &&
        this.props.location.data.sourceRefaCalcul == 1
      ) {
        console.log(this.props.location.data.refaCalculData);

        // Populare select placaj Klinker
        this.setState({
          defaultSelectProdusPlacajKlinker: this.props.location.data
            .refaCalculData.defaultSelectProdusPlacajKlinker,
          nameProdusPlacajKlinker: this.props.location.data.refaCalculData
            .nameProdusPlacajKlinker
        });

        axios
          .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
            id: this.props.location.data.refaCalculData
              .defaultSelectProdusPlacajKlinker,
            get_data: "modelPlacaj"
          })
          .then(res => {
            var modele_placaj_array = [];
            for (var k = 0; k < res.data.array_model.length; k++) {
              modele_placaj_array.push(
                <option
                  key={res.data.array_model[k].idModel}
                  value={res.data.array_model[k].idModel}
                >
                  {res.data.array_model[k].denumireModel}
                </option>
              );
            }

            this.setState({
              product_img_placaj: res.data.pret_model.imagine,
              denumirePlacajModel: res.data.pret_model.denumireModel,
              general_inaltime_coltar_placaj: res.data.model_placaj.inaltime,
              general_latime_coltar_placaj: res.data.model_placaj.latime,
              general_lungime_coltar_placaj: res.data.model_placaj.lungime,
              general_buc_cutie_placaj: res.data.model_placaj.buc_cutie,
              general_buc_cutie_coltar: res.data.model_placaj.buc_cutie_coltar,
              general_pret_coltar_placaj: res.data.pret_model.pret_coltar,
              general_latura_scurta_placaj: res.data.pret_model.latura_scurta,
              general_lungime_coltar: res.data.pret_model.lungime_coltar
            });

            // Culoare chit rosturi
            axios
              .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
                id: this.props.location.data.refaCalculData.idChitRosturi,
                get_data: "culoareChitRosturi"
              })
              .then(res => {
                var culoare_chit_rosturi_array = [];
                for (var k = 0; k < res.data.array_model.length; k++) {
                  culoare_chit_rosturi_array.push(
                    <option
                      key={res.data.array_model[k].idCuloareChit}
                      value={res.data.array_model[k].idCuloareChit}
                    >
                      {res.data.array_model[k].denumireCuloare}
                    </option>
                  );
                }
                this.setState({
                  modele_culoare_chit: culoare_chit_rosturi_array,
                  kgSac: res.data.pret_model.kgSac,
                  pret_chit_culoare: res.data.pret_model.pretChit
                });
              });

            axios
              .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
                id: this.props.location.data.refaCalculData
                  .defaultSelectModelPlacajKlinker,
                get_data: "pret_model_placaj"
              })
              .then(res => {
                this.setState({
                  product_img_placaj: res.data.pret_model.imagine
                });
              });

            this.setState({
              modele_placaj: modele_placaj_array,
              product_img_placaj: res.data.pret_model.imagine,
              defaultCurrency: this.props.location.data.refaCalculData
                .defaultCurrency,
              cursIntrodus: this.props.location.data.refaCalculData
                .cursIntrodus,
              grosime_placaj: this.props.location.data.refaCalculData
                .grosime_placaj,
              nameProdusPlacajKlinker: this.props.location.data.refaCalculData
                .nameProdusPlacajKlinker,
              defaultSelectProdusPlacajKlinker: this.props.location.data
                .refaCalculData.defaultSelectProdusPlacajKlinker,
              defaultSelectProdusPlacajKlinker: this.props.location.data
                .refaCalculData.defaultSelectProdusPlacajKlinker,
              defaultSelectModelPlacajKlinker: this.props.location.data
                .refaCalculData.defaultSelectModelPlacajKlinker,
              denumirePlacajModel: this.props.location.data.refaCalculData
                .denumirePlacajModel,
              nameModelAdeziv: this.props.location.data.refaCalculData
                .nameModelAdeziv,
              modelAdeziv: this.props.location.data.refaCalculData.modelAdeziv,
              nameChitRosturi: this.props.location.data.refaCalculData
                .nameChitRosturi,
              idChitRosturi: this.props.location.data.refaCalculData
                .idChitRosturi,
              nameCuloareChit: this.props.location.data.refaCalculData
                .nameCuloareChit,
              idCuloareChit: this.props.location.data.refaCalculData
                .idCuloareChit,
              imagine_placaj: this.props.location.data.refaCalculData
                .product_img_placaj,
              suprafata_placare: this.props.location.data.refaCalculData
                .suprafata_placare,
              lungime_colturi: this.props.location.data.refaCalculData
                .lungime_colturi,
              pret_bucata_placaj: this.props.location.data.refaCalculData
                .pret_placaj,
              discount: this.props.location.data.refaCalculData.discount_placaj,
              pret_adeziv: this.props.location.data.refaCalculData.pret_adeziv,
              discount_adeziv: this.props.location.data.refaCalculData
                .discount_adeziv,
              pret_chit: this.props.location.data.refaCalculData.pret_chit,
              discount_chit: this.props.location.data.refaCalculData
                .discount_chit,
              id_linie_comanda: this.props.location.data.refaCalculData
                .id_linie_comanda,
              editareProdusPlacaj: this.props.location.data.refaCalculData
                .editareProdusPlacaj
            });
          });
        this.setState({ active_inputs: true });
      }
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });

    if ([event.target.name] == "suprafata_placare") {
      if (
        this.state.grosime_placaj != 0 &&
        event.target.value != "" &&
        this.state.lungime_colturi != ""
      ) {
        var x = true;
      } else {
        var x = false;
      }
      this.setState({
        active_inputs: x
      });
    }

    if ([event.target.name] == "lungime_colturi") {
      if (
        this.state.grosime_placaj != 0 &&
        event.target.value != "" &&
        this.state.suprafata_placare != ""
      ) {
        var x = true;
      } else {
        var x = false;
      }
      this.setState({
        active_inputs: x
      });
    }

    if ([event.target.name] == "grosime_placaj") {
      if (
        this.state.suprafata_placare != 0 &&
        event.target.value != "" &&
        this.state.suprafata_placare != ""
      ) {
        var x = true;
      } else {
        var x = false;
      }
      this.setState({
        active_inputs: x
      });
    }
  };

  puneProdusePlacaj = event => {
    var value = event.target.value;
    this.setState({
      defaultSelectProdusPlacajKlinker: value,
      nameProdusPlacajKlinker:
        event.target.options[event.target.selectedIndex].text
    });

    var id_produs = this.state.defaultSelectProdusPlacajKlinker;

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        id: value,
        get_data: "modelPlacaj"
      })
      .then(res => {
        console.log(res.data);
        var modele_placaj_array = [];
        for (var k = 0; k < res.data.array_model.length; k++) {
          modele_placaj_array.push(
            <option
              key={res.data.array_model[k].idModel}
              value={res.data.array_model[k].idModel}
            >
              {res.data.array_model[k].denumireModel}
            </option>
          );
        }

        this.setState({
          product_img_placaj: res.data.pret_model.imagine,
          defaultSelectModelPlacajKlinker: res.data.pret_model.idModel,
          denumirePlacajModel: res.data.pret_model.denumireModel,
          general_inaltime_coltar_placaj: res.data.model_placaj.inaltime,
          general_latime_coltar_placaj: res.data.model_placaj.latime,
          general_lungime_coltar_placaj: res.data.model_placaj.lungime,
          general_buc_cutie_placaj: res.data.model_placaj.buc_cutie,
          general_buc_cutie_coltar: res.data.model_placaj.buc_cutie_coltar,
          general_pret_coltar_placaj: res.data.pret_model.pret_coltar,
          general_latura_scurta_placaj: res.data.pret_model.latura_scurta,
          general_lungime_coltar: res.data.pret_model.lungime_coltar
        });

        this.setState({ pret_bucata_placaj: res.data.pret_model.pret_buc });
        this.setState({ discount: 0.0 });
        this.setState({ modele_placaj: modele_placaj_array });
        this.setState({ ultima_comanda: res.data.ultima_comanda });
      });
  };

  culoareChitRosturi = event => {
    var value = event.target.value;
    this.setState({
      idChitRosturi: value,
      nameChitRosturi: event.target.options[event.target.selectedIndex].text
    });

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        id: value,
        get_data: "culoareChitRosturi"
      })
      .then(res => {
        console.log(res.data);
        var culoare_chit_rosturi_array = [];
        for (var k = 0; k < res.data.array_model.length; k++) {
          culoare_chit_rosturi_array.push(
            <option
              key={res.data.array_model[k].idCuloareChit}
              value={res.data.array_model[k].idCuloareChit}
            >
              {res.data.array_model[k].denumireCuloare}
            </option>
          );
        }

        this.setState({
          idCuloareChit: res.data.pret_model.idCuloareChit,
          nameCuloareChit: res.data.pret_model.denumireCuloare,
          kgSac: res.data.pret_model.kgSac,
          pret_chit_culoare: res.data.pret_model.pretChit
        });

        this.setState({ pret_chit: res.data.pret_model.pretChit });
        this.setState({ discount_chit: 0.0 });
        this.setState({ modele_culoare_chit: culoare_chit_rosturi_array });
        this.setState({ ultima_comanda: res.data.ultima_comanda });
      });
  };

  pretAdeziv = event => {
    var value = event.target.value;
    this.setState({
      modelAdeziv: value,
      nameModelAdeziv: event.target.options[event.target.selectedIndex].text
    });
    var id_adeziv = this.state.modelAdeziv;

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        id: value,
        get_data: "pret_adeziv"
      })
      .then(res => {
        console.log(res.data);
        this.setState({ pret_adeziv: res.data.pret_model.pretAdeziv });
      });
  };

  pretCuloareChit = event => {
    var value = event.target.value;
    this.setState({
      idCuloareChit: value,
      nameCuloareChit: event.target.options[event.target.selectedIndex].text
    });

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        id: value,
        get_data: "pret_culoare_chit"
      })
      .then(res => {
        console.log(res.data);
        this.setState({ pret_chit: res.data.pret_model.pretChit });
      });
  };

  pretModelPlacaj = event => {
    var value = event.target.value;
    this.setState({
      defaultSelectModelPlacajKlinker: value,
      nameSelectModelPlacajKlinker:
        event.target.options[event.target.selectedIndex].text
    });

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        id: value,
        get_data: "pret_model_placaj"
      })
      .then(res => {
        console.log(res.data);
        this.setState({ pret_bucata_placaj: res.data.pret_model.pret_buc });
        this.setState({ product_img_placaj: res.data.pret_model.imagine });
      });
  };

  pretChit = event => {
    var value = event.target.value;
    this.setState({
      modelChit: value,
      nameModelChit: event.target.options[event.target.selectedIndex].text
    });
    var id_chit = this.state.modelChit;

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        id: value,
        get_data: "pret_chit"
      })
      .then(res => {
        this.setState({ pret_chit: res.data[0].pret_chit });
      });
  };

  disabledLink = event => {
    console.log(this.state.ultima_comanda);
    if (
      this.state.defaultSelectModelPlacajKlinker == 0 ||
      this.state.defaultSelectProdusPlacajKlinker == 0 ||
      this.state.idChitRosturi == 0 ||
      this.state.suprafata_placare == "" ||
      this.state.lungime_colturi == ""
    ) {
      event.preventDefault();
      alert("Completati toate campurile!!");
    } else {
      sessionStorage.setItem(
        "defaultSelectModelPlacajKlinker",
        this.state.defaultSelectModelPlacajKlinker
      );
      sessionStorage.setItem(
        "nameSelectModelPlacajKlinker",
        this.state.denumirePlacajModel
      );
      sessionStorage.setItem(
        "defaultSelectProdusPlacajKlinker",
        this.state.defaultSelectProdusPlacajKlinker
      );
      sessionStorage.setItem(
        "nameProdusPlacajKlinker",
        this.state.nameProdusPlacajKlinker
      );
      sessionStorage.setItem("modelAdeziv", this.state.modelAdeziv);
      sessionStorage.setItem("grosime_placaj", this.state.grosime_placaj);
      sessionStorage.setItem("nameModelAdeziv", this.state.nameModelAdeziv);
      sessionStorage.setItem("idChitRosturi", this.state.idChitRosturi);
      sessionStorage.setItem("nameChitRosturi", this.state.nameChitRosturi);
      sessionStorage.setItem("idCuloareChit", this.state.idCuloareChit);
      sessionStorage.setItem("nameCuloareChit", this.state.nameCuloareChit);
      sessionStorage.setItem("suprafata_placare", this.state.suprafata_placare);
      sessionStorage.setItem("lungime_colturi", this.state.lungime_colturi);
      sessionStorage.setItem("pret_placaj", this.state.pret_bucata_placaj);
      sessionStorage.setItem("poza_placaj", this.state.product_img_placaj);

      sessionStorage.setItem("pret_adeziv", this.state.pret_adeziv);
      sessionStorage.setItem("pret_chit", this.state.pret_chit);
      sessionStorage.setItem("discount_placaj", this.state.discount);
      sessionStorage.setItem("discount_adeziv", this.state.discount_adeziv);
      sessionStorage.setItem("discount_chit", this.state.discount_chit);
      sessionStorage.setItem("imagine_placaj", this.state.product_img_placaj);
      sessionStorage.setItem("kgSac", this.state.kgSac);
      sessionStorage.setItem("pret_chit_culoare", this.state.pret_chit_culoare);
      sessionStorage.setItem("defaultCurrency", this.state.defaultCurrency);
      sessionStorage.setItem("cursIntrodus", this.state.cursIntrodus);
      sessionStorage.setItem(
        "editareProdusPlacaj",
        this.state.editareProdusPlacaj
      );
      sessionStorage.setItem("id_linie_comanda", this.state.id_linie_comanda);

      sessionStorage.setItem(
        "general_inaltime_coltar_placaj",
        this.state.general_inaltime_coltar_placaj
      );
      sessionStorage.setItem(
        "general_buc_cutie_placaj",
        this.state.general_buc_cutie_placaj
      );
      sessionStorage.setItem(
        "general_buc_cutie_coltar",
        this.state.general_buc_cutie_coltar
      );

      sessionStorage.setItem(
        "general_latura_scurta_placaj",
        this.state.general_latura_scurta_placaj
      );

      sessionStorage.setItem(
        "general_latime_coltar_placaj",
        this.state.general_latime_coltar_placaj
      );

      sessionStorage.setItem(
        "general_lungime_coltar",
        this.state.general_lungime_coltar
      );

      sessionStorage.setItem(
        "general_lungime_coltar_placaj",
        this.state.general_lungime_coltar_placaj
      );
      sessionStorage.setItem(
        "general_pret_coltar_placaj",
        this.state.general_pret_coltar_placaj
      );
      if (sessionStorage.getItem("ultima_comanda") === null) {
        sessionStorage.setItem("ultima_comanda", this.state.ultima_comanda);
      }
    }

    if (this.state.cursIntrodus == "" && this.state.defaultCurrency != "lei") {
      event.preventDefault();
      alert("Vă rugăm să introduceți cursul pentru moneda aleasă.");
    }
  };

  // Schimb moneda
  changeMoneda = e => {
    if (e.target.value != "lei") {
      this.setState({
        cursIntrodusAfisat: 1,
        defaultCurrency: e.target.value,
        cursIntrodus: ""
      });
    } else {
      this.setState({
        cursIntrodusAfisat: 0,
        defaultCurrency: e.target.value,
        cursIntrodus: ""
      });
    }
  };

  // Introducere curs
  introducereCurs = e => {
    this.setState({
      cursIntrodus: e.target.value
    });
  };

  render() {
    //sessionStorage.clear();
    if (this.state.defaultCurrency == "lei") {
      var currencyWidth = {
        width: "48px"
      };
    } else if (this.state.defaultCurrency == "euro") {
      var currencyWidth = {
        width: "68px"
      };
    }

    // Incarcare imagine produs daca exista sau nu
    try {
      var imagineProdus = require("../assets/img/klinker/" +
        this.state.product_img_placaj);
    } catch (err) {
      var imagineProdus = require("../assets/img/klinker/dummy-img.png");
    }

    return (
      <div>
        <div
          className="background calculator_caramida"
          style={{ backgroundImage: `url(${background})` }}
        >
          <Headerq />
          <div className="subheader2">
            <Subheader />
          </div>
          <div className="copy_subheader">
            Acest calculator pentru placaj klinker te ajută să estimezi de câte
            cărămizi vei avea nevoie pentru a acoperi o anumită suprafață. Pe
            lângă necesarul de materiale vei afla și costurile.
          </div>

          <div className="select_produse_general">
            <div className="select_inner">
              <div className="sl_general_placaj sl1">
                <div className="tl_general">
                  Introduceți
                  <br /> suprafața placării
                </div>
                <input
                  type="text"
                  placeholder="Suprafață placare"
                  ref="input_suprafata_placare"
                  value={this.state.suprafata_placare}
                  onChange={this.handleChange}
                  className="input_top input_dreapta input_edit c1 fz18 bd"
                  name="suprafata_placare"
                />
              </div>
              <div className="sl_general_placaj sl1">
                <div className="tl_general">
                  Introduceți
                  <br /> lungime colțuri
                </div>
                <input
                  type="text"
                  placeholder="Lungime colțuri"
                  ref="input_lungime_colturi"
                  value={this.state.lungime_colturi}
                  onChange={this.handleChange}
                  className="input_top input_dreapta input_edit c1 fz18 bd"
                  name="lungime_colturi"
                />
              </div>
              <div className="sl_general_placaj sl1">
                <div className="tl_general">
                  Selectează
                  <br /> grosimea pe care o preferi
                </div>
                <select
                  value={this.state.grosime_placaj}
                  onChange={this.handleChange}
                  name="grosime_placaj"
                  className={
                    "select_general calculator-field-select " +
                    (this.state.grosime_placaj != "" ? "select_black" : "")
                  }
                >
                  <option value="" disabled selected>
                    Selectează Grosime
                  </option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                </select>
              </div>
            </div>
          </div>

          <div
            className={`inputs_hidden ${
              this.state.active_inputs ? "show_inputs" : ""
            }`}
          >
            <div className="sectiune_inputuri">
              <div className="poza_stanga">
                <img src={imagineProdus} alt="" />
              </div>
              {/* Informatii constructie */}
              <div className="calculator-field informatii-constructie info_right">
                <div className="info-constructie-item  flx sb slmon">
                  {/* Selectare moneda */}
                  {sessionStorage.getItem("defaultCurrency") === "" ||
                  sessionStorage.getItem("defaultCurrency") === null ||
                  sessionStorage.getItem("defaultCurrency") === "null" ? (
                    <div className="calculator-field selectare-moneda">
                      <div className="calculator-field-title medium tl_general">
                        Selectare monedă
                      </div>
                      <select
                        ref="select_general moneda_select"
                        className="calculator-field-select select_general select_black"
                        value={this.state.defaultCurrency}
                        onChange={this.changeMoneda}
                      >
                        <option value="lei">Lei</option>
                        <option value="eur">Euro</option>
                        <option value="mdl">Lei moldovenești</option>
                      </select>
                      {this.state.cursIntrodusAfisat == 1 ? (
                        <input
                          type="number"
                          id="cursMoneda"
                          className="input_dreapta"
                          placeholder="Introdu curs"
                          value={this.state.cursIntrodus}
                          onChange={this.introducereCurs}
                          min="0"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="calculator-field-title medium tl_mare">
                  Placaj Klinker
                </div>

                <div className="info-constructie-items">
                  <div className="info-constructie-item  flx sb">
                    <select
                      className={
                        "select_general selectprodus calculator-field-select " +
                        (this.state.defaultSelectProdusPlacajKlinker != 0
                          ? "select_black"
                          : "")
                      }
                      value={this.state.defaultSelectProdusPlacajKlinker}
                      onChange={this.puneProdusePlacaj}
                    >
                      <option value="0" disabled>
                        Selectează Produsul
                      </option>
                      {this.state.produse_placaj}
                    </select>
                  </div>
                  <div className="info-constructie-item  flx sb">
                    <select
                      className={
                        "select_general selectmodel calculator-field-select sl_j1 " +
                        (this.state.defaultSelectModelPlacajKlinker != ""
                          ? "select_black"
                          : "")
                      }
                      onChange={this.pretModelPlacaj}
                      value={this.state.defaultSelectModelPlacajKlinker}
                    >
                      <option value="0" disabled selected>
                        Selectează Modelul
                      </option>
                      {this.state.modele_placaj}
                    </select>
                  </div>

                  <div className="flxx spb">
                    <div className="info-constructie-item flx sb">
                      <span className="item-title">Preț/buc (LEI)</span>
                      <input
                        type="text"
                        ref="input_pret_bucata_placaj"
                        value={this.state.pret_bucata_placaj}
                        onChange={this.handleChange}
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="pret_bucata_placaj"
                      />
                    </div>
                    <div className="info-constructie-item  flx sb">
                      <span className="item-title">Discount (%):</span>
                      <input
                        type="text"
                        ref="input_discount"
                        value={this.state.discount}
                        onChange={this.handleChange}
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="discount"
                      />
                    </div>
                  </div>
                </div>
                <div className="calculator-field-title medium tl_mare">
                  Adeziv
                </div>
                <div className="info-constructie-items">
                  <div className="info-constructie-item flx sb">
                    <select
                      value={this.state.modelAdeziv}
                      onChange={this.pretAdeziv}
                      className={
                        "select_general calculator-field-select sl_j1 " +
                        (this.state.modelAdeziv != 0 ? "select_black" : "")
                      }
                    >
                      <option value="0" disabled>
                        Selectează Adezivul
                      </option>
                      {this.state.adeziv_placaj}
                    </select>
                  </div>
                  <div className="flxx spb">
                    <div className="info-constructie-item flx sb">
                      <span className="item-title">Preț/buc (LEI)</span>
                      <input
                        type="text"
                        ref="input_pret_adeziv"
                        value={this.state.pret_adeziv}
                        onChange={this.handleChange}
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="pret_adeziv"
                      />
                    </div>
                    <div className="info-constructie-item  flx sb">
                      <span className="item-title">Discount (%):</span>
                      <input
                        type="text"
                        ref="input_discount_adeziv"
                        value={this.state.discount_adeziv}
                        onChange={this.handleChange}
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="discount_adeziv"
                      />
                    </div>
                  </div>
                </div>

                <div className="calculator-field-title medium tl_mare">
                  Chit
                </div>
                <div className="info-constructie-items">
                  <div className="info-constructie-item flx sb">
                    <select
                      value={this.state.idChitRosturi}
                      onChange={this.culoareChitRosturi}
                      className={
                        "select_general selectchit calculator-field-select " +
                        (this.state.idChitRosturi != 0 ? "select_black" : "")
                      }
                    >
                      <option value="0" disabled>
                        Selectează chitul
                      </option>
                      {this.state.chit_placaj}
                    </select>
                  </div>
                  <div className="info-constructie-item flx sb">
                    <select
                      value={this.state.idCuloareChit}
                      onChange={this.pretCuloareChit}
                      className={
                        "select_general selectculoarechit calculator-field-select sl_j1 " +
                        (this.state.idCuloareChit != 0 ? "select_black" : "")
                      }
                      name="idCuloareChit"
                    >
                      <option value="0" disabled>
                        Selectează culoarea
                      </option>
                      {this.state.modele_culoare_chit}
                    </select>
                  </div>
                  <div className="flxx spb">
                    <div className="info-constructie-item flx sb">
                      <span className="item-title">Preț/buc (LEI)</span>
                      <input
                        type="text"
                        ref="input_pret_chit"
                        value={this.state.pret_chit}
                        onChange={this.handleChange}
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="pret_chit"
                      />
                    </div>
                    <div className="info-constructie-item  flx sb">
                      <span className="item-title">Discount (%):</span>
                      <input
                        type="text"
                        ref="input_discount_chit"
                        value={this.state.discount_chit}
                        onChange={this.handleChange}
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="discount_chit"
                      />
                    </div>
                  </div>
                </div>

                <div className="info-constructie-info">
                  <p>
                    * Prețul este unul recomandat pentru vânzare. Pentru o
                    ofertă de preț, vă rugăm să contactați reprezentanții
                    Brikston.
                  </p>
                  <p>* Prețurile afișate includ TVA.</p>
                </div>
                <Link
                  to={{
                    pathname: "/sumar-comanda-placaj-klinker",
                    state_curent: { state_curent: this.state }
                  }}
                  onClick={this.disabledLink}
                >
                  <div className="calculeaza-button" id="calculeaza-button">
                    <div className="calculeaza-label">
                      Calculează
                      <span>Necesar materiale și costuri</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlacajKlinker;
