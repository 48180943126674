import React, { Component } from "react";
import { Link } from "react-router-dom";
import product from "../assets/img/product.png";
import cart_btn from "../assets/img/cart_btn.png";
import axios from "axios";

// Import components
import NecesarCaramidaLungime from "./NecesarCaramidaLungime";
import NecesarCaramidaVolum from "./NecesarCaramidaVolum";
import NecesarCaramidaSuprafata from "./NecesarCaramidaSuprafata";
import NecesarCaramidaBucati from "./NecesarCaramidaBucati";

class NecesarCaramida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      cantitate_m2: 0,
      cantitate_m3: 0,
      mortar_m3: 0,
      raportm3_m2: 0,
      cantitate_mortar_m2: 0,
      bucati_per_palet: 0,
      masa: 0,
      cantitate_buc: 0,
      pretLista: 0,
      masaPalet: 0,
      pretPalet: 0,
      masaMortarM3: 0,
      pretNisipM3: 0,
      pretCimentT: 0,
      lungimeDim: 0,
      inaltimeDim: 0,
      latimeDim: 0,
      cursIntrodus: 1
    };
  }

  componentWillMount() {
    if (this.props.location.data) {
      //console.log(this.props.location.data);
      // Adaug props in localStorage
      localStorage.setItem("props", JSON.stringify(this.props.location.data));
      var props = JSON.parse(localStorage.getItem("props"));
      // Setez state-ul
      this.setState(
        {
          data: JSON.parse(localStorage.getItem("props"))
        },
        () => {
          // Preiau informatii despre produs
          axios
            .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
              get_data: "detalii_by_produs",
              idProdus: props.date_calcul.optionProduse,
              grosimeValue: props.date_calcul.optionGrosimeText
            })
            .then(res => {
              this.setState({
                cantitate_m2: res.data.cantitate_m2,
                cantitate_m3: res.data.cantitate_m3,
                mortar_m3: res.data.mortar_m3,
                raportm3_m2: res.data.raportm3_m2,
                cantitate_mortar_m2: res.data.mortar_m3 / res.data.raportm3_m2,
                bucati_per_palet: res.data.bucati_per_palet,
                masa: res.data.masa,
                pretLista: res.data.pretLista,
                masaPalet: res.data.masaPalet,
                pretPalet: res.data.pretPalet,
                masaMortarM3: res.data.masaMortarM3,
                pretNisipM3: res.data.pretNisipM3,
                pretCimentT: res.data.pretCimentT,
                lungimeDim: res.data.lungime,
                latimeDim: res.data.latime,
                inaltimeDim: res.data.inaltime,
                cursIntrodus: props.date_calcul.cursIntrodus
              });
            });
        }
      );
    } else {
      // Setez state-ul din local storage
      var props = JSON.parse(localStorage.getItem("props"));
      this.setState(
        {
          data: JSON.parse(localStorage.getItem("props"))
        },
        () => {
          // Preiau informatii despre produs
          axios
            .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
              get_data: "detalii_by_produs",
              idProdus: props.date_calcul.optionProduse,
              grosimeValue: props.date_calcul.optionGrosimeText
            })
            .then(res => {
              this.setState({
                cantitate_m2: res.data.cantitate_m2,
                cantitate_m3: res.data.cantitate_m3,
                mortar_m3: res.data.mortar_m3,
                raportm3_m2: res.data.raportm3_m2,
                cantitate_mortar_m2: res.data.mortar_m3 / res.data.raportm3_m2,
                bucati_per_palet: res.data.bucati_per_palet,
                masa: res.data.masa,
                pretLista: res.data.pretLista,
                masaPalet: res.data.masaPalet,
                pretPalet: res.data.pretPalet,
                masaMortarM3: res.data.masaMortarM3,
                pretNisipM3: res.data.pretNisipM3,
                pretCimentT: res.data.pretCimentT,
                lungimeDim: res.data.lungime,
                latimeDim: res.data.latime,
                inaltimeDim: res.data.inaltime,
                cursIntrodus: props.date_calcul.cursIntrodus
              });
            });
        }
      );
    }

    // Preiau ID-ul ultimei comenzi si-l salvez in localStorage
    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels2.php`, {
        get_data: "preluareUltimaComanda"
      })
      .then(res => {
        if (
          localStorage.getItem("ultima_comanda") === null ||
          localStorage.getItem("ultima_comanda") === ""
        ) {
          localStorage.setItem("ultima_comanda", res.data.idComandaNou);
        }
      });
  }

  componentWillUnmount() {
    // Sterg variabila `data` din local storage in momentul in care parasesc componenta
    localStorage.removeItem("data");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.state.data == "") {
      return <div />;
    } else {
      var props = JSON.parse(localStorage.getItem("props"));
      var data_to_write = [];
      if (props.date_calcul.optionModalitate === "lungime") {
        // Creare array cu valori necesare (+valori calculate) pentru fiecare componenta
        // in parte, in functie de tipul calculului
        data_to_write.push({
          modalitateCalcul: "lungime",
          idLinieComanda: this.state.data.date_calcul.idLinieComanda,
          editareProdusCos: this.state.data.date_calcul.editareProdusCos,
          product_name: this.state.data.date_calcul.productName,
          product_image: this.state.data.date_calcul.productImage,
          lungime: parseFloat(
            this.state.data.date_calcul.lungimePereti
          ).toFixed(0),
          inaltime: parseFloat(
            this.state.data.date_calcul.inaltimePerete
          ).toFixed(0),
          grosime: this.state.data.date_calcul.optionGrosimeText,
          moneda: this.state.data.date_calcul.defaultCurrency,
          cursIntrodus: this.state.data.date_calcul.cursIntrodus,
          cantitate_m2: this.state.cantitate_m2,
          cantitate_m3: this.state.cantitate_m3,
          cantitate_mortar_m2: this.state.cantitate_mortar_m2,
          cantitate_mortar_m3: parseFloat(this.state.mortar_m3).toFixed(2),
          livrabil_caramida_cantitate: parseFloat(
            parseFloat(
              this.state.data.date_calcul.numarBucati /
                this.state.bucati_per_palet
            ).toFixed(0) * this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_caramida_volum: parseFloat(
            ((this.state.data.date_calcul.lungime *
              this.state.data.date_calcul.inaltime *
              this.state.data.date_calcul.latime) /
              1000000) *
              (parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0) *
                this.state.bucati_per_palet)
          ).toFixed(2),
          livrabil_caramida_paleti: parseFloat(
            this.state.data.date_calcul.numarBucati /
              this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_caramida_masa: parseFloat(
            (this.state.masa *
              (parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0) *
                this.state.bucati_per_palet)) /
              1000
          ).toFixed(2),
          livrabil_caramida_valoare_fara_tva: parseFloat(
            (((this.state.data.date_calcul.pret *
              (100 - this.state.data.date_calcul.discount)) /
              100) *
              parseFloat(
                parseFloat(
                  this.state.data.date_calcul.numarBucati /
                    this.state.bucati_per_palet
                ).toFixed(0) * this.state.bucati_per_palet
              ).toFixed(0)) /
              this.state.cursIntrodus
          ).toFixed(2),
          livrabil_paleti_cantitate: parseFloat(
            this.state.data.date_calcul.numarBucati /
              this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_paleti_masa: parseFloat(
            (this.state.masaPalet *
              parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0)) /
              1000
          ).toFixed(2),
          livrabil_paleti_valoare_fara_tva: parseFloat(
            (this.state.pretPalet *
              parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0)) /
              this.state.cursIntrodus
          ).toFixed(2),
          volum: this.state.data.date_calcul.volum,
          suprafata: this.state.data.date_calcul.suprafataZidarie,
          idProdus: this.state.data.date_calcul.optionProduse,
          pret: this.state.data.date_calcul.pret,
          discount: this.state.data.date_calcul.discount,
          masa: this.state.masa,
          masaMortarM3: this.state.masaMortarM3,
          pretNisipM3: this.state.pretNisipM3,
          pretCimentT: this.state.pretCimentT,
          raportM3M2: this.state.raportm3_m2,
          inaltimeDim: this.state.inaltimeDim,
          latimeDim: this.state.latimeDim,
          lungimeDim: this.state.lungimeDim,
          bucatiPerPalet: this.state.bucati_per_palet,
          bucati: this.state.data.date_calcul.numarBucati,
          pretPalet: this.state.pretPalet,
          masaPalet: this.state.masaPalet
        });
      } else if (props.date_calcul.optionModalitate === "volum") {
        var data_to_write = [];
        data_to_write.push({
          modalitateCalcul: "volum",
          idLinieComanda: this.state.data.date_calcul.idLinieComanda,
          editareProdusCos: this.state.data.date_calcul.editareProdusCos,
          product_name: this.state.data.date_calcul.productName,
          product_image: this.state.data.date_calcul.productImage,
          lungime: parseFloat(
            this.state.data.date_calcul.lungimePereti
          ).toFixed(2),
          inaltime: parseFloat(
            this.state.data.date_calcul.inaltimePerete
          ).toFixed(2),
          grosime: this.state.data.date_calcul.optionGrosimeText,
          moneda: this.state.data.date_calcul.defaultCurrency,
          cursIntrodus: this.state.data.date_calcul.cursIntrodus,
          cantitate_m2: this.state.cantitate_m2,
          cantitate_m3: this.state.cantitate_m3,
          cantitate_mortar_m2: this.state.cantitate_mortar_m2,
          cantitate_mortar_m3: parseFloat(this.state.mortar_m3).toFixed(2),
          livrabil_caramida_cantitate: parseFloat(
            parseFloat(
              this.state.data.date_calcul.numarBucati /
                this.state.bucati_per_palet
            ).toFixed(0) * this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_caramida_volum: parseFloat(
            ((this.state.data.date_calcul.lungime *
              this.state.data.date_calcul.inaltime *
              this.state.data.date_calcul.latime) /
              1000000) *
              (parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0) *
                this.state.bucati_per_palet)
          ).toFixed(2),
          livrabil_caramida_paleti: parseFloat(
            this.state.data.date_calcul.numarBucati /
              this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_caramida_masa: parseFloat(
            (this.state.masa *
              (parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0) *
                this.state.bucati_per_palet)) /
              1000
          ).toFixed(2),
          livrabil_caramida_valoare_fara_tva: parseFloat(
            (((this.state.data.date_calcul.pret *
              (100 - this.state.data.date_calcul.discount)) /
              100) *
              parseFloat(
                parseFloat(
                  this.state.data.date_calcul.numarBucati /
                    this.state.bucati_per_palet
                ).toFixed(0) * this.state.bucati_per_palet
              ).toFixed(0)) /
              this.state.cursIntrodus
          ).toFixed(2),
          livrabil_paleti_cantitate: parseFloat(
            this.state.data.date_calcul.numarBucati /
              this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_paleti_masa: parseFloat(
            (this.state.masaPalet *
              parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0)) /
              1000
          ).toFixed(2),
          livrabil_paleti_valoare_fara_tva: parseFloat(
            (this.state.pretPalet *
              parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0)) /
              this.state.cursIntrodus
          ).toFixed(2),
          volum: this.state.data.date_calcul.volum,
          suprafata: this.state.data.date_calcul.suprafataZidarie,
          idProdus: this.state.data.date_calcul.optionProduse,
          pret: this.state.data.date_calcul.pret,
          discount: this.state.data.date_calcul.discount,
          masa: this.state.masa,
          masaMortarM3: this.state.masaMortarM3,
          pretNisipM3: this.state.pretNisipM3,
          pretCimentT: this.state.pretCimentT,
          raportM3M2: this.state.raportm3_m2,
          inaltimeDim: this.state.inaltimeDim,
          latimeDim: this.state.latimeDim,
          lungimeDim: this.state.lungimeDim,
          bucatiPerPalet: this.state.bucati_per_palet,
          bucati: this.state.data.date_calcul.numarBucati,
          pretPalet: this.state.pretPalet,
          masaPalet: this.state.masaPalet
        });
      } else if (props.date_calcul.optionModalitate === "suprafata") {
        var data_to_write = [];
        data_to_write.push({
          modalitateCalcul: "suprafata",
          idLinieComanda: this.state.data.date_calcul.idLinieComanda,
          editareProdusCos: this.state.data.date_calcul.editareProdusCos,
          product_name: this.state.data.date_calcul.productName,
          product_image: this.state.data.date_calcul.productImage,
          lungime: parseFloat(
            this.state.data.date_calcul.lungimePereti
          ).toFixed(2),
          inaltime: parseFloat(
            this.state.data.date_calcul.inaltimePerete
          ).toFixed(2),
          grosime: this.state.data.date_calcul.optionGrosimeText,
          moneda: this.state.data.date_calcul.defaultCurrency,
          cursIntrodus: this.state.data.date_calcul.cursIntrodus,
          cantitate_m2: this.state.cantitate_m2,
          cantitate_m3: this.state.cantitate_m3,
          cantitate_mortar_m2: this.state.cantitate_mortar_m2,
          cantitate_mortar_m3: parseFloat(this.state.mortar_m3).toFixed(2),
          livrabil_caramida_cantitate: parseFloat(
            parseFloat(
              this.state.data.date_calcul.numarBucati /
                this.state.bucati_per_palet
            ).toFixed(0) * this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_caramida_volum: parseFloat(
            ((this.state.data.date_calcul.lungime *
              this.state.data.date_calcul.inaltime *
              this.state.data.date_calcul.latime) /
              1000000) *
              (parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0) *
                this.state.bucati_per_palet)
          ).toFixed(2),
          livrabil_caramida_paleti: parseFloat(
            this.state.data.date_calcul.numarBucati /
              this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_caramida_masa: parseFloat(
            (this.state.masa *
              (parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0) *
                this.state.bucati_per_palet)) /
              1000
          ).toFixed(2),
          livrabil_caramida_valoare_fara_tva: parseFloat(
            (((this.state.data.date_calcul.pret *
              (100 - this.state.data.date_calcul.discount)) /
              100) *
              parseFloat(
                parseFloat(
                  this.state.data.date_calcul.numarBucati /
                    this.state.bucati_per_palet
                ).toFixed(0) * this.state.bucati_per_palet
              ).toFixed(0)) /
              this.state.cursIntrodus
          ).toFixed(2),
          livrabil_paleti_cantitate: parseFloat(
            this.state.data.date_calcul.numarBucati /
              this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_paleti_masa: parseFloat(
            (this.state.masaPalet *
              parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0)) /
              1000
          ).toFixed(2),
          livrabil_paleti_valoare_fara_tva: parseFloat(
            (this.state.pretPalet *
              parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0)) /
              this.state.cursIntrodus
          ).toFixed(2),
          volum: this.state.data.date_calcul.volum,
          suprafata: this.state.data.date_calcul.suprafataZidarie,
          idProdus: this.state.data.date_calcul.optionProduse,
          pret: this.state.data.date_calcul.pret,
          discount: this.state.data.date_calcul.discount,
          masa: this.state.masa,
          masaMortarM3: this.state.masaMortarM3,
          pretNisipM3: this.state.pretNisipM3,
          pretCimentT: this.state.pretCimentT,
          raportM3M2: this.state.raportm3_m2,
          inaltimeDim: this.state.inaltimeDim,
          latimeDim: this.state.latimeDim,
          lungimeDim: this.state.lungimeDim,
          bucatiPerPalet: this.state.bucati_per_palet,
          bucati: this.state.data.date_calcul.numarBucati,
          pretPalet: this.state.pretPalet,
          masaPalet: this.state.masaPalet
        });
      } else if (props.date_calcul.optionModalitate === "bucati") {
        var data_to_write = [];
        data_to_write.push({
          modalitateCalcul: "bucati",
          idLinieComanda: this.state.data.date_calcul.idLinieComanda,
          editareProdusCos: this.state.data.date_calcul.editareProdusCos,
          product_name: this.state.data.date_calcul.productName,
          product_image: this.state.data.date_calcul.productImage,
          lungime: parseFloat(
            this.state.data.date_calcul.lungimePereti
          ).toFixed(2),
          inaltime: parseFloat(
            this.state.data.date_calcul.inaltimePerete
          ).toFixed(2),
          grosime: this.state.data.date_calcul.optionGrosimeText,
          moneda: this.state.data.date_calcul.defaultCurrency,
          cursIntrodus: this.state.data.date_calcul.cursIntrodus,
          cantitate_m2: this.state.cantitate_m2,
          cantitate_m3: this.state.cantitate_m3,
          cantitate_mortar_m2: this.state.cantitate_mortar_m2,
          cantitate_mortar_m3: this.state.mortar_m3,
          livrabil_caramida_cantitate: parseFloat(
            parseFloat(
              this.state.data.date_calcul.numarBucati /
                this.state.bucati_per_palet
            ).toFixed(0) * this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_caramida_volum: parseFloat(
            ((this.state.data.date_calcul.lungime *
              this.state.data.date_calcul.inaltime *
              this.state.data.date_calcul.latime) /
              1000000) *
              (parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0) *
                this.state.bucati_per_palet)
          ).toFixed(2),
          livrabil_caramida_paleti: parseFloat(
            this.state.data.date_calcul.numarBucati /
              this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_caramida_masa: parseFloat(
            (this.state.masa *
              (parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0) *
                this.state.bucati_per_palet)) /
              1000
          ).toFixed(2),
          livrabil_caramida_valoare_fara_tva: parseFloat(
            (((this.state.data.date_calcul.pret *
              (100 - this.state.data.date_calcul.discount)) /
              100) *
              parseFloat(
                parseFloat(
                  this.state.data.date_calcul.numarBucati /
                    this.state.bucati_per_palet
                ).toFixed(0) * this.state.bucati_per_palet
              ).toFixed(0)) /
              this.state.cursIntrodus
          ).toFixed(2),
          livrabil_paleti_cantitate: parseFloat(
            this.state.data.date_calcul.numarBucati /
              this.state.bucati_per_palet
          ).toFixed(0),
          livrabil_paleti_masa: parseFloat(
            (this.state.masaPalet *
              parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0)) /
              1000
          ).toFixed(2),
          livrabil_paleti_valoare_fara_tva: parseFloat(
            (this.state.pretPalet *
              parseFloat(
                this.state.data.date_calcul.numarBucati /
                  this.state.bucati_per_palet
              ).toFixed(0)) /
              this.state.cursIntrodus
          ).toFixed(2),
          volum: this.state.data.date_calcul.volum,
          suprafata: this.state.data.date_calcul.suprafataZidarie,
          idProdus: this.state.data.date_calcul.optionProduse,
          pret: this.state.data.date_calcul.pret,
          discount: this.state.data.date_calcul.discount,
          masa: this.state.masa,
          masaMortarM3: this.state.masaMortarM3,
          pretNisipM3: this.state.pretNisipM3,
          pretCimentT: this.state.pretCimentT,
          raportM3M2: this.state.raportm3_m2,
          inaltimeDim: this.state.inaltimeDim,
          latimeDim: this.state.latimeDim,
          lungimeDim: this.state.lungimeDim,
          bucatiPerPalet: this.state.bucati_per_palet,
          bucati: this.state.data.date_calcul.numarBucati,
          pretPalet: this.state.pretPalet,
          masaPalet: this.state.masaPalet
        });
      }

      if (props.date_calcul.optionModalitate === "lungime") {
        return <NecesarCaramidaLungime data={data_to_write} />;
      } else if (props.date_calcul.optionModalitate === "volum") {
        return <NecesarCaramidaVolum data={data_to_write} />;
      } else if (props.date_calcul.optionModalitate === "suprafata") {
        return <NecesarCaramidaSuprafata data={data_to_write} />;
      } else if (props.date_calcul.optionModalitate === "bucati") {
        return <NecesarCaramidaBucati data={data_to_write} />;
      }
    }
  }
}

export default NecesarCaramida;
