import React, { Component } from "react";

class TermeniConditii extends Component {
  render() {
    return (
      <div className="termeni-conditii" id="termeni-conditii">
        Pagina termeni și condiții
      </div>
    );
  }
}

export default TermeniConditii;
