import React, { Component } from "react";

// Import asset-uri
import logo from "./../assets/img/leier_logo_oficial.jpg";

export default class Header extends Component {
  render() {
    return (
      <div className="header" id="header">
        <img src={logo} alt="Logo Leier" />
      </div>
    );
  }
}
