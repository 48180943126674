import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import $ from "jquery";
import Subheader from "./Subheader";
import Menu from "./Menu";
import Headerq from "./Headerq";

import background from "./../assets/img/background.jpg";

export default class CaramidaKlinker extends Component {
  constructor(props) {
    super(props);

    // Verific daca moneda si cursul exista deja in localStorage
    if (
      localStorage.getItem("cursIntrodus") === null ||
      localStorage.getItem("defaultCurrency") === null
    ) {
      var defaultCurrencyValue = "lei";
      var cursIntrodusValue = 1;
    } else {
      var defaultCurrencyValue = localStorage.getItem("defaultCurrency");
      var cursIntrodusValue = localStorage.getItem("cursIntrodus");
    }

    this.state = {
      lungimePaleti: "",
      lungimePaletiShow: false,
      defaultCurrency: defaultCurrencyValue,
      cursIntrodus: cursIntrodusValue,
      cursIntrodusAfisat: 0,
      defaultSelectGrosime: 0,
      defaultSelectModelKlinker: 0,
      defaultSelectMortar: 0,
      defaultSelectProdusKlinker: 0,
      produse: [],
      nameProdusKlinker: "",
      active_inputs: false,
      ultima_coamnda: "",
      denumireModel: "",
      modele: (
        <option value="0" disabled="">
          Selectează Modelul
        </option>
      ),
      pret_bucata: "",
      id_linie_comanda: "",
      lungime: "",
      latime: "",
      inaltime: "",
      discount: "",
      suprafata_zidarie: "",
      product_img: "dummy-img.png",
      pret_bucata_mortar: "",
      editareProdus: 0,
      bucati_palet: "",
      nume_mortar: "",
      produse_mortar: (
        <option value="0" disabled selected>
          {" "}
          Selectează Mortarul{" "}
        </option>
      ),
      discount_mortar: ""
    };
  }

  componentDidMount() {
    $(document).ready(function() {
      $("input").click(function() {
        var value = $(this).val();
        if (value == "0" || value == "0.00") {
          $(this).select();
        }
      });

      $("select").on("change", function() {
        if (this.value != "" || this.value != 0) {
          $(this).css("cssText", "color:#000;");

          if ($(this).hasClass("selectprodus")) {
            $(".selectmodel").css("cssText", "color:#000;");
          }
          if ($(this).hasClass("selectchit")) {
            $(".selectculoarechit").css("cssText", "color:#000;");
          }
        } else {
          $(this).css("cssText", "color:#666;");
        }
      });
    });
  }

  updateStateGrosime = event => {
    this.setState({
      defaultSelectGrosime: this.refs.defaultSelectGrosime.value
    });
    if (
      this.refs.defaultSelectGrosime.value != 0 &&
      this.state.suprafata_zidarie != ""
    ) {
      this.setState({
        active_inputs: true
      });
    }
  };

  updateStateModelKlinker = event => {
    var value = event.target.value;

    var id_produs = this.state.defaultSelectProdusKlinker;

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        id_klinker: id_produs,
        id_model: value,
        get_data: "model_individual"
      })
      .then(res => {
        console.log("testes");
        this.setState({
          product_img: res.data.pret_model.imagine
        });

        this.setState({ pret_bucata: res.data.pret_model.pret_buc });
        this.setState({ lungime: res.data.pret_model.lungime });
        this.setState({ latime: res.data.pret_model.latime });
        this.setState({ inaltime: res.data.pret_model.inaltime });
        this.setState({ discount: "0.00" });
        this.setState({ bucati_palet: res.data.pret_model.buc_palet });
        this.setState({ denumireModel: res.data.pret_model.denumireModel });
      });

    this.setState({
      defaultSelectModelKlinker: this.refs.defaultSelectModelKlinker.value
    });
  };

  updateStateMortar = event => {
    this.setState({
      defaultSelectMortar: this.refs.defaultSelectMortar.value
    });
  };

  changeLungimePaletiValue = event => {
    this.setState({
      lungimePaleti: event.target.value
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });

    if ([event.target.name] == "suprafata_zidarie") {
      if (this.state.defaultSelectGrosime != 0 && event.target.value != "") {
        var x = true;
      } else {
        var x = false;
      }
      this.setState({
        active_inputs: x
      });
    }
  };

  componentWillMount() {
    console.log(this.props.location);
    if (this.props.location.state != undefined) {
      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "produse"
        })
        .then(res => {
          var produse_array = [];
          for (var k = 0; k < res.data.length; k++) {
            produse_array.push(
              <option key={res.data[k].id_produs} value={res.data[k].id_produs}>
                {res.data[k].nume_produs}
              </option>
            );
          }

          this.setState({
            editareProdus: 1,
            active_inputs: true
          });
          this.setState({
            id_linie_comanda: this.props.location.state.dataToEdit
              .id_linie_comanda
          });

          this.setState({ produse: produse_array });

          this.setState({
            defaultSelectGrosime: this.props.location.state.dataToEdit
              .grosime_rost,
            defaultSelectProdusKlinker: this.props.location.state.dataToEdit
              .idKlinker,
            suprafata_zidarie: this.props.location.state.dataToEdit.suprafata,
            nameProdusKlinker: this.props.location.state.dataToEdit
              .denumireKlinker
          });

          var value = this.props.location.state.dataToEdit.idKlinker;
          var id_produs = this.state.defaultSelectProdusKlinker;

          axios
            .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
              id: value,
              get_data: "model",
              id_model_caramida: this.props.location.state.dataToEdit.idModel
            })
            .then(res => {
              //console.log(res.data);
              var modele_array = [];
              for (var k = 0; k < res.data.array_model.length; k++) {
                modele_array.push(
                  <option
                    key={res.data.array_model[k].idModel}
                    value={res.data.array_model[k].idModel}
                  >
                    {res.data.array_model[k].denumireModel}
                  </option>
                );
              }

              if (
                this.state.defaultSelectProdusKlinker == 1 ||
                this.state.defaultSelectProdusKlinker == 5
              ) {
                this.setState({
                  produse_mortar: (
                    <option value={res.data.array_mortar[0].idMortar}>
                      {res.data.array_mortar[0].denumireMortar}
                    </option>
                  )
                });
                this.setState({
                  pret_bucata_mortar: this.props.location.state.dataToEdit
                    .pretMortar,
                  nume_mortar: res.data.array_mortar[0].denumireMortar,
                  defaultSelectMortar: res.data.array_mortar[0].idMortar
                });
              } else {
                this.setState({
                  produse_mortar: (
                    <option value={res.data.array_mortar[1].idMortar}>
                      {res.data.array_mortar[1].denumireMortar}
                    </option>
                  )
                });
                this.setState({
                  pret_bucata_mortar: this.props.location.state.dataToEdit
                    .pretMortar,
                  nume_mortar: res.data.array_mortar[1].denumireMortar,
                  defaultSelectMortar: res.data.array_mortar[1].idMortar
                });
              }

              this.setState({
                product_img: res.data.pret_model.imagine,
                defaultSelectModelKlinker: res.data.pret_model.idModel,
                denumireModel: res.data.pret_model.denumireModel
              });

              this.setState({
                pret_bucata: this.props.location.state.dataToEdit.pret_buc
              });
              this.setState({ lungime: res.data.pret_model.lungime });
              this.setState({ latime: res.data.pret_model.latime });
              this.setState({ inaltime: res.data.pret_model.inaltime });
              this.setState({
                discount: this.props.location.state.dataToEdit.discount
              });
              this.setState({
                discount_mortar: this.props.location.state.dataToEdit
                  .discount_mortar
              });
              this.setState({ modele: modele_array });
              this.setState({
                bucati_palet: res.data.pret_model.buc_palet
              });

              $("select").each(function() {
                if (this.value != "" || this.value != 0) {
                  $(this).css("cssText", "color:#000;");

                  if ($(this).hasClass("selectprodus")) {
                    $(".selectmodel").css("cssText", "color:#000;");
                  }
                  if ($(this).hasClass("selectchit")) {
                    $(".selectculoarechit").css("cssText", "color:#000;");
                  }
                } else {
                  $(this).css("cssText", "color:#666;");
                }
              });
            });

          axios
            .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
              get_data: "ultima_comanda"
            })
            .then(res => {
              if (
                localStorage.getItem("ultima_comanda") === null ||
                localStorage.getItem("ultima_comanda") === ""
              ) {
                localStorage.setItem("ultima_comanda", res.data);
              }
            });
        });
    } else {
      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "produse"
        })
        .then(res => {
          var produse_array = [];
          for (var k = 0; k < res.data.length; k++) {
            produse_array.push(
              <option key={res.data[k].id_produs} value={res.data[k].id_produs}>
                {res.data[k].nume_produs}
              </option>
            );
          }

          this.setState({ produse: produse_array });
        });
      axios
        .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
          get_data: "ultima_comanda"
        })
        .then(res => {
          if (
            localStorage.getItem("ultima_comanda") === null ||
            localStorage.getItem("ultima_comanda") === ""
          ) {
            localStorage.setItem("ultima_comanda", res.data);
          }
        });

      // Refa calcul
      if (
        this.props.location.data != undefined &&
        this.props.location.data.refaCalculData != undefined &&
        this.props.location.data.sourceRefaCalcul == 1
      ) {
        axios
          .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
            id: this.props.location.data.refaCalculData
              .defaultSelectProdusKlinker,
            get_data: "model"
          })
          .then(res => {
            var modele_array = [];
            for (var k = 0; k < res.data.array_model.length; k++) {
              modele_array.push(
                <option
                  key={res.data.array_model[k].idModel}
                  value={res.data.array_model[k].idModel}
                >
                  {res.data.array_model[k].denumireModel}
                </option>
              );
            }

            if (
              this.state.defaultSelectProdusKlinker == 1 ||
              this.state.defaultSelectProdusKlinker == 5
            ) {
              this.setState({
                produse_mortar: (
                  <option value={res.data.array_mortar[0].idMortar}>
                    {res.data.array_mortar[0].denumireMortar}
                  </option>
                )
              });
              this.setState({
                pret_bucata_mortar: this.props.location.data.refaCalculData
                  .pretMortar,
                nume_mortar: res.data.array_mortar[0].denumireMortar,
                defaultSelectMortar: res.data.array_mortar[0].idMortar
              });
            } else {
              this.setState({
                produse_mortar: (
                  <option value={res.data.array_mortar[1].idMortar}>
                    {res.data.array_mortar[1].denumireMortar}
                  </option>
                )
              });
              this.setState({
                pret_bucata_mortar: this.props.location.data.refaCalculData
                  .pretMortar,
                nume_mortar: res.data.array_mortar[1].denumireMortar,
                defaultSelectMortar: res.data.array_mortar[1].idMortar
              });
            }

            this.setState({
              defaultSelectProdusKlinker: this.props.location.data
                .refaCalculData.defaultSelectProdusKlinker,
              pret_bucata_mortar: this.props.location.data.refaCalculData
                .pret_bucata_mortar,
              nameProdusKlinker: this.props.location.data.refaCalculData
                .nameProdusKlinker,
              product_img: this.props.location.data.refaCalculData.product_img,
              defaultSelectModelKlinker: this.props.location.data.refaCalculData
                .defaultSelectModelKlinker,
              denumireModel: this.props.location.data.refaCalculData
                .denumireModel,
              pret_bucata: this.props.location.data.refaCalculData.pret_bucata,
              lungime: this.props.location.data.refaCalculData.lungime,
              latime: this.props.location.data.refaCalculData.latime,
              inaltime: this.props.location.data.refaCalculData.inaltime,
              discount: this.props.location.data.refaCalculData.discount,
              discount_mortar: this.props.location.data.refaCalculData
                .discount_mortar,
              modele: modele_array,
              bucati_palet: this.props.location.data.refaCalculData
                .bucati_palet,
              defaultSelectGrosime: this.props.location.data.refaCalculData
                .defaultSelectGrosime,
              suprafata_zidarie: this.props.location.data.refaCalculData
                .suprafata_zidarie,
              id_linie_comanda: this.props.location.data.refaCalculData
                .id_linie_comanda,
              editareProdus: this.props.location.data.refaCalculData
                .editareProdus
            });
          });
        this.setState({ active_inputs: true });
      }
    }
  }

  puneProduse = event => {
    var value = event.target.value;

    this.setState({
      defaultSelectProdusKlinker: value,
      nameProdusKlinker: event.target.options[event.target.selectedIndex].text
    });

    var id_produs = this.state.defaultSelectProdusKlinker;

    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        id: value,
        get_data: "model"
      })
      .then(res => {
        console.log("Aici");
        console.log(res.data);
        var modele_array = [];
        for (var k = 0; k < res.data.array_model.length; k++) {
          modele_array.push(
            <option
              key={res.data.array_model[k].idModel}
              value={res.data.array_model[k].idModel}
            >
              {res.data.array_model[k].denumireModel}
            </option>
          );
        }

        if (
          this.state.defaultSelectProdusKlinker == 1 ||
          this.state.defaultSelectProdusKlinker == 5
        ) {
          this.setState({
            produse_mortar: (
              <option value={res.data.array_mortar[0].idMortar}>
                {res.data.array_mortar[0].denumireMortar}
              </option>
            )
          });
          this.setState({
            pret_bucata_mortar: res.data.array_mortar[0].pretMortar,
            nume_mortar: res.data.array_mortar[0].denumireMortar,
            defaultSelectMortar: res.data.array_mortar[0].idMortar
          });
        } else {
          this.setState({
            produse_mortar: (
              <option value={res.data.array_mortar[1].idMortar}>
                {res.data.array_mortar[1].denumireMortar}
              </option>
            )
          });
          this.setState({
            pret_bucata_mortar: res.data.array_mortar[1].pretMortar,
            nume_mortar: res.data.array_mortar[1].denumireMortar,
            defaultSelectMortar: res.data.array_mortar[1].idMortar
          });
        }

        this.setState({
          product_img: res.data.pret_model.imagine,
          defaultSelectModelKlinker: res.data.pret_model.idModel,
          denumireModel: res.data.pret_model.denumireModel
        });

        this.setState({ pret_bucata: res.data.pret_model.pret_buc });
        this.setState({ lungime: res.data.pret_model.lungime });
        this.setState({ latime: res.data.pret_model.latime });
        this.setState({ inaltime: res.data.pret_model.inaltime });
        this.setState({ discount: "0.00" });
        this.setState({ discount_mortar: "0.00" });
        this.setState({ modele: modele_array });
        this.setState({ bucati_palet: res.data.pret_model.buc_palet });
      });
  };

  lungimePaletiShow = event => {
    this.setState({
      lungimePaletiShow: true
    });
  };

  currencyChange = event => {
    if (event.target.value == "euro") {
      this.setState({
        defaultCurrency: "euro"
      });
    } else if (event.target.value == "lei") {
      this.setState({
        defaultCurrency: "lei"
      });
    }
  };

  disabledLink = event => {
    if (
      this.state.suprafata_zidarie == "" ||
      this.state.defaultSelectGrosime == 0
    ) {
      event.preventDefault();
      alert("Completati toate campurile!!");
    } else {
      localStorage.setItem("bucati_palet", this.state.bucati_palet);
      localStorage.setItem("defaultCurrency", this.state.defaultCurrency);
      localStorage.setItem(
        "defaultSelectGrosime",
        this.state.defaultSelectGrosime
      );
      localStorage.setItem(
        "defaultSelectModelKlinker",
        this.state.defaultSelectModelKlinker
      );
      localStorage.setItem(
        "defaultSelectMortar",
        this.state.defaultSelectMortar
      );
      localStorage.setItem(
        "defaultSelectProdusKlinker",
        this.state.defaultSelectProdusKlinker
      );

      localStorage.setItem("denumireModel", this.state.denumireModel);
      localStorage.setItem("discount", this.state.discount);
      localStorage.setItem("discount_mortar", this.state.discount_mortar);
      localStorage.setItem("inaltime", this.state.inaltime);
      localStorage.setItem("latime", this.state.latime);
      localStorage.setItem("lungime", this.state.lungime);
      localStorage.setItem("nameProdusKlinker", this.state.nameProdusKlinker);
      localStorage.setItem("nume_mortar", this.state.nume_mortar);
      localStorage.setItem("pret_bucata", this.state.pret_bucata);
      localStorage.setItem("pret_bucata_mortar", this.state.pret_bucata_mortar);
      localStorage.setItem("product_img", this.state.product_img);
      localStorage.setItem("suprafata_zidarie", this.state.suprafata_zidarie);
      localStorage.setItem("cursIntrodus", this.state.cursIntrodus);
      localStorage.setItem("editareProdus", this.state.editareProdus);
      localStorage.setItem("id_linie_comanda", this.state.id_linie_comanda);
      if (localStorage.getItem("ultima_comanda") === null) {
        localStorage.setItem("ultima_comanda", this.state.ultima_comanda);
      }
    }

    if (this.state.cursIntrodus == "" && this.state.defaultCurrency != "lei") {
      event.preventDefault();
      alert("Vă rugăm să introduceți cursul pentru moneda aleasă.");
    }
  };

  // Schimb moneda
  changeMoneda = e => {
    if (e.target.value != "lei") {
      this.setState({
        cursIntrodusAfisat: 1,
        defaultCurrency: e.target.value,
        cursIntrodus: ""
      });
    } else {
      this.setState({
        cursIntrodusAfisat: 0,
        defaultCurrency: e.target.value,
        cursIntrodus: ""
      });
    }
  };

  // Introducere curs
  introducereCurs = e => {
    this.setState({
      cursIntrodus: e.target.value
    });
  };

  render() {
    if (this.state.defaultCurrency == "lei") {
      var currencyWidth = {
        width: "48px"
      };
    } else if (this.state.defaultCurrency == "euro") {
      var currencyWidth = {
        width: "68px"
      };
    }

    // Incarcare imagine produs daca exista sau nu
    try {
      var imagineProdus = require("../assets/img/klinker/" +
        this.state.product_img);
    } catch (err) {
      var imagineProdus = require("../assets/img/klinker/dummy-img.png");
    }
    return (
      <div className="h100">
        <div
          className="background h100 calculator_caramida"
          style={{ backgroundImage: `url(${background})` }}
        >
          <Headerq />
          <div className="subheader2">
            <Subheader />
          </div>
          <div className="copy_subheader">
            Acest calculator pentru cărămidă și blocuri ceramice te ajută să
            estimezi de câte cărămizi vei avea nevoie pentru a acoperi o anumită
            suprafață. Pe lângă necesarul de materiale vei afla și costurile.
          </div>
          <div className="select_produse_general">
            <div className="select_inner">
              <div className="sl_general_klinker sl1">
                <div className="tl_general">
                  Introduceți
                  <br /> suprafața zidăriei
                </div>
                <input
                  placeholder="Suprafață zidărie"
                  type="text"
                  ref="input_suprafata_zidarie"
                  value={this.state.suprafata_zidarie}
                  onChange={this.handleChange}
                  className="input_dreapta input_edit c1 fz18  input_top"
                  name="suprafata_zidarie"
                />
              </div>
              <div className="sl_general_klinker sl1">
                <div className="tl_general">
                  Selectează
                  <br /> grosimea pe care o preferi
                </div>
                <select
                  ref="defaultSelectGrosime"
                  onChange={this.updateStateGrosime}
                  value={this.state.defaultSelectGrosime}
                  className="select_general"
                >
                  <option value="0" disabled>
                    Selectează Grosime
                  </option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                </select>
              </div>

              <div className="sl_general_klinker sl4 hidden_element">
                <div className="tl_general">
                  Selectează
                  <br /> mortarul
                </div>
                <select
                  ref="defaultSelectMortar"
                  onChange={this.updateStateMortar}
                  value={this.state.defaultSelectMortar}
                  className="select_general"
                >
                  {this.state.produse_mortar}
                </select>
              </div>
            </div>
          </div>
          <div
            className={`inputs_hidden ${
              this.state.active_inputs ? "show_inputs" : ""
            }`}
          >
            <div className="sectiune_inputuri">
              <div className="poza_stanga">
                <img src={imagineProdus} alt="" />
              </div>
              {/* Informatii constructie */}
              <div className="calculator-field informatii-constructie info_right">
                <div className="info-constructie-item  flx sb slmon">
                  {/* Selectare moneda */}
                  {localStorage.getItem("defaultCurrency") === "" ||
                  localStorage.getItem("defaultCurrency") === null ||
                  localStorage.getItem("defaultCurrency") === "null" ? (
                    <div className="calculator-field selectare-moneda">
                      <div className="calculator-field-title medium tl_general">
                        Selectare monedă
                      </div>
                      <select
                        ref="select_general moneda_select"
                        className="calculator-field-select select_general select_black"
                        value={this.state.defaultCurrency}
                        onChange={this.changeMoneda}
                      >
                        <option value="lei">Lei</option>
                        <option value="eur">Euro</option>
                        <option value="mdl">Lei moldovenești</option>
                      </select>
                      {this.state.cursIntrodusAfisat == 1 ? (
                        <input
                          type="number"
                          id="cursMoneda"
                          className="input_dreapta"
                          placeholder="Introdu curs"
                          value={this.state.cursIntrodus}
                          onChange={this.introducereCurs}
                          min="0"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="calculator-field-title medium tl_mare">
                  Cărămidă
                </div>

                <div className="info-constructie-items">
                  <div className="info-constructie-item  flx sb">
                    <select
                      className="select_general selectprodus sl_j"
                      ref="defaultSelectProdusKlinker"
                      value={this.state.defaultSelectProdusKlinker}
                      onChange={this.puneProduse}
                    >
                      <option value="0" disabled selected>
                        Selectează produsul
                      </option>
                      {this.state.produse}
                    </select>
                  </div>
                  <div className="info-constructie-item  flx sb">
                    <select
                      ref="defaultSelectModelKlinker"
                      onChange={this.updateStateModelKlinker}
                      value={this.state.defaultSelectModelKlinker}
                      className="select_general selectmodel sl_j"
                    >
                      {this.state.modele}
                    </select>
                  </div>
                  <div className="flxx spb">
                    <div className="info-constructie-item  flx sb">
                      <div className="item-title">Lungime:</div>
                      <input
                        disabled
                        type="text"
                        ref="input_lungime"
                        value={this.state.lungime}
                        onChange={this.handleChange}
                        className="input_dreapta c1 fz18 bd"
                        name="lungime"
                      />
                    </div>
                    <div className="info-constructie-item  flx sb">
                      <div className="item-title">Latime:</div>
                      <input
                        disabled
                        type="text"
                        ref="input_latime"
                        value={this.state.latime}
                        onChange={this.handleChange}
                        className="input_dreapta c1 fz18 bd"
                        name="latime"
                      />
                    </div>
                    <div className="info-constructie-item  flx sb">
                      <span className="item-title">Înălțime:</span>
                      <input
                        disabled
                        type="text"
                        ref="input_inaltime"
                        value={this.state.inaltime}
                        onChange={this.handleChange}
                        className="input_dreapta c1 fz18 bd"
                        name="inaltime"
                      />
                    </div>
                  </div>
                  <div className="flxx spb">
                    <div className="info-constructie-item flx sb">
                      <span className="item-title">Preț/buc (LEI)</span>
                      <input
                        type="text"
                        ref="input_pret"
                        value={this.state.pret_bucata}
                        onChange={this.handleChange}
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="pret_bucata"
                      />
                    </div>
                    <div className="info-constructie-item  flx sb">
                      <span className="item-title">Discount (%):</span>
                      <input
                        type="text"
                        ref="input_discount"
                        value={this.state.discount}
                        onChange={this.handleChange}
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="discount"
                      />
                    </div>
                  </div>
                </div>
                <div className="calculator-field-title medium tl_mare">
                  Mortar
                </div>
                <div className="info-constructie-items">
                  {/* Mortar */}
                  <div className="info-constructie-item flx sb">
                    <select
                      className="calculator-field-select select_general mortar_select selectmodel"
                      ref="defaultSelectMortar"
                      onChange={this.updateStateMortar}
                      value={this.state.defaultSelectMortar}
                    >
                      {this.state.produse_mortar}
                    </select>
                  </div>
                  <div className="flxx spb">
                    <div className="info-constructie-item flx sb">
                      <span className="item-title">Preț/buc (LEI)</span>
                      <input
                        ref="input_pret_mortar"
                        value={this.state.pret_bucata_mortar}
                        onChange={this.handleChange}
                        type="text"
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="pret_bucata_mortar"
                      />
                    </div>
                    <div className="info-constructie-item  flx sb">
                      <span className="item-title">Discount (%):</span>
                      <input
                        ref="input_discount_mortar"
                        value={this.state.discount_mortar}
                        onChange={this.handleChange}
                        type="text"
                        className="input_dreapta input_edit c1 fz18 bd"
                        name="discount_mortar"
                      />
                    </div>
                  </div>
                </div>

                <div className="info-constructie-info">
                  <p>
                    * Prețul este unul recomandat pentru vânzare. Pentru o
                    ofertă de preț, vă rugăm să contactați reprezentanții
                    Brikston.
                  </p>
                  <p>* Prețurile afișate includ TVA.</p>
                </div>
                <Link
                  to={{
                    pathname: "/sumar-comanda-klinker",
                    state_curent: { state_curent: this.state }
                  }}
                  onClick={this.disabledLink}
                >
                  <div className="calculeaza-button" id="calculeaza-button">
                    <div className="calculeaza-label">
                      Calculează
                      <span>Necesar materiale și costuri</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
