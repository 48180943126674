import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import Menu from "./Menu";
import { Link, Redirect } from "react-router-dom";
import logo from "./../assets/img/leier_logo_oficial.jpg";
import cos from "./../assets/img/cos.png";
import meniu from "./../assets/img/meniu.png";

export default class Headerq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      nr_produse: ""
    };
  }
  handleChangeMenu1 = event => {
    this.setState({ visible: !this.state.visible });
  };

  componentWillReceiveProps() {
    this.setState({
      nr_produse: this.props.nr_produse
    });
  }

  componentWillMount() {
    axios
      .post(`https://www.brikston.ro/ajax_calculator/getModels.php`, {
        ultima_comanda: localStorage.getItem("ultima_comanda"),
        get_data: "numar_produse_comanda"
      })
      .then(res => {
        this.setState({
          nr_produse: res.data
        });
      });
  }

  render() {
    return (
      <div>
        <Menu onClick={this.handleChangeMenu1} visible={this.state.visible} />
        <div className="header2">
          <div className="container_header2">
            <Link to="/">
              <div className="logo_header2">
                {" "}
                <img src={logo} alt="Logo Brikston" />
              </div>
            </Link>
            {/*<div className="meniu_header2">
              <div className="container_right">
                <Link
                  to={{
                    pathname: "/cart"
                  }}
                >
                  <div className="cart">
                    <img src={cos} alt="Cart" />
                    <div className="circle_products">
                      {this.state.nr_produse}
                    </div>
                  </div>
                </Link>
                <img src={meniu} alt="Menu" onClick={this.handleChangeMenu1} />
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}
